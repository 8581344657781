import CommentIcon from "@mui/icons-material/Comment";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HistoryIcon from "@mui/icons-material/History";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Alert, Box, Button, Slide, Snackbar, Tab, Tabs, ThemeProvider, Typography } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Context } from "../../context/Context";
import server from "../../server";
import Theme from "../../style/Theme";
import TicketEventWidget from "./Comments/TicketEventWidget";
import { a11yProps, TabPanel } from "./consts";
import TicketHistoryDetails from "./History/TicketHistoryDetails";
import AnnouncementsWidget from "./News/AnnouncementsWidget";
import QueueStats from "./Stats/QueueStats";
import TicketStatus from "./StatusTabs/TicketStatus";
import TutorStatus from "./StatusTabs/TutorStatus";

export default function WidgetTabs(props) {
	WidgetTabs.propTypes = {
		activeTutors: PropTypes.array,
		useEventListener: PropTypes.func
	};

	const { course_id } = useParams();
	const {
		state: { user, role }
	} = useContext(Context);
	const navigate = useNavigate();

	const [ecId, setEcId] = useState(-1);

	/* eslint-disable no-unused-vars */
	const [tutorLocations, setTutorLocations] = useState(["B260", "Remote"]);

	const [value, setValue] = useState(0);
	const { activeTutors, useEventListener } = props;

	const [ticketsResolved, setTicketsResolved] = useState(0);
	const [prevTicketTime, setPrevTicketTime] = useState(0);
	const [avgResTime, setAvgResTime] = useState(0);
	const [allTickets, setAllTickets] = useState([]);
	const [ticketHistory, setTicketHistory] = useState([]);
	let ticketHistoryCount = 10; // maximum number of tickets to show for the history

	const [openTicketHistoryInfo, setOpenTicketHistoryInfo] = useState({});

	const [open, setOpen] = useState(false);

	// stores all of the ticketEvents for the queue, remove once the ticket is removed
	const [queueEventList, setQueueEventList] = useState({});
	const [ticketEventList, setTicketEventList] = useState([]);

	// determines whether we need to re-pull the tickets to obtain ticket history, but honestly,
	// we can just have the server change, and then update the history on re-load. !!!!
	const [ticketsQueried, setTicketsQueried] = useState(false);

	// is either the ticket that's opened or just false
	const [openedTicket, setOpenedTicket] = useState(false);

	/**
	 * We click on a ticket in the history – open its info.
	 * @param {*} ticketID
	 */
	const handleOpen = (ticketID) => {
		allTickets.forEach((ticket) => {
			if (ticket.ticket_id === ticketID) {
				setOpenTicketHistoryInfo(ticket);
				setOpen(true);
			}
		});
	};

	/**
	 * Opens/Closes the ticket state in the small widget when it is clicked in the Queue.
	 * Also sets the eventList to the opened ticket's eventsList.
	 * @param e – the eventListener object that contains the clicked ticket ID from the main Queues page
	 */
	const handleTicketOpen = (e) => {
		if (openedTicket && e.detail === openedTicket) {
			setOpenedTicket(false);
			setTicketEventList(null);
		} else {
			setOpenedTicket(e.detail);
			setTicketEventList(queueEventList[e.detail]);
		}
	};

	const handleTicketUpdate = (e) => {
		// Most recent events are first
		const event = e.detail.events[0];

		// Show toast if the user that took the action is the current user
		if (event.ec_user_id === ecId) {
			handleSnackbar(event.event_type, "success");
		}

		// Tell useEffect to repull all tickets for ticket history
		// TODO: this should not be done
		setTicketsQueried(false);

		// If the ticket is resolved or cancelled,
		// then it is removed from the queue -> stop tracking ticket
		if (event.event_type === "CANCELED" || event.event_type === "RESOLVED") {
			setOpenedTicket(false);
			setTicketEventList(null);
			return;
		}

		// Update each ticket's events (queue events) and comments (ticket events)
		const events = queueEventList;
		const eventsInOrder = Array.from(e.detail.events).reverse();
		events[e.detail.id] = eventsInOrder;
		setQueueEventList(events);
		setTicketEventList(eventsInOrder);

		// Notify for certain scenarios
		const newTicketForTutor = event.event_type === "CREATED" && role !== "STUDENT";
		const updateOnOpenedTicket = openedTicket === event.ticket_id && event.ec_user_id !== ecId;
		if (newTicketForTutor || updateOnOpenedTicket) {
			const { first_name, last_name, event_type } = event;
			const message = `${first_name} ${last_name} ${event_type.toLowerCase()} the ticket.`;

			if (Notification.permission === "granted") {
				new Notification("Ticket Update", {
					body: message,
					icon: "/favicon.ico"
				});
			} else if (Notification.permission !== "denied") {
				Notification.requestPermission().then((permission) => {
					if (permission === "granted") {
						new Notification("Ticket Update", {
							body: message,
							icon: "/favicon.ico"
						});
					}
				});
			}
		}
	};

	const tabStyles = {
		fontSize: "10px"
	};

	function TabSmaller(props) {
		return (
			<Tab
				{...props}
				sx={tabStyles}
			/>
		);
	}

	const handleChange = (_event, newValue) => {
		setValue(newValue);
	};

	const handleFindAllTickets = (result) => {
		const events = {};
		const oldTickets = [];
		let foundPrevTicket = false;

		// applies only for each tutor
		let ticketHistoryCount = 0;
		let ticketHistoryTime = 0;

		const tickets = result.data.result || [];

		if (tickets.length === 0) return;

		setAllTickets(tickets);

		// sees which tickets have been resolved/cancelled and need to be set as the history
		tickets.forEach((ticket) => {
			const { status, ticket_id } = ticket;

			if (status === 2 || status === 3) {
				oldTickets.push(ticket);

				if (ticket.ec_grader_id === ecId) return; // skip if the grader isn't assigned to this ticket

				ticketHistoryCount++;

				const accepted = new Date(ticket.accepted_at);
				const closed = new Date(ticket.closed_at);
				const minutes = (closed - accepted) / 60000;
				ticketHistoryTime += minutes;

				// calculate previous ticket time from the most recently resolved ticket
				if (!foundPrevTicket) {
					foundPrevTicket = true;
					setPrevTicketTime(Number(minutes.toFixed(2)));
				}
			}

			// TODO setting the ticket events for every ticket
			// we shouldn't need to edit the events for the tickets that aren't active, but
			events[ticket_id] = ticket.ticket_events ? ticket.ticket_events.reverse() : [];
		});
		const avgResTime = ticketHistoryTime / ticketHistoryCount || 0;

		setQueueEventList(events);
		setTicketHistory(oldTickets);
		setAvgResTime(Number(avgResTime.toFixed(2)));
		setTicketsResolved(ticketHistoryCount);
	};

	console.debug("Loading SmallWidget...");

	// Get tickets
	useEffect(() => {
		const sse = new EventSource(`${server.baseURL}api/ticket_event/stream?channel=queue_id.${course_id}`);

		const today = format(new Date(), "MM.dd.yyyy");

		// When a ticketEvent has been added, add it to the end of the list
		sse.addEventListener("addComment", (event) => {
			console.debug("Added ticket event");
			const data = JSON.parse(event.data);

			const ticketEvent = data.message;
			const { message, event_type, first_name, last_name, ticket_id } = ticketEvent;

			const eventList = queueEventList;
			const ticketEventList = queueEventList[ticket_id];

			eventList[ticket_id] = ticketEventList ? ticketEventList.concat(ticketEvent) : ticketEvent;

			// we need to input the entire queueEventList object here
			setQueueEventList(eventList);

			// notifications for the current ticket
			if (openedTicket === ticket_id) {
				setTicketEventList(eventList[ticket_id]);
				if (ticketEvent.ec_user_id !== ecId) {
					const title = `${first_name} ${last_name} ${event_type.toLowerCase()} the ticket.`;

					if (Notification.permission === "granted") {
						new Notification(title, {
							body: message,
							icon: "/favicon.ico"
						});
					} else if (Notification.permission !== "denied") {
						Notification.requestPermission().then((permission) => {
							if (permission === "granted") {
								new Notification(title, {
									body: message,
									icon: "/favicon.ico"
								});
							}
						});
					}
				}
			}
		});

		// need this server call to be nested in order to access the grader id
		server
			.getUserRoleInCourse(user.id, course_id)
			.then((response) => {
				setEcId(response.data.result.enrolled_course_info.id);
			})
			.then(() => {
				// TODO: Check if course has been changed to run this again
				// - Careful of infinite loop logic

				// getting ticket history for the current date, and should only populate the ticket history array up to 10 tix
				if (!ticketsQueried) {
					// find tickets for comments
					server
						.findAllTickets(course_id, null, null, "0;1")
						.then((result) => {
							const events = {};
							const tickets = result.data.result || [];

							setAllTickets(tickets);

							// sees which tickets have been resolved and need to be set as the history
							tickets.forEach((ticket) => {
								const { ticket_id, ticket_events } = ticket;
								// TODO setting the ticket events for every ticket
								// we shouldn't need to edit the events for the tickets that aren't active, but
								events[ticket_id] = ticket_events ? ticket_events.reverse() : [];
							});

							setQueueEventList(events);
							setTicketsQueried(true);
						})
						.then(() => {});

					// find all the tickets for today's date for stats & ticket history
					server.findAllTickets(course_id, today, null, "0;1;2;3").then((result) => {
						const oldTickets = [];
						let foundPrevTicket = false;

						// applies only for current tutor
						let ticketHistoryCount = 0;
						let ticketHistoryTime = 0;

						const tickets = result.data.result || [];

						// sees which tickets have been resolved and need to be set as the history
						tickets.forEach((ticket) => {
							const { status } = ticket;

							if (status === 2 || status === 3) {
								// only display up to 10 tickets
								oldTickets.push(ticket);

								// calculate previous ticket time from the most recently resolved ticket
								if (ticket.ec_grader_id === ecId) {
									ticketHistoryCount++;

									const accepted = new Date(ticket.accepted_at);
									const closed = new Date(ticket.closed_at);
									const minutes = (closed - accepted) / 60000;
									ticketHistoryTime += minutes;

									if (!foundPrevTicket) {
										foundPrevTicket = true;
										setPrevTicketTime(Number(minutes.toFixed(2)));
									}
								}
							}
						});

						setTicketHistory(oldTickets);
						const avgResTime = ticketHistoryTime / ticketHistoryCount || 0;

						setAvgResTime(Number(avgResTime.toFixed(2)));
						setTicketsResolved(ticketHistoryCount);
					});
				}
			});

		return () => {
			sse.close();
		};
	}, [course_id, openedTicket, queueEventList, ecId, ticketsQueried, user.id]);

	useEventListener("ticketEventOpened", handleTicketOpen);
	useEventListener("ticketUpdated", handleTicketUpdate);

	const [snackbarInfo, setSnackbarInfo] = useState({
		open: false,
		message: "",
		severity: "success"
	});

	const handleSnackbar = (event_type, severity) => {
		let message = "";
		switch (event_type) {
			case "CREATED":
				message = "Ticket created successfully";
				break;
			case "UPDATED":
				message = "Ticket updated successfully";
				break;
			case "ACCEPTED":
				message = "Ticket accepted";
				break;
			case "DEFERRED":
				message = "Ticket returned to the queue";
				break;
			case "CANCELED":
				message = "Ticket cancelled";
				break;
			case "RESOLVED":
				message = "Ticket resolved";
				break;
			default:
				message = "Ticket update";
				break;
		}

		setSnackbarInfo({
			open: true,
			message,
			severity
		});
	};

	return (
		<Box sx={{ color: "white", width: "100%" }}>
			<TicketHistoryDetails
				open={open}
				openTicketHistoryInfo={openTicketHistoryInfo}
				setOpen={setOpen}
			/>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={snackbarInfo.open}
				autoHideDuration={3000}
				onClose={() =>
					setSnackbarInfo({
						message: snackbarInfo.message,
						open: false
					})
				}
				TransitionComponent={Slide}
			>
				<Alert
					severity={snackbarInfo.severity}
					sx={{ width: "max-content" }}
				>
					{snackbarInfo.message}
				</Alert>
			</Snackbar>

			<Box sx={{ borderBottom: 0 }}>
				<ThemeProvider theme={Theme.darkTheme}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						centered
						TabIndicatorProps={{ style: { background: "white" } }}
						sx={{
							"& .Mui-selected": { color: "white !important" }
						}}
					>
						<TabSmaller
							icon={<CommentIcon />}
							label="Comments"
							{...a11yProps(0)}
							style={{ minWidth: "50px" }}
						/>
						<TabSmaller
							icon={<NewReleasesIcon />}
							label="News"
							{...a11yProps(1)}
							style={{ minWidth: "50px" }}
						/>
						<TabSmaller
							icon={<HistoryIcon />}
							label="History"
							{...a11yProps(2)}
							style={{ minWidth: "50px" }}
						/>
						<TabSmaller
							icon={<EqualizerIcon />}
							label="Stats"
							{...a11yProps(3)}
							style={{ minWidth: "50px" }}
						/>
					</Tabs>
				</ThemeProvider>
			</Box>
			{/* Ticket Events */}
			<TabPanel
				value={value}
				index={0}
			>
				<TicketEventWidget
					ticketEventList={ticketEventList}
					openedTicket={openedTicket}
					ecId={ecId}
				/>
			</TabPanel>

			{/* Announcements */}
			<TabPanel
				value={value}
				index={1}
			>
				<AnnouncementsWidget />
			</TabPanel>

			{/* Ticket History */}
			<TabPanel
				value={value}
				index={2}
			>
				<Box style={{ maxHeight: "50vh", overflowY: "auto" }}>
					{ticketHistory.map((ticket) => {
						const ticketData = ticket;

						if (ticketHistoryCount-- > 0) {
							return (
								<Box
									key={ticketData.ticket_id}
									onClick={() => handleOpen(ticketData.ticket_id)}
								>
									<TicketStatus
										key={ticketData.ticket_id}
										type={ticketData.status}
										person={ticketData.title}
										link="placeholder link"
										tutor={`Tutor w/ id ${ticketData.ec_grader_id}`}
										description={ticketData.description}
										events={ticketData.ticket_events}
									/>
								</Box>
							);
						}
						return "";
					})}
					{ticketHistory.length === 0 && (
						<Typography
							textAlign="center"
							fontWeight="bold"
							marginBottom={2}
						>
							No tickets completed today!
						</Typography>
					)}
					{role !== "STUDENT" && (
						<Button
							variant="contained"
							color="secondary"
							style={{ textAlign: "right" }}
							onClick={() => navigate("/settings")}
						>
							View more previous tickets in the Ticket History page →
						</Button>
					)}
				</Box>
			</TabPanel>

			{/* Queue Stats */}
			<TabPanel
				value={value}
				index={3}
			>
				<Typography
					variant="h6"
					style={{ textAlign: "center" }}
					sx={{ margin: "5px" }}
				>
					ACTIVE TUTORS
				</Typography>
				{activeTutors.map((tutor) => (
					<TutorStatus
						type="OnDuty"
						person={[tutor.fname, tutor.lname].join(" ")}
						key={tutor.id}
						locations={tutorLocations}
					/>
				))}
				<div>
					<QueueStats
						ticketsResolved={ticketsResolved}
						prevTicketTime={prevTicketTime}
						avgResTime={avgResTime}
						received
					/>
				</div>
			</TabPanel>
		</Box>
	);
}
