import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CourseCreationApproval from "./routes/CourseCreationApproval";
import CourseSettings from "./routes/CourseSettings";
import CreateCourse from "./routes/CreateCourse";
import ForgotPassword from "./routes/ForgotPassword";
import HallOfFame from "./routes/HallOfFame";
import Help from "./routes/Help";
import Login from "./routes/Login";
import PageNotFound from "./routes/PageNotFound";
import QueuePage from "./routes/QueuePage";
import Settings from "./routes/Settings";

function AppRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<Login />}
				/>
				<Route
					path="/login"
					element={<Login />}
				/>
				<Route
					path="/help"
					element={<Help />}
				/>
				<Route
					path="/forgot-password"
					element={<ForgotPassword />}
				/>
				<Route
					path="/secret/hall-of-fame"
					element={<HallOfFame />}
				/>
				<Route
					path="/queue/:course_id"
					element={<QueuePage />}
				/>
				<Route
					path="/settings"
					element={<Settings />}
				/>
				<Route
					path="/create-course"
					element={<CreateCourse />}
				/>
				<Route
					path="/course-settings/:course_id"
					element={<CourseSettings />}
				/>
				<Route
					path="/course-creation-approval"
					element={<CourseCreationApproval />}
				/>
				<Route
					path="*"
					element={<PageNotFound />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default AppRoutes;
