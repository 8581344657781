export const HELPER_TIPS = {
	queue: "Enable queue for this course",
	status: "Activate this course",
	lock: "Allow tutors to lock queue, even if there are tutors on duty.",
	lab: "Using CSE Labs",
	highCapMode: "In high capacity mode, extra warnings/messages are displayed on the queue.",
	highCapThreshold: "Queue is at high capacity if it has this many tickets.",
	highCapAnnounce:
		"This message appears to everyone in the queue news feed (Announcements) during high capacity mode.",
	highCapWarning: "This warning appears to students before they create a ticket during high capacity mode.",
	defaultTags: "Allows students to tag their tickets with some general CSE keywords.",
	cooldown: "After being helped, a student has to wait this many minutes before they can create another ticket."
};

export const INSTRUCTOR_HEADERS = [
	{ field: "fname", label: "First Name" },
	{ field: "lname", label: "Last Name" },
	{ field: "email", label: "Email" },
	{ field: "demote", label: "Demote" },
	{ field: "edit", label: "Edit" }
];

export const GRADER_HEADERS = [
	{ field: "fname", label: "First Name" },
	{ field: "lname", label: "Last Name" },
	{ field: "email", label: "Email" },
	{ field: "promote", label: "Promote" },
	{ field: "remove", label: "Remove" }
];

export const STUDENT_HEADERS = [
	{ field: "fname", label: "First Name" },
	{ field: "lname", label: "Last Name" },
	{ field: "email", label: "Email" },
	{ field: "edit", label: "Edit" },
	{ field: "remove", label: "Remove" }
];
