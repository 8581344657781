import { Box, Button, Link, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import VisitorNavbar from "../components/VisitorNavbar";
import styles from "../style/HallOfFameStyles";
import OurTheme from "../style/Theme";

function ExternalLink({ href, name }) {
	return (
		<Link
			href={href}
			underline="always"
			target="_blank"
		>
			{name}
		</Link>
	);
}
ExternalLink.propTypes = {
	href: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};

function HallOfFame() {
	const { theme } = OurTheme;

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<VisitorNavbar />
					<Box sx={styles.center}>
						<Typography sx={styles.title}>Hall of Fame</Typography>
					</Box>
					<Typography
						sx={styles.body}
						color="primary"
					>
						This project has been a multi-generational undertaking with tons of talented contributors, and
						we&apos;d like to shout out a few of them via their Github accounts:
						<ul>
							<li>
								<ExternalLink
									href="https://github.com/demc"
									name="@demc"
								/>{" "}
								for reviving the site and pushing towards the current version.
							</li>
							<li>
								<ExternalLink
									href="https://github.com/kbuzsaki"
									name="@kbuzsaki"
								/>{" "}
								and{" "}
								<ExternalLink
									href="https://github.com/kdhuynh"
									name="@kdhuynh"
								/>{" "}
								for their leadership on the Play rewrite.
							</li>
							<li>
								<ExternalLink
									href="https://github.com/chenboy3"
									name="@chenboy3"
								/>
								,{" "}
								<ExternalLink
									href="https://github.com/maxwell-bland"
									name="@maxwell-bland"
								/>
								,{" "}
								<ExternalLink
									href="https://github.com/alxyzc"
									name="@alxyzc"
								/>
								, and{" "}
								<ExternalLink
									href="https://github.com/kraylie"
									name="@kraylie"
								/>{" "}
								for taking the reigns afterwards.
							</li>
							<li>
								<ExternalLink
									href="https://github.com/nate-browne"
									name="@nate-browne"
								/>{" "}
								for heading the rewrite that led to the site you see now.
							</li>
							<li>
								All members who joined from 2022-2023 to help finish the Queues MVP!
								<ul>
									<li>
										<ExternalLink
											href="https://github.com/AdoryVo"
											name="Adory Vo"
										/>{" "}
										(lead) [current]
									</li>
									<li>
										<ExternalLink
											href="https://github.com/Michael-Ivanov1"
											name="Michael Ivanov"
										/>{" "}
										(lead) [current]
									</li>
									<li>
										<ExternalLink
											href="https://github.com/nhilikeknee"
											name="Nhi Nguyen"
										/>{" "}
										(lead)
									</li>
									<li>
										<ExternalLink
											href="https://github.com/thomas-gg"
											name="Thomas Garry"
										/>{" "}
										(lead)
									</li>
									<li>
										<ExternalLink
											href="https://github.com/yukatig"
											name="Yukati Gupta"
										/>{" "}
										(lead)
									</li>
									<li>
										<ExternalLink
											href="https://github.com/angelatsai1214"
											name="Angela Tsai"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/anishatwal"
											name="Anisha Atwal"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/BellaBao1111"
											name="Bella Bao"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/donle3"
											name="Don Le [current]"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/jennifer-jiang"
											name="Jennifer Jiang"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/LongtianBao"
											name="Longtian Bao"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/lanson19"
											name="Lukas Anson"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/Pathe-Seck"
											name="Pathe Seck"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/rajnawal"
											name="Raj Nawal"
										/>
									</li>
									<li>
										<ExternalLink
											href="https://github.com/ShreyaVelagala"
											name="Shreya Velagala"
										/>
									</li>
								</ul>
							</li>
							<li>Professor Paul Cao for sponsoring the project since 2022</li>
							<li>Gary Gillespie for letting us work on this project :)</li>
						</ul>
						Through your vision and hard work that led the contribution of many others, we&apos;ve gone from
						whiteboard queues to digital systems, given quite a few people a chance to try out some web
						development, and made amazing lifelong friends.
					</Typography>
					<Box sx={styles.center}>
						<Typography
							sx={styles.text}
							color="primary"
						>
							<b>That opportunity truly is one we&apos;re grateful for :)</b>
						</Typography>
						<Link
							underline="none"
							href="/"
						>
							<Button
								sx={styles.button}
								variant="outlined"
								color="primary"
							>
								Back Home
							</Button>
						</Link>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}

export default HallOfFame;
