/**
 * Predefined tag options.
 *
 * Used in ticket checkboxes - true & false for checked or not.
 */
export const TAG_LABELS = [
	"Getting Started",
	"Specifications",
	"Conceptual Question",
	"Programming Language",
	"Implementation",
	"Testing",
	"Incorrect Behavior",
	"Weird Debug",
	"Runtime Error",
	"Compile Error"
];

// Equivalent to ["1", "2", .... "30"]
export const SEAT_OPTIONS = [...Array(31).keys()].map(String).slice(1);

export const TAG_LABEL_TOGGLES = {
	"Getting Started": false,
	Specifications: false,
	"Conceptual Question": false,
	"Programming Language": false,
	Implementation: false,
	Testing: false,
	"Incorrect Behavior": false,
	"Weird Debug": false,
	"Runtime Error": false,
	"Compile Error": false
};

export const ROOM_OPTIONS = ["Remote", "B230", "B240", "B250", "B260", "B270", "Hallway"];

export const CALENDAR_EVENT_COLORS = ["#B07080", "#B56917", "#353839", "#469A84", "#64609A"];
