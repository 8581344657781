import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import server from "../server";
import styles from "../style/CourseCreationRequestStyle";
import OurTheme from "../style/Theme";

export default function CourseCreationRequest(props) {
	CourseCreationRequest.propTypes = {
		course_req_id: PropTypes.number,
		shortName: PropTypes.string,
		courseTitle: PropTypes.string,
		courseUrl: PropTypes.string,
		quarter: PropTypes.string,
		year: PropTypes.number,
		fname: PropTypes.string,
		lname: PropTypes.string,
		email: PropTypes.string
	};

	const { theme } = OurTheme;

	const handleApproveCourse = () => {
		server
			.approveCourseRequest(props.course_req_id)
			.then(() => {
				console.log("Course request approved!");

				window.location.reload();
			})
			.catch((err) => console.error(err));
	};

	const handleDeclineCourse = () => {
		const confirmed = window.confirm("Are you sure you want to decline this request?");
		if (confirmed) {
			server
				.declineCourseRequest(props.course_req_id)
				.then(() => {
					console.log("Course request declined!");

					window.location.reload();
				})
				.catch((err) => console.error(err));
		}
	};

	const handleDownloadCourse = () => {
		const promise1 = server.getCourseRequestCsv(props.course_req_id);
		const promise2 = server.getCourseRequestStaffCsv(props.course_req_id);
		const FILE_NAMES = ["Roster.csv", "Staff.csv"];

		// Promise.catch in case there is an error i.e. staff does not exist
		Promise.all([promise1.catch((e) => e), promise2.catch((e) => e)])
			.then((files) => {
				if (!files) throw new Error("Files are inaccessible");
				files.forEach((file, index) => {
					if (!file || !file.data) throw new Error(`${FILE_NAMES[index]} not accessible`);

					// Start download by creating download button & clicking it
					const blob = new Blob([file.data], { type: "text/csv" });
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.style.display = "none";
					a.href = url;
					a.download = `${props.shortName} ${props.quarter} ${props.year} ${FILE_NAMES[index]}`;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				});
			})
			.catch((err) => alert(err));
	};

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Card
						style={{
							backgroundColor: "secondary",
							margin: "20px",
							width: "80%"
						}}
					>
						<CardContent>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={8}
									sx={styles.courseInfo}
								>
									<Typography variant="h5">{props.shortName} </Typography>
									<Typography variant="h5">{props.courseTitle} </Typography>
									<Typography variant="body1">{props.courseUrl} </Typography>
									<Typography variant="body1">
										{props.quarter} {props.year}{" "}
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									sx={styles.instructorInfo}
								>
									<Typography
										style={{ color: "#2a667b" }}
										variant="body2"
									>
										{props.fname} {props.lname}
									</Typography>
									<Typography
										style={{ color: "#2a667b" }}
										variant="body2"
									>
										{props.email}{" "}
									</Typography>
								</Grid>
							</Grid>

							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={8}
								>
									<Button
										startIcon={<CloudDownloadIcon />}
										style={{ color: "#2a667b" }}
										variant="text"
										onClick={handleDownloadCourse}
									>
										Download Rosters
									</Button>
								</Grid>
								<Grid
									item
									xs={4}
								>
									<Box sx={styles.actionBtn}>
										<Button
											style={{
												backgroundColor: "#2a667b",
												color: "white",
												margin: "0 10px"
											}}
											variant="contained"
											onClick={handleApproveCourse}
										>
											Approve
										</Button>
										<Button
											style={{
												backgroundColor: "#a64452",
												color: "white",
												margin: "0 10px"
											}}
											variant="contained"
											onClick={handleDeclineCourse}
										>
											Decline
										</Button>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</ThemeProvider>
			</StyledEngineProvider>
		</Grid>
	);
}
