export default {
	title: {
		color: "white",
		"font-size": "40px"
		// paddingLeft: '100px',
	},
	h2: {
		color: "white"
		// paddingLeft: '100px',
	},
	information: {
		color: "white",
		// paddingLeft: '130px',
		paddingBottom: "10px"
	},
	dropdown: {
		// paddingLeft: '130px',
		paddingBottom: "30px",
		height: "100px",
		width: "200px",
		color: "white"
	},
	select: {
		"&:before": {
			borderColor: "white"
		},
		"&:after": {
			borderColor: "white"
		}
	},
	icon: {
		fill: "white"
	},
	item: {
		color: "#464655"
	},
	buttons: {
		// paddingLeft: '120px',
		flexDirection: "column",
		"& > *": {
			marginBottom: "1em !important",
			marginRight: "1em !important"
		}
	},
	table: {
		minWidth: 850,
		maxHeight: 700
	},
	container: {
		// paddingTop: '50px',
		// paddingLeft: '50px',
		// paddingRight: '100px',
	},
	cell: {
		color: "#464655"
	},
	col: {
		color: "#464655",
		fontWeight: "bold",
		backgroundColor: "#d1dae3"
	},
	formControl: {
		margin: 1,
		minWidth: 120
	},
	selectEmpty: {
		marginTop: 2
	},
	dialogue: {
		color: "#464655"
	},
	floatingLabelFocusStyle: {
		color: "#464655",
		paddingLeft: "10px",
		"&:hover": {
			borderColor: "#46455"
		},
		marginRight: "10px",
		marginBottom: "10px"
	},
	input: {
		color: "#464655",
		marginBottom: "0.5em",
		marginRight: "0.5em"
	}
};
