import PropTypes from "prop-types";
import React, { createContext, useReducer } from "react";

export default (reducer, actions, defaultValue) => {
	const Context = createContext(defaultValue);

	function Provider({ children }) {
		Provider.propTypes = {
			children: PropTypes.element
		};

		const [state, dispatch] = useReducer(reducer, defaultValue);

		const boundActions = {};
		for (const key in actions) {
			if (Object.prototype.hasOwnProperty.call(actions, key)) {
				boundActions[key] = actions[key](dispatch);
			}
		}

		return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
	}

	return { Context, Provider };
};
