export default {
	"@global": {
		"#ticketList::-webkit-scrollbar": {
			display: "none"
		}
	},
	center: {
		display: "flex",
		justifyContent: "center"
	},
	icon: {
		borderColor: "white",
		color: "white",
		marginBottom: "1rem",
		textTransform: "none"
	},
	submit: {
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black"
			}
		}
	},
	title: {
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black"
			}
		},
		textAlign: "center"
	},
	text: {
		color: "black"
	},
	ticketList: {
		height: {
			xs: "auto",
			md: "70vh"
		},
		overflowY: "auto",
		scrollbarWidth: "none"
	},
	check: {
		color: "black"
	},
	locationfield: {
		marginBottom: "0.5rem",
		marginTop: "0.5rem"
	},
	tab: {
		width: "50%",
		padding: "10px"
	}
};
