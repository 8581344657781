import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	Select,
	TextField
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

import server from "../../server";
import styles from "../../style/QueueStyle";
import OurTheme from "../../style/Theme";

export default function TicketFeedbackForm(props) {
	const { inverseTheme } = OurTheme;

	const { resolvedTicket, openFeedback, setOpenFeedback } = props;
	const [rating, setRating] = useState("2");
	const [addFeedback, setAddFeedback] = useState("");
	const [resolvedTicketFeedback, setResolvedTicketFeedback] = useState(true);

	// Hide from tutor for feedback
	const [anonymousFeedback, setAnonFeedback] = useState(false);

	const handleCloseFeedback = () => {
		setResolvedTicketFeedback(true);
		setAnonFeedback(false);
		setOpenFeedback(false);
	};

	// Boolean Togglers for Feedback
	const toggleAnonFeedback = () => {
		setAnonFeedback(!anonymousFeedback);
	};

	const toggleResolvedTicketFeedback = () => {
		setResolvedTicketFeedback(!resolvedTicketFeedback);
	};
	const handleSubmitFeedback = () => {
		handleCloseFeedback();
		server.submitTicketFeedback(
			resolvedTicket.queue_id,
			resolvedTicket.ticket_id,
			Number(rating),
			resolvedTicket.ec_grader_id,
			addFeedback,
			anonymousFeedback
		);
	};
	return (
		resolvedTicket && (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={inverseTheme}>
					<Dialog
						open={openFeedback}
						onClose={handleCloseFeedback}
						aria-labelledby="feedback-dialog-title"
					>
						<DialogTitle
							sx={styles.title}
							id="feedback-dialog-title"
						>
							Ticket Feedback
						</DialogTitle>
						<DialogContent>
							<div>
								{/* Toggle tutor resolved ticket */}
								<FormControlLabel
									sx={styles.check}
									control={
										<Checkbox
											sx={styles.check}
											onClick={toggleResolvedTicketFeedback}
											color="primary"
											checked={resolvedTicketFeedback}
										/>
									}
									label="Ticket Resolved?"
								/>

								{/* Dropdown tutor rating */}
								<FormControl
									variant="standard"
									sx={styles.locationfield}
									fullWidth
									required
								>
									<InputLabel htmlFor="age-native-simple">Tutor Rating</InputLabel>
									<Select
										native
										onChange={(e) => setRating(e.target.value)}
									>
										<option value="2">Good :)</option>
										<option value="1">Okay :/</option>
										<option value="0">Bad :(</option>
									</Select>
								</FormControl>

								<TextField
									sx={styles.text}
									onChange={(e) => setAddFeedback(e.target.value)}
									variant="outlined"
									id="name"
									label="Additional Feedback"
									multiline
									minRows="6"
									fullWidth
								/>

								{/* Toggle anon feedback */}
								<FormControlLabel
									sx={styles.check}
									control={
										<Checkbox
											sx={styles.check}
											onClick={toggleAnonFeedback}
											color="primary"
											checked={anonymousFeedback}
										/>
									}
									label="Make Feedback Anonymous?"
								/>
							</div>
						</DialogContent>

						<DialogActions>
							<Button
								sx={styles.submit}
								onClick={handleCloseFeedback}
								color="primary"
								variant="outlined"
							>
								Cancel
							</Button>
							<Button
								sx={styles.submit}
								onClick={handleSubmitFeedback}
								color="primary"
								variant="outlined"
							>
								Submit
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
			</StyledEngineProvider>
		)
	);
}
TicketFeedbackForm.propTypes = {
	// whether to open the feedback or not
	openFeedback: PropTypes.bool.isRequired,
	setOpenFeedback: PropTypes.func.isRequired,
	// the ticket object that the feedback is being given to
	resolvedTicket: PropTypes.object
};
