import AirplayIcon from "@mui/icons-material/Airplay";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import EventIcon from "@mui/icons-material/Event";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import server from "../../server";
import Theme from "../../style/Theme";
import Queue from "./Queue";
import TutorCalendar from "./TutorCalendar";

// displays the props being passed in a panel only
// if correct index has been passed
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

// Provides accessibility for the tabs
function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`
	};
}

export default function MiddleWidget(props) {
	MiddleWidget.propTypes = {
		queueData: PropTypes.object
	};

	const isDesktop = useMediaQuery("(min-width: 900px)");
	const { course_id } = useParams();
	const [value, setValue] = useState(0);
	const [url, setURL] = useState("");

	// reference: https://stackoverflow.com/questions/50965941/material-ui-tab-label-font-size-is-really-small
	const tabStyles = {
		fontSize: "10px"
	};

	// applies tabStyle to the Tab
	function TabSmaller(props) {
		return (
			<Tab
				{...props}
				sx={tabStyles}
			/>
		);
	}

	const WEBSITE_INDEX = 2;
	const handleChange = (event, newValue) => {
		if (newValue !== WEBSITE_INDEX) setValue(newValue);
	};

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	// change the website to the new url and get back to the home page if there no url
	useEffect(() => {
		server.findCourseByID(course_id).then((result) => {
			console.debug("Course data:", result.data.result);
			let newURL = result.data.result.url;
			if (newURL === "") {
				newURL = "https://cse.ucsd.edu/";
			} else if (!newURL.includes("http")) {
				newURL = `https://${newURL}`;
			}
			setURL(newURL);
		});
	}, [course_id]);

	return (
		<Box
			sx={{
				color: "white !important",
				width: "100%",
				display: "flex",
				flexDirection: { xs: "column", md: "row" }
			}}
		>
			<Box>
				<ThemeProvider theme={Theme.darkTheme}>
					<Tabs
						orientation={isDesktop ? "vertical" : "horizontal"}
						// variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						centered
						TabIndicatorProps={{ style: { background: "white" } }}
						sx={{
							"& .Mui-selected": { color: "white !important" }
						}}
					>
						<TabSmaller
							icon={<DynamicFeedIcon fontSize="small" />}
							label="Tickets"
							{...a11yProps(0)}
						/>
						<TabSmaller
							icon={<EventIcon fontSize="small" />}
							label="Calendar"
							{...a11yProps(1)}
						/>
						<TabSmaller
							onClick={() => openInNewTab(url)}
							icon={<AirplayIcon fontSize="small" />}
							label="Course Website"
							{...a11yProps(2)}
						/>
					</Tabs>
				</ThemeProvider>
			</Box>
			<Box sx={{ width: "100%" }}>
				<TabPanel
					value={value}
					index={0}
				>
					<Queue
						course_id={parseInt(course_id)}
						queueData={props.queueData}
					/>
				</TabPanel>
				<TabPanel
					style={{ width: "100%" }}
					value={value}
					index={1}
				>
					<TutorCalendar queueData={props.queueData} />
				</TabPanel>
			</Box>
		</Box>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};
