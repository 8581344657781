export default {
	resolved: {
		"&:hover": {
			boxShadow: "4px 5px #3d5259",
			cursor: "pointer"
		},
		margin: 2,
		backgroundColor: "#2282a3",
		borderRadius: "15px 15px 2px"
	},
	returned: {
		margin: 2,
		backgroundColor: "#44633F",
		borderRadius: "15px 15px 2px"
	},
	accepted: {
		"&:hover": {
			boxShadow: "2px 5px #888888",
			cursor: "pointer"
		},
		margin: 2,
		backgroundColor: "#5A9367",
		borderRadius: "15px 15px 2px"
	},
	cancelled: {
		"&:hover": {
			boxShadow: "4px 5px #3d5259",
			cursor: "pointer"
		},
		margin: 2,
		backgroundColor: "#5b6366",
		borderRadius: "15px 15px 2px"
	},
	edited: {
		margin: 2,
		backgroundColor: "#5CAB7D",
		borderRadius: "15px 15px 2px"
	},
	created: {
		margin: 2,
		backgroundColor: "#3E885B",
		borderRadius: "15px 15px 2px"
	},
	tableCellStatus: (theme) => ({
		[theme.breakpoints.down(850)]: {
			display: "table-row"
		},
		[theme.breakpoints.up(851)]: {
			display: "table-cell"
		}
	}),
	tableCellTutor: (theme) => ({
		[theme.breakpoints.down(850)]: {
			display: "table-row",
			textAlign: "left"
		},
		[theme.breakpoints.up(851)]: {
			display: "table-cell",
			textAlign: "right"
		}
	}),
	tableRow: {
		display: "table-row",
		width: "100%"
	},
	table: {
		display: "table",
		width: "100%"
	},
	bold: {
		fontWeight: "bold"
	},
	ticketCard: {
		"&:hover": {
			backgroundColor: "red"
		}
	}
};
