import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Context } from "../../../context/Context";
import styles from "../../../style/SmallWidget/AnnouncementStyle";

export default function Announcement(props) {
	Announcement.propTypes = {
		owner: PropTypes.string,
		subject: PropTypes.string,
		body: PropTypes.string,
		last_edited_at: PropTypes.string,
		handleArchive: PropTypes.func
	};

	const {
		state: { role }
	} = useContext(Context);

	function timestamp() {
		const date_obj = new Date(props.last_edited_at);
		const date = format(date_obj, "eee MMM dd yyyy");
		const time = format(date_obj, "hh:mm aa");

		return `${date}, ${time}`;
	}

	return (
		<Box sx={styles.announcement}>
			<Card
				variant="outlined"
				sx={styles.messageyou}
			>
				<CardContent sx={styles.cardContent}>
					<Typography
						variant="h6"
						sx={styles.messageSubject}
					>
						{props.subject}
					</Typography>
					<Typography
						variant="body2"
						sx={styles.messageSender}
					>
						by {props.owner} @ {timestamp()}
					</Typography>
					<Typography
						variant="subtitle2"
						sx={styles.messageText}
					>
						{props.body}
					</Typography>
					{role !== "STUDENT" && (
						<Button
							variant="contained"
							size="small"
							onClick={props.handleArchive}
						>
							Archive
						</Button>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}
