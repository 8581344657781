import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

export function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

export const TICKET_INFO_OBJECT = {
	description: "",
	tutorName: "",
	studentName: "",
	room: "",
	workstation: "",
	createdAt: "",
	closedAt: "",
	acceptedAt: "",
	ticketStatus: "",
	tags: [],
	isPrivate: false
};
