import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../conf";
import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/CourseSettings/StaffPageStyle";
import OurTheme from "../../style/Theme";
import { GRADER_HEADERS, INSTRUCTOR_HEADERS } from "./consts";

function createData(fname, lname, email, id) {
	return { fname, lname, email, id };
}

export default function StaffPageTab(props) {
	StaffPageTab.propTypes = {
		handleOpen: PropTypes.func
	};

	const { theme } = OurTheme;
	const { inverseTheme } = OurTheme;

	const [openAddStaff, setOpenAddStaff] = useState(false);
	const [openCreateUser, setOpenCreateUser] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [fname, setFName] = useState("");
	const [lname, setLName] = useState("");
	const [pid, setPid] = useState("");
	const [userID, setUserID] = useState(0); // eslint-disable-line no-unused-vars
	const [sectID, setSectID] = useState(0); // eslint-disable-line no-unused-vars
	const { course_id } = useParams();
	// const [open, setOpen] = useState(false);
	// const [errMessage, setErrMessage] = useState("");
	const [isCourseAdmin, setIsCourseAdmin] = useState(false);
	const [instructors, setInstructors] = useState([]);
	const [graders, setGraders] = useState([]);
	const {
		state: { user }
	} = useContext(Context);

	const handleAddGradRow = (f, l, e, i) => {
		const tmpGraders = [...graders, createData(f, l, e, i)];
		tmpGraders.sort((a, b) => a.fname.localeCompare(b.fname));
		setGraders(tmpGraders);
	};

	const handleAddInstRow = (f, l, e, i) => {
		const tmpInstructors = [...instructors, createData(f, l, e, i)];
		tmpInstructors.sort((a, b) => a.fname.localeCompare(b.fname));
		setInstructors(tmpInstructors);
	};

	const handleRemoveInstrRow = (idx) => {
		const row = [...instructors];
		row.splice(idx, 1);
		setInstructors(row);
	};

	const handleRemoveGradRow = (idx) => {
		const row = [...graders];
		row.splice(idx, 1);
		setGraders(row);
	};

	const handleSort = (field, instructor) => {
		// [].concat is necessary for state update without changed reference
		if (instructor) {
			setInstructors([].concat(instructors).sort((a, b) => a[field].localeCompare(b[field])));
		} else {
			setGraders([].concat(graders).sort((a, b) => a[field].localeCompare(b[field])));
		}
	};

	const handleDemoteButton = (first, last, email, user_id, idx) => {
		server
			.changeRoleWithEmail(email, Number(course_id), 1, "GRADER")
			.then(() => {
				// Direct to queue page
				console.log("Role change successful!");
				handleRemoveInstrRow(idx);
				handleAddGradRow(first, last, email, user_id);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePromoteButton = (first, last, email, user_id, idx) => {
		server
			.changeRoleWithEmail(email, Number(course_id), 1, "INSTRUCTOR")
			.then(() => {
				// Direct to queue page
				console.log("Role change successful!");
				handleRemoveGradRow(idx);
				handleAddInstRow(first, last, email, user_id);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleClose = () => {
		setOpenAddStaff(false);
		setOpenCreateUser(false);
		setUserEmail("");
		setFName("");
		setLName("");
		setPid("");
	};

	const handleAdd = () => {
		const inst = instructors.find((e) => userEmail === e.email);
		const grad = graders.find((e) => userEmail === e.email);
		if (inst || grad) {
			handleClose();
			props.handleOpen("User is already a grader or instructor!", 400);
			return;
		}

		props.handleOpen("Attempting to add user...", 200);
		server
			.changeRoleWithEmail(userEmail, Number(course_id), 1, "GRADER")
			.then((res) => {
				if (res.status === 200) {
					handleClose();
					props.handleOpen("Successfully added & notified via email!", 200);
					handleAddGradRow(res.data.reason.fname, res.data.reason.lname, userEmail, res.data.reason.id);
				}
			})
			.catch((err) => {
				handleClose();
				props.handleOpen(err.response.data.reason, 400);
				console.error(err);
			});
	};

	const handleAddUser = () => {
		// if userEmail !=
		const isEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail);
		if (!isEmail) {
			alert("invalid email");
			return;
		}

		props.handleOpen("Attempting to add user...", 200);
		server
			.createUser(userEmail, fname, lname, pid)
			.then((res) => {
				if (res.status === 200) {
					server
						.changeRoleWithEmail(res.data.user.email, Number(course_id), 1, "GRADER")
						.then((res) => {
							if (res.status === 200) {
								props.handleOpen("Successfully added & notified via email!", 200);
								handleAddGradRow(
									res.data.reason.fname,
									res.data.reason.lname,
									userEmail,
									res.data.reason.id
								);
							}
						})
						.catch((err) => {
							alert(err.response.data.reason);
							console.error(err);
						});
				}
			})
			.catch((err) => {
				alert(err.response.data.reason);
				console.error(err);
			});
		handleClose();
	};

	const handleRemoveButton = (fname, lname, user_id, idx) => {
		if (window.confirm(`Are you sure you would like to remove ${fname} ${lname}?`)) {
			server
				.deleteUserFromCourse(user_id, Number(course_id))
				.then((res) => {
					if (res.status === 200) {
						handleRemoveGradRow(idx);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
		handleClose();
	};

	const getStripedStyle = (index) => (index % 2 ? { background: "#d1dae3" } : { background: "white" });

	useEffect(() => {
		const apiBaseUrl = "/api/enrolled_course/get_all_user_in_course";
		api.get(apiBaseUrl, {
			params: { course_id, roles: "ADMIN;INSTRUCTOR" },
			withCredentials: true
		}).then((response) => {
			for (const i of response.data.result) {
				// if the current user is an admin, enhance privileges
				if (i.user_info.email === user.email && i.enrolled_user_info.role === "ADMIN") {
					setIsCourseAdmin(true);
				}
			}
			const instructors = response.data.result.map((user) => user.user_info);
			instructors.sort((a, b) => a.fname.localeCompare(b.fname));
			setInstructors(instructors);
		});
		api.get(apiBaseUrl, {
			params: { course_id, roles: "GRADER" },
			withCredentials: true
		}).then((response) => {
			const graders = response.data.result.map((user) => user.user_info);
			graders.sort((a, b) => a.fname.localeCompare(b.fname));
			setGraders(graders);
		});
	}, [course_id, user.email]);

	const renderCells = (row, index) =>
		INSTRUCTOR_HEADERS.map((header) =>
			header.field === "edit" ? (
				isCourseAdmin && (
					<TableCell
						align="center"
						key={header.label}
					>
						<IconButton
							aria-label="edit"
							size="large"
						>
							<EditIcon fontSize="small" />
						</IconButton>
					</TableCell>
				)
			) : header.field === "demote" ? (
				isCourseAdmin && (
					<TableCell
						sx={styles.cell}
						align="center"
						key={header.label}
					>
						{user.email !== row.email && (
							<IconButton
								onClick={() => handleDemoteButton(row.fname, row.lname, row.email, row.id, index)}
								aria-label="demote button"
								size="large"
							>
								<ArrowDownwardIcon style={{ fontSize: "small" }} />
							</IconButton>
						)}
					</TableCell>
				)
			) : (
				<TableCell
					sx={styles.cell}
					align="left"
					key={header.label}
				>
					{row[header.field]}
				</TableCell>
			)
		);

	const renderCells2 = (row, index) =>
		GRADER_HEADERS.map((header) =>
			header.field === "remove" ? (
				isCourseAdmin && (
					<TableCell
						align="center"
						key={header.label}
					>
						<IconButton
							onClick={() => handleRemoveButton(row.fname, row.lname, row.id, index)}
							aria-label="remove"
							size="large"
						>
							<TrashIcon fontSize="small" />
						</IconButton>
					</TableCell>
				)
			) : header.field === "promote" ? (
				isCourseAdmin && (
					<TableCell
						sx={styles.cell}
						align="center"
						key={header.label}
					>
						<IconButton
							onClick={() => handlePromoteButton(row.fname, row.lname, row.email, row.id, index)}
							aria-label="demote button"
							size="large"
						>
							<ArrowUpwardIcon style={{ fontSize: "small" }} />
						</IconButton>
					</TableCell>
				)
			) : (
				<TableCell
					sx={styles.cell}
					align="left"
					key={header.label}
				>
					{row[header.field]}
				</TableCell>
			)
		);

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							xs={3}
						>
							<Typography
								variant="h6"
								sx={styles.h2}
							>
								INFO
							</Typography>
							<Typography sx={styles.information}>
								Total Staff: {instructors.length + graders.length}
							</Typography>
							<Typography sx={styles.information}>Instructors: {instructors.length}</Typography>

							<Typography
								variant="h6"
								sx={styles.h2}
							>
								OPTIONS
							</Typography>
							<Box sx={styles.buttons}>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => setOpenAddStaff(true)}
								>
									Add Staff
								</Button>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => setOpenCreateUser(true)}
								>
									Create Grader
								</Button>
								{/* <Button color={"secondary"} variant="contained">Restore Staff</Button>
                                <Button color={"secondary"} variant="contained">Import Previous Staff</Button>
                                <Button color={"secondary"} variant="contained">Delete All Staff</Button> */}
							</Box>
						</Grid>

						<Grid
							item
							xs={9}
						>
							<div>
								<Typography
									variant="h6"
									sx={styles.h2}
								>
									INSTRUCTORS (ADMIN)
								</Typography>
								<TableContainer
									sx={styles.iTable}
									component={Paper}
								>
									<Table
										stickyHeader
										sx={styles.iTable}
										size="small"
										aria-label="sticky table"
									>
										<TableHead>
											<TableRow
												style={{
													background: "#d1dae3"
												}}
											>
												{INSTRUCTOR_HEADERS.map((header) =>
													header.field === "edit" || header.field === "demote" ? (
														isCourseAdmin && (
															<TableCell
																align="center"
																sx={styles.col}
																key={header.label}
															>
																{header.label}
															</TableCell>
														)
													) : (
														<TableCell
															align="left"
															sx={styles.col}
															key={header.label}
														>
															{header.label}
															<IconButton
																aria-label="sort"
																onClick={() => handleSort(header.field, true)}
																size="large"
															>
																<ExpandMore fontSize="small" />
															</IconButton>
														</TableCell>
													)
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{instructors.map((row, index) => (
												<TableRow
													style={getStripedStyle(index)}
													key={index}
												>
													{renderCells(row, index)}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>

								<Typography
									variant="h6"
									sx={styles.h2}
								>
									GRADERS
								</Typography>
								<TableContainer
									sx={styles.gTable}
									component={Paper}
								>
									<Table
										sx={styles.gTable}
										size="small"
										aria-label="a dense table"
									>
										<TableHead style={{ position: "sticky" }}>
											<TableRow
												style={{
													background: "#d1dae3"
												}}
											>
												{GRADER_HEADERS.map((header) =>
													header.field === "remove" || header.field === "promote" ? (
														isCourseAdmin && (
															<TableCell
																align="center"
																sx={styles.col}
																key={header.label}
															>
																{header.label}
															</TableCell>
														)
													) : (
														<TableCell
															align="left"
															sx={styles.col}
															key={header.label}
														>
															{header.label}
															<IconButton
																aria-label="sort"
																onClick={() => handleSort(header.field, false)}
																size="large"
															>
																<ExpandMore fontSize="small" />
															</IconButton>
														</TableCell>
													)
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{graders.map((row, index) => (
												<TableRow
													style={getStripedStyle(index)}
													key={index}
												>
													{renderCells2(row, index)}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</Grid>
					</Grid>

					<ThemeProvider theme={inverseTheme}>
						<Dialog
							open={openAddStaff}
							onClose={handleClose}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle
								id="form-dialog-title"
								sx={styles.dialogue}
							>
								Add Staff
							</DialogTitle>
							<DialogContent>
								<DialogContentText
									sx={{
										...styles.dialogue,
										marginBottom: "1em"
									}}
								>
									Enter staff information. If this user does not exist yet, use &lsquo;Create
									Grader&rsquo;
								</DialogContentText>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="User Email"
									sx={styles.input}
									error={userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)}
									helperText={
										userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
											? "Must be a valid email"
											: ""
									}
									onChange={(event) => setUserEmail(event.target.value)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									sx={styles.dialogue}
								>
									Cancel
								</Button>
								<Button
									onClick={handleAdd}
									variant="contained"
									color="inherit"
								>
									Add Staff
								</Button>
							</DialogActions>
						</Dialog>
					</ThemeProvider>
					<ThemeProvider theme={inverseTheme}>
						<Dialog
							open={openCreateUser}
							onClose={handleClose}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle
								id="form-dialog-title"
								sx={styles.dialogue}
							>
								Create Grader
							</DialogTitle>
							<DialogContent>
								<DialogContentText
									sx={{
										...styles.dialogue,
										marginBottom: "1em"
									}}
								>
									Enter user information. This will create a user if not already in the db and add
									them to this course&apos;s staff.
								</DialogContentText>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="User Email"
									sx={styles.input}
									type="email"
									onChange={(event) => setUserEmail(event.target.value)}
									error={userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)}
									helperText={
										userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
											? "Must be a valid email"
											: ""
									}
								/>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="User First Name"
									sx={styles.input}
									onChange={(event) => setFName(event.target.value)}
								/>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="User Last Name"
									sx={styles.input}
									onChange={(event) => setLName(event.target.value)}
								/>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="User PID"
									sx={styles.input}
									onChange={(event) => setPid(event.target.value)}
									error={pid !== "" && !/.[0-9]{8}$/.test(pid)}
									helperText={
										pid !== "" && !/.[0-9]{8}$/.test(pid) ? "Must be a valid PID (A12345678)" : ""
									}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									sx={styles.dialogue}
								>
									Cancel
								</Button>
								<Button
									onClick={handleAddUser}
									variant="contained"
									color="inherit"
								>
									Create Grader
								</Button>
							</DialogActions>
						</Dialog>
					</ThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
