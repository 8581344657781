export default {
	form: {
		"& > *": {
			margin: 1,
			position: "relative",
			textAlign: "center",
			verticalAlign: "middle"
		},
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white"
			}
		},
		display: "inline-block"
	},
	formControl: {
		margin: 1,
		minWidth: 200
	},
	message: {
		"& > *": {
			margin: 1,
			position: "relative",
			textAlign: "center",
			verticalAlign: "middle"
		},
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white"
			}
		},
		display: "inline-block",
		width: "100%"
	},
	wrapper: {
		textAlign: "center"
	},
	wrapper2: {
		display: "inline-block",
		marginTop: "20px",
		width: "70%"
	},
	title: {
		color: "white",
		fontWeight: "bold",
		fontSize: "3em"
	},
	body: {
		display: "inline-block",
		width: "30%",
		verticalAlign: "top"
	},
	submit: {
		display: "inline-block",
		marginTop: "50px"
	},
	table: {
		color: "black"
	},
	viewTicket: {
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer"
		}
	},
	rowOne: {
		background: "white"
	},
	rowTwo: {
		background: "#d1dae3"
	}
};
