import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		// Link when unclicked
		primary: {
			main: "#ffffff"
		},
		secondary: {
			main: "#d1dae3"
		},
		text: {
			// Input box when hovered over
			primary: "#ffffff",
			// Text in input box when unclicked
			secondary: "#ffffff"
		}
	},
	typography: {
		// Link and Input box text
		fontFamily: "Quicksand",
		fontSize: 16
	}
});

const inverseTheme = createTheme({
	palette: {
		// Link when unclicked
		primary: {
			main: "#000000"
		},
		secondary: {
			// added for TicketHistory page's buttons
			main: "#ffffff"
		},
		text: {
			// Input box when hovered over
			primary: "#000000",
			// Text in input box when unclicked
			secondary: "#000000"
		}
	},
	typography: {
		// Link and Input box text
		fontFamily: "Quicksand",
		fontSize: 16
	}
});

const darkTheme = createTheme({
	palette: {
		mode: "dark"
	},
	typography: {
		// Link and Input box text
		fontFamily: "Quicksand",
		fontSize: 16
	}
});

export default { theme, inverseTheme, darkTheme };
