import { Box, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Context } from "../../../context/Context";
import server from "../../../server";
import styles from "../../../style/SmallWidget/TicketEventWidgetStyle";
import TicketEvent from "./TicketEvent";

/**
 * @component TicketEventWidget
 * Displays all the events for a given ticket
 * Contains a box to leave comments at the bottom of the component
 */
export default function TicketEventWidget(props) {
	const { course_id } = useParams();
	const eventListRef = useRef(null);

	const { ticketEventList } = props;
	const { openedTicket } = props;
	const { ecId } = props;
	const [tempComment, setTempComment] = useState("");

	const {
		state: { user }
	} = useContext(Context);

	const addComment = (input) => {
		if (input === "\n") {
			setTempComment(""); // when we input a newline it adds an extra one – need to reset the comment
		} else {
			setTempComment(input);
		}
	};

	const submitComment = (e) => {
		if (e.keyCode === 13) {
			// code for enter key
			server.addComment({
				queue_id: course_id,
				message: tempComment,
				ticket_id: openedTicket,
				first_name: user.fname,
				last_name: user.lname
			});
			setTempComment("");
		}
	};

	useEffect(() => {
		if (eventListRef.current) {
			eventListRef.current.scrollBy(0, eventListRef.current.scrollHeight);
		}
	}, [openedTicket, ticketEventList]);

	return (
		<div>
			{openedTicket ? (
				<div id="openedTicket">
					<Box
						sx={styles.ticketEventList}
						ref={eventListRef}
					>
						{ticketEventList &&
							ticketEventList.map((event, index) => (
								<TicketEvent
									key={index}
									eventType={event.event_type}
									message={event.message}
									ecId={event.ec_user_id}
									userEcId={ecId} // except the user_id is different from the ec_id
									firstName={event.first_name}
									lastName={event.last_name}
									timestamp={new Date(event.timestamp)}
								/>
							))}
					</Box>
					<TextField
						value={tempComment}
						variant="outlined"
						placeholder="Enter a comment..."
						id="name"
						label="Comment"
						multiline
						minRows="3"
						fullWidth
						margin="normal"
						sx={{
							"& fieldset": { borderColor: "white !important" }
						}}
						onChange={(e) => addComment(e.target.value)}
						onKeyDown={submitComment}
						inputProps={{ style: { color: "white" } }}
						InputLabelProps={{ style: { color: "white" } }}
					/>
				</div>
			) : (
				<Typography
					variant="body1"
					align="center"
				>
					Choose a ticket to view its comments
				</Typography>
			)}
		</div>
	);
}

TicketEventWidget.propTypes = {
	ticketEventList: PropTypes.any,
	openedTicket: PropTypes.any,
	ecId: PropTypes.any
};
