import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Collapse,
	FormControl,
	Grid,
	InputLabel,
	Link,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { styled, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useRef, useState } from "react";

import Navbar from "../components/Navbar";
import VisitorNavbar from "../components/VisitorNavbar";
import { Context } from "../context/Context";
import server from "../server";
import styles from "../style/CreateCourseStyle";
import OurTheme from "../style/Theme";

export default function CreateCourse() {
	const { theme } = OurTheme;

	const textFieldClasses = { ...styles.formControl, ...styles.textField };

	const CustomAlert = styled(Alert)({
		"& .MuiAlert-icon": {
			fontSize: 40
		},
		"& ,MuiAlert-root": {
			display: "flex",
			alignItems: "center"
		},
		"& .MuiAlert-message": {
			fontSize: 20
		},
		"& .MuiSvgIcon-fontSizeSmall": {
			fontSize: "1em"
		}
	});

	const [openMissingField, setOpenMissingField] = useState(false);
	const [openCorrectFormat, setOpenCorrectFormat] = useState(false);

	const [open, setOpen] = useState(false);
	const {
		state: { user }
	} = useContext(Context);

	const [courseTitle, setCourseTitle] = useState("");
	const [quarter, setQuarter] = useState("");
	const [year, setYear] = useState("");
	const [shortName, setShortName] = useState("");
	const [url, setURL] = useState("");

	const [fname, setFname] = useState(user.fname || "");
	const [lname, setLname] = useState(user.lname || "");
	const [email, setEmail] = useState(user.email || "");

	const [studentRoster, setStudentRoster] = useState();
	const [waitlistRoster, setWaitlistRoster] = useState();
	const [staffRoster, setStaffRoster] = useState();

	const form = useRef(null);

	const validateForm = () => {
		if (
			courseTitle &&
			quarter &&
			year &&
			shortName &&
			url &&
			fname &&
			lname &&
			email &&
			studentRoster &&
			fname &&
			lname &&
			email
		)
			return true;

		console.warn("You are missing at least one required field");
		setOpenMissingField(true);
		setTimeout(() => {
			setOpenMissingField(false);
		}, 10000);

		return false;
	};

	const handleSubmit = () => {
		if (validateForm()) {
			const reader = new FileReader();
			reader.onload = (e) => {
				// The file's text will be printed here...this is just for debuggin
				console.debug("File's text:", e.target.result); // Turn on Verbose console level to view
			};
			reader.readAsText(studentRoster);

			const formData = new FormData(form.current);
			formData.append("students", studentRoster);
			formData.append("waitlist", waitlistRoster);
			formData.append("instructors", staffRoster);
			formData.append("name", courseTitle);
			formData.append("quarter", quarter);
			formData.append("short_name", shortName);
			formData.append("description", "Placeholder description"); // do we need this? Maybe use this for notes to the Queues team
			formData.append("year", year);
			formData.append("cse", true);
			formData.append("queue_lock", false);
			formData.append("url", url);
			formData.append("user", user.id);
			formData.append("email", email);
			formData.append("fname", fname);
			formData.append("lname", lname);

			console.debug(formData); // Turn on Verbose console level to view

			server
				.createCourse(formData)
				.then(() => {
					setOpen(true);
					setTimeout(() => {
						setOpen(false);
					}, 10000);
				})
				.catch((err) => {
					console.error("Error creating course: ", err);
					setOpenCorrectFormat(true);
					setTimeout(() => {
						setOpenCorrectFormat(false);
					}, 10000);
				});
		}
	};

	function handleDownload() {
		// Start download by creating download button & clicking it
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = "/staff_template.csv";
		a.download = "staff_template.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseMissingField = () => {
		setOpenMissingField(false);
	};

	const handleCloseCorrectFormat = () => {
		setOpenCorrectFormat(false);
	};

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Box sx={styles.alert}>
						<Collapse in={open}>
							<CustomAlert
								onClose={handleClose}
								severity="success"
							>
								Course Creation Request has been sent! You will receive an email to {email} once the
								course has been approved by the Queues team.
							</CustomAlert>
						</Collapse>
						<Collapse in={openMissingField}>
							<CustomAlert
								onClose={handleCloseMissingField}
								severity="error"
							>
								You are missing at least one required field.
							</CustomAlert>
						</Collapse>
						<Collapse in={openCorrectFormat}>
							<CustomAlert
								onClose={handleCloseCorrectFormat}
								severity="error"
							>
								Make sure your CSV file matches the expected format.
							</CustomAlert>
						</Collapse>
					</Box>
					{user ? <Navbar /> : <VisitorNavbar />}
					<Box sx={styles.wrappertitle}>
						<Typography sx={styles.title}>Create Course</Typography>
						<div>The Queues team will review your request for approval and follow up via email.</div>

						<Typography sx={{ fontWeight: "bold", marginTop: "2em" }}>
							👋 Hi, thanks for your interest in Queues!
							<br />
							Queues is currently in development and not yet ready for public beta testing.
							<br />
							Apologies for the inconvenience, please use{" "}
							<Link href="https://autograder.ucsd.edu/">Autograder</Link> for the time being.
						</Typography>
					</Box>

					{/* <Grid
						container
						justifyContent="center"
						sx={styles.courseForm}
					>
						<Grid
							item
							xs={3}
							container
							sx={styles.courseCol}
						>
							<Box
								as="form"
								sx={styles.form}
							>
								<Typography
									variant="h5"
									style={{ fontWeight: "bold" }}
								>
									Course Info
								</Typography>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="Course Title"
									placeholder="Basic Data Structures"
									variant="outlined"
									onChange={(event) => setCourseTitle(event.target.value)}
								/>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="Course Code"
									placeholder="CSE 12"
									variant="outlined"
									onChange={(event) => setShortName(event.target.value)}
								/>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="Course URL"
									placeholder="ieng6.ucsd.edu"
									variant="outlined"
									onChange={(event) => setURL(event.target.value)}
								/>
								<FormControl
									margin="dense"
									variant="standard"
									sx={styles.formControl}
								>
									<InputLabel required>Course Quarter</InputLabel>
									<Select
										value={quarter}
										onChange={(event) => setQuarter(event.target.value)}
									>
										<MenuItem
											sx={{ color: "black" }}
											value="SS1"
										>
											SS1
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value="SS2"
										>
											SS2
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value="FA"
										>
											FA
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value="WI"
										>
											WI
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value="SP"
										>
											SP
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl
									margin="dense"
									variant="standard"
									sx={styles.formControl}
								>
									<InputLabel required>Course Year</InputLabel>
									<Select
										value={year}
										onChange={(event) => setYear(event.target.value)}
									>
										<MenuItem
											sx={{ color: "black" }}
											value={2023}
										>
											2023
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value={2024}
										>
											2024
										</MenuItem>
										<MenuItem
											sx={{ color: "black" }}
											value={2025}
										>
											2025
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</Grid>

						<Grid
							item
							xs={3}
							container
							sx={styles.courseCol}
						>
							<Box
								as="form"
								sx={styles.form}
							>
								<Typography
									variant="h5"
									style={{ fontWeight: "bold" }}
								>
									Instructor Info
								</Typography>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="First Name"
									placeholder="Gary"
									variant="outlined"
									value={fname}
									onChange={(event) => setFname(event.target.value)}
								/>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="Last Name"
									placeholder="Gillespie"
									variant="outlined"
									value={lname}
									onChange={(event) => setLname(event.target.value)}
								/>
								<TextField
									required
									margin="dense"
									size="small"
									sx={textFieldClasses}
									label="Email"
									placeholder="professor@ucsd.edu"
									variant="outlined"
									value={email}
									onChange={(event) => setEmail(event.target.value)}
								/>
							</Box>
						</Grid>
					</Grid>

					<Typography
						variant="h5"
						style={{
							fontWeight: "bold",
							color: "white",
							textAlign: "center",
							marginBottom: "0.5em"
						}}
					>
						Roster Info
					</Typography>
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="between"
						spacing={8}
					>
						<Grid item>
							<Accordion
								style={{
									backgroundColor: "transparent",
									boxShadow: "none",
									width: "320px",
									textAlign: "center",
									border: "1px solid white"
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography
										variant="body1"
										style={{
											fontSize: "12px",
											padding: "0px"
										}}
									>
										Where do I get a course roster?
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										style={{
											fontSize: "14px",
											textAlign: "left"
										}}
									>
										1. Go to blink.ucsd.edu.
										<br />
										2. Open the ‘Instruction Tools’ drop down list and select ‘Class Lists’.
										<br />
										3. Use the filtering options to find your class list. Typically by using your
										last name as the search option.
										<br />
										4. Download your current roster in the ‘standard format’.
										<br />
										5. (optional) If you wish to include waitlisted students, download the waitlist
										roster append their information to your first roster list.
										<br />
										6. If you are unable to get a blink roster, please email us at queues@ucsd.edu
										<br />
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Box
								as="form"
								id="testform"
								ref={form}
								sx={{
									...styles.form,
									padding: "0",
									textAlign: "center !important"
								}}
							>
								<InputLabel
									required
									sx={styles.formControl}
									htmlFor="age-native-simple"
								>
									Course Roster
								</InputLabel>
								<input
									style={styles.fileInput}
									accept=".txt,.csv,.xls,.xlsx"
									type="file"
									onChange={(e) => setStudentRoster(e.target.files[0])}
								/>
							</Box>
						</Grid>

						<Grid item>
                            <Accordion style={{
									backgroundColor: "transparent",
									boxShadow: "none",
									width: "320px",
									textAlign: "center",
									border: "1px solid white"
								}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="body1" style={{ fontSize: "12px", padding: "0px" }}>
                                        Where do I get a waitlist roster?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{ fontSize: "12px", textAlign: "left" }}>
                                        1. Go to blink.ucsd.edu.<br />
                                        2. Open the ‘Instruction Tools’ drop down list and select ‘Class Lists’.<br />
                                        3. Use the filtering options to find your class list. Typically by using your last name as the search option.<br />
                                        4. Download your waitlist roster in the ‘standard format’.<br />
                                        5. If you are unable to get a blink roster, please email us at queues@eng.ucsd.edu<br />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
							<Box
								as="form"
								sx={{
									...styles.form,
									padding: "0",
									textAlign: "center !important"
								}}
							>
								<InputLabel
									sx={styles.formControl}
									htmlFor="age-native-simple"
								>
									Waitlist Roster (Optional)
								</InputLabel>
								<input
									style={styles.fileInput}
									accept=".csv,.xls,.xlsx"
									type="file"
									onChange={(e) => setWaitlistRoster(e.target.files[0])}
								/>
							</Box>
                        </Grid>

						<Grid item>
							<Accordion
								style={{
									backgroundColor: "transparent",
									boxShadow: "none",
									width: "320px",
									textAlign: "center",
									border: "1px solid white"
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography
										variant="body1"
										style={{
											fontSize: "12px",
											padding: "0px"
										}}
									>
										Where do I get a staff roster?
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										style={{
											fontSize: "14px",
											textAlign: "left"
										}}
									>
										Download the template below & fill it out with your staff&apos;s information.
										<br />
										<br />
										<Button
											variant="outlined"
											onClick={handleDownload}
										>
											staff_template.csv
										</Button>
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Box
								as="form"
								sx={{
									...styles.form,
									padding: "0",
									textAlign: "center !important"
								}}
							>
								<InputLabel
									sx={styles.formControl}
									htmlFor="age-native-simple"
								>
									Staff Roster (Optional)
								</InputLabel>
								<input
									style={styles.fileInput}
									accept=".csv,.xls,.xlsx"
									type="file"
									onChange={(e) => setStaffRoster(e.target.files[0])}
								/>
							</Box>
						</Grid>
					</Grid>

					<Box sx={styles.wrapper}>
						<Button
							sx={styles.submit}
							onClick={handleSubmit}
							variant="outlined"
							color="primary"
							type="submit"
						>
							Submit Request
						</Button>
					</Box> */}
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}

/* TODO: Fix the sizing of the upload buttons and show the name */
/* TODO: Add queue lock status input */
/* TODO: Uploading the roster stuff in another page */
