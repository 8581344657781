export default {
	body: {
		marginLeft: "10%",
		marginRight: "10%"
	},
	center: {
		textAlign: "center",
		marginBottom: "2em"
	},
	text: {
		marginTop: "1em",
		marginBottom: "1em"
	},
	button: {
		marginTop: "10px"
	},
	title: {
		color: "white",
		fontWeight: "bold",
		fontSize: "3em",
		marginTop: "8px",
		marginBottom: "20px"
	}
};
