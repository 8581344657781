import CallMadeIcon from "@mui/icons-material/CallMade";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LiveHelpRoundedIcon from "@mui/icons-material/LiveHelpRounded";
import UndoIcon from "@mui/icons-material/Undo";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import Linkify from "react-linkify";

import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/MainWidget/TicketStyle";
import OurTheme from "../../style/Theme";
import { TAG_LABELS } from "./consts";
import TicketForm from "./TicketForm";

/*
 * Template for ticket and form to create a ticket
 * (Need post request to send checkbox data to database)
 */
export default function Ticket(props) {
	// Initialize a state variable as an object where keys are item identifiers and values are toggle states
	const [tagToggles, setToggles] = useState(
		TAG_LABELS.reduce((acc, tag) => {
			acc[tag] = props.tagArray.includes(tag);
			return acc;
		}, {})
	);

	// Function to toggle the state of an individual item
	const toggleTicketItem = (tProp) => {
		setToggles((prevToggles) => ({
			...prevToggles,
			[tProp]: !prevToggles[tProp]
		}));
	};

	const { theme } = OurTheme;

	const {
		state: { user }
	} = useContext(Context);
	const [accepted, setAccepted] = useState(props.status === 1);

	const [copied, setCopied] = useState("");

	const ticketKey = props.ticket_id;
	const expanded = props.expanded === ticketKey;

	const [room, setRoom] = useState(props.room);
	const [seat, setSeat] = useState(props.seat);
	const [description, setDescrip] = useState(props.description);
	const [tags, setTags] = useState("");

	useEffect(() => {
		setTags(props.tagArray.join(", "));
	}, [props.tagArray]);

	useEffect(() => {
		setDescrip(props.description);
		setRoom(props.room);
		setSeat(props.seat);
		setAccepted(props.status === 1);
	}, [props]);

	/*
	 * state for a student to hide their name from other classmates (NOT tutor/prof)
	 * value is boolean
	 * not select (False) - show name to classmates
	 * selected (True) - hide name from classmates
	 * TODO: change visibility of name and role based on this
	 *      if anonymous is True:
	 *          student (own ticket) or tutor/prof: name visible
	 *          student (classmate's ticket): name not visible
	 *      if anonymous is False:
	 *          name visible to everyone
	 */
	const [anonymous, setAnon] = useState(props.is_private);

	/*
	 * toggles for all boolean values
	 * (selecting and delecting buttons)
	 */
	const toggleAnon = () => {
		setAnon(!anonymous);
	};

	const [open, setOpen] = React.useState(false);

	const handleExpanded = () => {
		props.setExpanded(expanded ? 0 : ticketKey);
		window.dispatchEvent(new CustomEvent("ticketEventOpened", { detail: ticketKey }));
	};

	/*
	 * re-open ticket form when want to edit ticket
	 */
	const handleOpenToEdit = () => {
		setOpen(true);
	};

	/*
	 * reset values when ticket form is closed
	 */
	const handleClose = () => {
		setOpen(false);
	};

	/*
	 * change values when ticket form is submitted
	 * tagToggles is an object with the keys being the tags and whether they are checked or not
	 */
	const handleSubmit = (room, seat, description, tagToggles) => {
		setRoom(room);
		setSeat(seat);
		setDescrip(description);

		const tag_list = Object.keys(tagToggles).filter((key) => tagToggles[key]);
		setTags(tag_list.join(", "));

		server.studentUpdateTicket(
			props.ticket_id,
			0, // 0 = do not cancel ticket
			props.name,
			description,
			room,
			seat,
			0, // Help type: Question
			anonymous,
			tag_list
		);

		handleClose();
	};

	/*
	 * accept ticket: change accepted status of ticket to True
	 */
	const handleAccept = () => {
		setAccepted(true);
		// change status in backend
		server.graderUpdateTicket(props.ticket_id, "ACCEPTED");
	};

	/*
	 * cancel ticket: remove ticket from the queue
	 */
	const handleCancel = () => {
		// double check that want to cancel the ticket? (cannot be undone)

		const confirmed = window.confirm("Are you sure you want to CANCEL this ticket?");
		if (confirmed) {
			// remove ticket from backend queue
			// refresh queue to show removed ticket
			server.graderUpdateTicket(props.ticket_id, "CANCELED");
			// allow user to submit another ticket for this class?
		}
	};

	/*
	 * resolve ticket: remove ticket from queue
	 */
	const handleResolve = () => {
		// remove ticket from backend queue
		// refresh queue to show removed ticket
		// allow user to submit another ticket for this class?
		// ticket tutor feedback form?
		const confirmed = window.confirm("Are you sure you want to RESOLVE this? ");
		if (confirmed) {
			server.graderUpdateTicket(props.ticket_id, "RESOLVED");
		}
	};

	/*
	 * return ticket to the queue
	 */
	const handleReturn = () => {
		setAccepted(false);
		server.graderUpdateTicket(props.ticket_id, "DEFERRED");
	};

	/*
	 * button options that appear on ticket depending on role
	 * student (own ticket):
	 *      accepted: cancel, comment
	 *      not accepted: edit, cancel, comment
	 * student (other classmates' tickets):
	 *      view only
	 * tutor or prof(admin):
	 *      accepted: resolved, return to queue, cancel, comment
	 *      not accepted: accept, cancel, comment
	 *
	 */
	const buttonActionsForUserRole = () => {
		// fix spacing of all buttons
		const editButton = (
			<Button
				variant="contained"
				startIcon={<EditIcon />}
				sx={styles.editButton}
				key={1}
				onClick={handleOpenToEdit}
			>
				Edit
			</Button>
		);
		const acceptButton = (
			<Button
				variant="contained"
				startIcon={<CallMadeIcon />}
				sx={styles.acceptButton}
				key={2}
				onClick={() => {
					handleAccept();
				}}
			>
				Accept
			</Button>
		);
		const cancelButton = (
			<Button
				variant="contained"
				startIcon={<CancelIcon />}
				sx={styles.cancelButton}
				key={3}
				onClick={() => {
					handleCancel();
				}}
			>
				Cancel
			</Button>
		);
		const resolveButton = (
			<Button
				variant="contained"
				startIcon={<DoneIcon />}
				sx={styles.resolveButton}
				key={5}
				onClick={() => {
					handleResolve();
				}}
			>
				Resolve
			</Button>
		);
		const returnButton = (
			<Button
				variant="contained"
				startIcon={<UndoIcon />}
				sx={styles.returnButton}
				key={6}
				onClick={() => {
					handleReturn();
				}}
			>
				Return
			</Button>
		);
		if (props.role === "STUDENT" && props.student_id === user.id) {
			// ticket need to have user.id attribute
			if (accepted) {
				return [cancelButton];
			}
			return [editButton, cancelButton];
		}
		if (props.role !== "STUDENT") {
			if (accepted) {
				return [resolveButton, returnButton, cancelButton];
			}
			return [acceptButton, cancelButton];
		}
		return "";
	};
	const ticketInfo = {
		room,
		seat,
		description,
		anonymous,
		tagToggles
	};

	/*
	 * format of ticket and edit form
	 */
	return (
		<Box sx={styles.container}>
			{/* submitted ticket */}
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{/* ticket summary */}

					<Accordion
						square={false}
						sx={styles.root}
						expanded={expanded}
						onChange={() => handleExpanded()}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
							sx={accepted ? styles.acceptedTicket : styles.unacceptedTicket}
						>
							<LiveHelpRoundedIcon sx={styles.ticketIcon} />
							<Box sx={styles.table}>
								<Box sx={styles.tableRow}>
									{/* name of student creating ticket */}
									<Box sx={styles.tableLeft}>
										<Typography
											sx={styles.title}
											align="left"
										>
											{" "}
											{props.is_private &&
											props.role === "STUDENT" &&
											props.student_id !== user.id
												? "Anonymous"
												: props.name}
											{accepted && (
												<>
													{" - "}
													{props.tutor_name}
												</>
											)}
										</Typography>
									</Box>
									{/* selected room and seat */}
									<Box sx={styles.tableRight}>
										<Typography
											sx={styles.location}
											align="right"
										>
											{room}-{seat}
										</Typography>
									</Box>
								</Box>
							</Box>
						</AccordionSummary>

						{/* ticket details (appear when expanded) */}
						<AccordionDetails sx={styles.body}>
							{/* details of date/time/location/description */}
							<Typography>
								<b>Created at: </b>
								{props.date} {props.time}
							</Typography>

							<Typography
								sx={styles.copyLink}
								onClick={() => {
									navigator.clipboard.writeText(seat);
									setCopied("copied");
									setTimeout(() => {
										setCopied("");
									}, 2500);
								}}
							>
								<b>Location: </b>
								{room} - {room === "Remote" ? " " : "Seat "}
								{seat}
								<i style={{ color: "#2A667B" }}>{copied}</i>
							</Typography>
							<Typography>
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a
											target="blank"
											href={decoratedHref}
											key={key}
										>
											{decoratedText}
										</a>
									)}
								>
									<b>Description: </b>
									{description || "No description"}
								</Linkify>
							</Typography>
							{/* ticket tags if it is a question (not a checkoff) */}
							<Typography>
								<b>Tags: </b>
								{tags || "No tags"}
							</Typography>
							{/* 
                               ticket buttons available to the user (student or tutor/prof)
                               for current status of ticket (accepted or not accepted)
                            */}
							<Box sx={styles.buttonDiv}>{buttonActionsForUserRole()}</Box>
						</AccordionDetails>
					</Accordion>
				</ThemeProvider>
			</StyledEngineProvider>

			{/* form to edit a ticket */}
			<TicketForm
				ticketInfo={ticketInfo}
				open={open}
				title="Edit Ticket"
				toggleTicketItem={toggleTicketItem}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				toggleAnon={toggleAnon}
			/>
		</Box>
	);
}

Ticket.propTypes = {
	status: PropTypes.number,
	room: PropTypes.string,
	seat: PropTypes.string,
	description: PropTypes.string,
	tagArray: PropTypes.array,
	is_private: PropTypes.bool,

	ticket_id: PropTypes.number,
	student_id: PropTypes.number,
	name: PropTypes.string,
	tutor_name: PropTypes.string,
	role: PropTypes.string,
	date: PropTypes.string,
	time: PropTypes.string,

	expanded: PropTypes.number,
	setExpanded: PropTypes.func
};
