import {
	Autocomplete,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

import styles from "../../style/MainWidget/TicketStyle";
import OurTheme from "../../style/Theme";
import { ROOM_OPTIONS, SEAT_OPTIONS } from "./consts";

/**
 *
 *
 */

export default function TicketForm(props) {
	const { inverseTheme } = OurTheme;

	const { room, seat, description, tagToggles, anonymous } = props.ticketInfo;

	// helper function to check if it's a valid zoom link (compatible with subdomain such as ucsd.zoom)
	const zoomLinkRegex = /^https:\/\/(?:\w+\.)?zoom\.us\/(?:j\/)?(?:my\/)?([a-z0-9.]{5,40})$/;

	function isZoomLinkValid(link) {
		return zoomLinkRegex.test(link);
	}

	// temporary values that are edited when you change
	const [tempRoom, setTempRoom] = useState(room);
	const [tempSeat, setTempSeat] = useState(seat);
	const [tempDescription, setTempDescrip] = useState(description);

	const { open, title } = props;

	const { handleClose, handleSubmit, toggleTicketItem, toggleAnon } = props;

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle
						id="form-dialog-title"
						sx={styles.dialog}
					>
						{title}
					</DialogTitle>
					<DialogContent sx={styles.dialog}>
						{/* ticket issues (editable by toggle) */}
						<div>
							{Object.entries(tagToggles).map(([key, value]) => (
								<FormControlLabel
									sx={styles.check}
									control={
										<Checkbox
											sx={styles.check}
											onClick={() => toggleTicketItem(key)}
											color="primary"
											checked={value}
											name={key}
										/>
									}
									key={key}
									label={key}
								/>
							))}
						</div>
						{/* 
                           options for room selection (editable by dropdown)
                        */}
						<FormControl
							margin="normal"
							fullWidth
						>
							<InputLabel id="room">Room</InputLabel>
							<Select
								labelId="room"
								label="Room"
								value={tempRoom}
								onChange={(e) => setTempRoom(e.target.value)}
							>
								{ROOM_OPTIONS.map((element) => (
									<MenuItem
										value={element}
										key={element}
									>
										{element}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{/* 
                            options for seat selection (editable by dropdown) 
                        */}
						<FormControl
							margin="normal"
							required
							fullWidth
						>
							<Autocomplete
								id="seat"
								freeSolo
								ListboxProps={{
									style: {
										maxHeight: "200px",
										overflow: "auto"
									} // Set the maximum height and enable scroll
								}}
								options={SEAT_OPTIONS}
								value={tempSeat}
								renderInput={(params) => {
									const isValid = isZoomLinkValid(params.inputProps.value);
									const isRemote = tempRoom === "Remote";
									return (
										<TextField
											{...params}
											label="Seat/Zoom Link"
											error={!isValid && isRemote}
											helperText={
												!isValid && isRemote
													? "Please enter a valid Zoom link if you are remote!"
													: ""
											}
										/>
									);
								}}
								onChange={(event, newValue) => {
									setTempSeat(newValue);
								}}
								onInputChange={(event, newInputValue) => {
									// Check if the input value is not from a selection
									if (event && event.type === "change") {
										setTempSeat(newInputValue);
									}
								}}
							/>
						</FormControl>
						{/* decription field (editable) */}
						<TextField
							sx={styles.text}
							onChange={(e) => setTempDescrip(e.target.value)}
							variant="outlined"
							id="name"
							defaultValue={description}
							label="Description"
							margin="normal"
							multiline
							rows="6"
							fullWidth
						/>
						{/* Anonymous checkbox (editable by toggle) */}
						<FormControlLabel
							sx={styles.check}
							label="Hide from Classmates?"
							control={
								<Checkbox
									sx={styles.check}
									onClick={toggleAnon}
									color="primary"
									checked={anonymous}
									name="anonymous"
								/>
							}
						/>
					</DialogContent>

					{/* submit ticket */}
					<DialogActions>
						<Button
							onClick={() => handleSubmit(tempRoom, tempSeat, tempDescription, tagToggles)}
							color="primary"
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

TicketForm.propTypes = {
	ticketInfo: PropTypes.object.isRequired,
	open: PropTypes.bool,
	title: PropTypes.string,
	toggleTicketItem: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleClose: PropTypes.func,
	toggleAnon: PropTypes.func
};
