export default {
	announcement: {
		cursor: "default"
	},
	cardContent: {
		padding: "1em!important"
	},
	messageText: {
		color: "#2a667b",
		fontWeight: "bold",
		marginBottom: "0.5em"
	},
	messageSender: {
		color: "#2a667b",
		marginBottom: "0.5em"
	},
	messageSubject: {
		color: "#2a667b"
	},
	messageyou: {
		margin: 1,
		backgroundColor: "#baeaff",
		borderRadius: "15px 15px 15px"
	}
};
