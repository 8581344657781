export default {
	tableCellStatus: (theme) => ({
		[theme.breakpoints.down(850)]: {
			display: "table-row"
		},
		[theme.breakpoints.up(851)]: {
			display: "table-cell"
		}
	}),
	tableCellTutor: (theme) => ({
		[theme.breakpoints.down(850)]: {
			display: "table-row",
			textAlign: "left"
		},
		[theme.breakpoints.up(851)]: {
			display: "table-cell",
			textAlign: "right"
		}
	}),
	tableRow: {
		display: "table-row",
		width: "100%"
	},
	table: {
		display: "table",
		width: "100%"
	},
	bold: {
		fontWeight: "bold"
	},
	courseInfo: {
		display: "flex",
		flexDirection: "column",
		color: "#2a667b"
	},
	instructorInfo: {
		display: "flex",
		flexDirection: "column",
		color: "#2a667b",
		textAlign: "right"
	},
	row: {
		display: "flex",
		flexDirection: "row"
	},
	actionBtn: {
		display: "flex",
		flexDirection: "row",
		color: "#2a667b",
		textAlign: "right",
		justifyContent: "right"
	}
};
