import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { formatISO } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Context } from "../../../context/Context";
import server from "../../../server";
import styles from "../../../style/SmallWidget/AnnouncementWidgetStyle";
import OurTheme from "../../../style/Theme";
import Announcement from "./Announcement";

export default function Announcements() {
	const { course_id } = useParams();
	const {
		state: { user, role }
	} = useContext(Context);

	const [open, setOpen] = useState(false);
	const theme = OurTheme.inverseTheme;

	const [announcementList, setAnnouncementList] = useState([]);
	const [newAnnInput, setNewAnnInput] = useState("");
	const [newAnnTitle, setNewAnnTitle] = useState("");
	const fullname = `${user.fname} ${user.lname}`;

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleSubmit = () => {
		if (!newAnnInput || !newAnnTitle) {
			alert("Please fill out the required inputs!");
			return;
		}

		// prepend message to the list of Announcements
		server.addAnnouncement(user.id, parseInt(course_id), newAnnTitle, newAnnInput);
		setAnnouncementList((announcementList) => [
			{
				last_edited_at: formatISO(new Date()),
				body: newAnnInput,
				subject: newAnnTitle,
				owner_name: fullname
			},
			...announcementList
		]);
		generateAnnouncements(); // regenerate
		handleClose();
	};

	const handleArchive = (post_id) => {
		// Remove post in database
		server.archiveAnnouncement(parseInt(course_id), post_id);

		// Remove post from frontend
		setAnnouncementList((list) => list.filter((currentPost) => currentPost.id !== post_id));
	};

	const generateAnnouncements = () => (
		<Box
			id="announcementList"
			sx={styles.announcementList}
		>
			{announcementList.map((announcement, index) => (
				<Announcement
					key={index}
					owner={announcement.owner_name}
					subject={announcement.subject}
					body={announcement.body}
					last_edited_at={announcement.last_edited_at}
					handleArchive={() => handleArchive(announcement.id)}
				/>
			))}
		</Box>
	);

	useEffect(() => {
		server
			.getAnnouncements(course_id)
			.then((response) => {
				setAnnouncementList(response.data.result);
			})
			.catch((err) => console.error(err));
	}, [course_id]);

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle
							sx={styles.form}
							id="form-dialog-title"
						>
							Add a new announcement
						</DialogTitle>
						<DialogContent>
							<TextField
								sx={styles.text}
								variant="outlined"
								id="name"
								label="Announcement Title"
								fullWidth
								onChange={(e) => setNewAnnTitle(e.target.value)}
								placeholder="PA3 deadline EXTENDED"
							/>
						</DialogContent>
						<DialogContent>
							<TextField
								sx={styles.text}
								variant="outlined"
								id="name"
								label="Announcement Description"
								multiline
								fullWidth
								onChange={(e) => setNewAnnInput(e.target.value)}
								placeholder="PA3 deadline has been EXTENDED until February 29th. Also please fill out the ticket descriptions or your ticket will be skipped by the tutors and you will be ejecto seato, cuz. "
							/>
						</DialogContent>
						<DialogActions>
							<Button
								sx={styles.form}
								onClick={handleSubmit}
								color="primary"
							>
								Send
							</Button>
						</DialogActions>
					</Dialog>
					{role !== "STUDENT" && (
						<Box textAlign="center">
							<Button
								sx={styles.newAnnouncement}
								variant="text"
								startIcon={<AddCircleIcon />}
								onClick={handleOpen}
							>
								{" "}
								New Announcement{" "}
							</Button>
						</Box>
					)}

					{announcementList.length > 0 ? (
						generateAnnouncements()
					) : (
						<Typography
							variant="body1"
							align="center"
						>
							No announcements
						</Typography>
					)}
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
