import { Link } from "@mui/material";
import React from "react";

export default function VisitorNavbar() {
	return (
		<div style={{ marginTop: "1em", textAlign: "center" }}>
			<Link href="/">&lt; Return to login page</Link>
		</div>
	);
}
