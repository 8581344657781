import OurTheme from "../Theme";

const theme1 = OurTheme.theme;
export default {
	title: {
		marginTop: "0.5em",
		color: "white",
		fontSize: "3em",
		fontWeight: "bold"
	},
	root: {
		flexGrow: 1,
		backgroundColor: "#2A667B",
		paddingLeft: "48px",
		paddingRight: "48px"
	},
	tab: {
		// width: "10px",
		color: "white"
	},
	header: {
		color: "white",
		fontWeight: "bold",
		marginBottom: "0.5em"
	},
	info: {
		color: "white"
	},
	div: {
		float: "left"
	},
	div2: {
		paddingLeft: "50px",
		float: "left"
	},
	paper: {
		color: theme1.palette.text.primary,
		background: "#2A667B",
		fontSize: 20,
		// fontWeight: 'bold',
		fontWeight: 100
	},
	paper2: {
		color: theme1.palette.text.primary,
		background: "#2A667B",
		fontSize: 20
	},
	switchLabel: {
		fontSize: 20,
		color: "white",
		fontWeight: 100
	},
	textField: {
		width: "100%",
		"& label + div": {
			backgroundColor: "#4e6f7b",
			borderRadius: "5px"
		},
		"& label": {
			color: "white",
			fontWeight: "900"
		},
		"& label ~ p": {
			color: "#cce2e3"
		},
		"& .MuiOutlinedInput-root fieldset": {
			borderColor: "grey"
		}
	}
};
