import PersonIcon from "@mui/icons-material/Person";
import { Box, Grid, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import OurTheme from "../../../style/Theme";

export default function TutorStatus(props) {
	TutorStatus.propTypes = {
		locations: PropTypes.arrayOf(PropTypes.string),
		person: PropTypes.string
	};

	const { theme } = OurTheme;

	const formatLocations = () => (
		<div id="locations">
			{props.locations.map((location) => (
				<Typography
					variant="h6"
					style={{ fontSize: "14px" }}
					key={location}
				>
					{location}
				</Typography>
			))}
		</div>
	);

	return (
		<Box style={{ display: "flex", justifyContent: "center" }}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Grid
						container
						style={{
							alignItems: "center",
							backgroundColor: "#1a404d",
							margin: "2px",
							padding: "8px",
							borderRadius: "20px",
							maxWidth: "250px"
						}}
					>
						<Grid
							item
							xs={2}
						>
							<PersonIcon fontSize="small" />
						</Grid>
						<Grid
							item
							xs={7}
						>
							<Typography
								variant="h6"
								style={{ fontSize: "14px" }}
							>
								{props.person}
							</Typography>
						</Grid>
						<Grid
							item
							xs={3}
						>
							{formatLocations()}
						</Grid>
					</Grid>
				</ThemeProvider>
			</StyledEngineProvider>
		</Box>
	);
}
