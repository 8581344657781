import { Box, Card, CardContent, Typography } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import Linkify from "react-linkify";

import styles from "../../../style/SmallWidget/TicketEventStyle";

/**
 * @component/TicketEvent
 * Renders a card for the event (comment, cancellation, etc.) for a specific ticket
 *
 */
export default function TicketEvent(props) {
	// being my message would orient it to the right of the screen, other message would be to the left
	const ticketEventEnums = {
		CREATED: styles.created,
		ACCEPTED: styles.accepted,
		RESOLVED: styles.resolved,
		UPDATED: styles.updated,
		DEFERRED: styles.deferred,
		CANCELLED: styles.canceled,
		COMMENTED: styles.commented
	};

	const { ecId } = props;

	const tStamp = format(props.timestamp, "h:mm aaaa");

	// determines who the event is coming from to style on either the left or right side
	const eventType = ticketEventEnums[props.eventType];
	const sender = ecId === props.userEcId ? styles.commentme : styles.commentyou;

	return (
		<Box sx={sender}>
			<Card
				variant="outlined"
				sx={styles.layout}
			>
				<CardContent sx={eventType}>
					<Box>
						<Box
							display="flex"
							flexDirection="row"
						>
							<Box flexGrow={1}>
								<Typography
									variant="body1"
									sx={styles.messageTitle}
								>
									{`${props.firstName} ${props.lastName}`}
								</Typography>
							</Box>
							<Box>
								<Typography
									variant="body1"
									sx={styles.messageSubheader}
								>
									{tStamp}
								</Typography>
							</Box>
						</Box>
						<Typography
							variant="subtitle1"
							sx={styles.messageSubheader}
						>
							{props.eventType.toLowerCase()}
						</Typography>
					</Box>

					{/* <Typography variant="body2" sx={styles.messageSender}>
                            {props.timestamp}
                        </Typography>

                        <Typography variant="body2" sx={styles.messageSender}>
                            {props.eventType //want the eventType to be at the edge of the card in addition to changing the class of the commment.
                            }
                        </Typography> */}
					<br />
					<Typography
						variant="body2"
						sx={styles.messageText}
					>
						<Linkify
							componentDecorator={(decoratedHref, decoratedText, key) => (
								<a
									target="blank"
									href={decoratedHref}
									key={key}
								>
									{decoratedText}
								</a>
							)}
						>
							{props.message}
						</Linkify>
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
}

TicketEvent.propTypes = {
	eventType: PropTypes.string, // determines the type of event on the comment
	message: PropTypes.string,
	ecId: PropTypes.number,
	userEcId: PropTypes.number,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	timestamp: PropTypes.instanceOf(Date)
};
