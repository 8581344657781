import { Box, Card, CardContent, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Context } from "../../../context/Context";
import styles from "../../../style/SmallWidget/AnnouncementStyle";
import OurTheme from "../../../style/Theme";

export default function QueueStats(props) {
	QueueStats.propTypes = {
		ticketsResolved: PropTypes.number,
		prevTicketTime: PropTypes.number,
		avgResTime: PropTypes.number,
		received: PropTypes.bool
	};

	const {
		state: { role }
	} = useContext(Context);

	const { theme } = OurTheme;
	const messageclass = props.received ? styles.messageyou : styles.messageme;
	return (
		<Box sx={styles.announcement}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{role !== "STUDENT" && (
						<div>
							<Typography
								variant="h6"
								align="center"
								sx={{ margin: "5px", marginTop: "25px" }}
							>
								YOUR QUEUE STATS
							</Typography>
							<Card
								variant="outlined"
								sx={messageclass}
							>
								<CardContent>
									<div>
										<Typography
											variant="body2"
											sx={styles.messageText}
										>
											Tickets Resolved Today: {props.ticketsResolved} tickets
										</Typography>
										<Typography
											variant="body2"
											sx={styles.messageText}
										>
											Previous Ticket Time: {props.prevTicketTime} min.
										</Typography>
										<Typography
											variant="body2"
											sx={styles.messageText}
										>
											Avg. Resolution Time Today: {props.avgResTime} min.
										</Typography>
									</div>
								</CardContent>
							</Card>
						</div>
					)}
				</ThemeProvider>
			</StyledEngineProvider>
		</Box>
	);
}
