/**
 * This file declares the basic axios stuff for use by everyone else
 * as a global import.
 * @author npcompletenate
 */
import axios from "axios";

// In production, we proxy the api through /api on the same port
export const serverURL = process.env.NODE_ENV === "production" ? "/" : "http://localhost:1337/";

// create the axios object used to interact with the API
const api = axios.create({
	baseURL: serverURL
});

// Redirect the user to login if a server call indicates their session cookie has expired
api.interceptors.response.use(
	(res) => res,
	(error) => {
		if (!error.response) {
			return Promise.reject(Error("500 Internal Server Error - contact queues@ucsd.edu for help."));
		}

		const { reason } = error.response.data;
		if (reason && reason.includes("Not logged in!")) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = "/login";
		}

		return Promise.reject(error);
	}
);

export default api;
