// TODO: Update material-icon to mui
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Box, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/QueueStyle";
import { TAG_LABELS } from "./consts";
import Ticket from "./Ticket";
import TicketFeedbackForm from "./TicketFeedbackForm";
import TicketForm from "./TicketForm";

export default function Queue(props) {
	const isMounted = React.useRef(true);

	const {
		state: { user, course }
	} = useContext(Context);
	const [role, setRole] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const [tagToggles, setToggles] = useState(
		TAG_LABELS.reduce((acc, tag) => {
			acc[tag] = false;
			return acc;
		}, {})
	);

	// Function to toggle the state of an individual item
	const toggleTicketItem = (tProp) => {
		setToggles((prevToggles) => ({
			...prevToggles,
			[tProp]: !prevToggles[tProp]
		}));
	};

	// Fields for Ticket
	const [open, setOpen] = useState(false); // ticket modal
	const [description, setDescrip] = useState("");
	const [seat, setSeat] = useState("");
	const [room, setRoom] = useState("Remote");

	// TODO: storing id of open ticket and if we should display the edit form
	const [openedTicket, setOpenedTicket] = useState(0);

	// Hide from classmates
	const [anonymous, setAnon] = useState(false);

	const [resolvedTicket, setResolvedTicket] = useState();

	// FOR FEEDBACK
	const [openFeedback, setOpenFeedback] = useState(false);

	const ticketInfo = {
		room,
		seat,
		description,
		anonymous,
		tagToggles
	};

	// Boolean Togglers
	const toggleAnon = () => {
		setAnon(!anonymous);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setToggles(
			TAG_LABELS.reduce((acc, tag) => {
				acc[tag] = false;
				return acc;
			}, {})
		);

		setDescrip("");
		setRoom("Remote");
		setSeat("");
		setOpen(false);
	};

	const handleSubmit = (room, seat, description, tagToggles) => {
		// saves the information user selected

		const tags = Object.keys(tagToggles).filter((key) => tagToggles[key]);

		server.addTicket({
			queue_id: props.course_id,
			student_id: user.id,
			title: `${user.fname} ${user.lname}`,
			description,
			room,
			workstation: seat,
			is_private: anonymous,
			help_type: 0,
			tag_list: tags
		});
		handleClose();
	};

	// Existing Queue for the Class
	const [ticketList, setTicketList] = useState([]);

	// Event Source connection – add all event listeners here!
	useEffect(() => {
		const sse = new EventSource(`${server.baseURL}api/ticket/stream?channel=queue_id.${props.course_id}`);

		// When a ticket has been added, add it to the bottom of the queue
		sse.addEventListener("addTicket", (event) => {
			const data = JSON.parse(event.data);
			const ticket = data.message.ticket_info;
			const events = data.message.ticket_events;

			// Do not update anything if ticket is private to student
			if (ticket.is_private && course.role === "STUDENT" && ticket.student_id !== user.id) {
				return;
			}

			const dateTime = new Date(ticket.created_at);

			ticket.name = ticket.title;
			ticket.date = format(dateTime, "eee MMM dd yyyy");
			ticket.time = format(dateTime, "hh:mm aa");

			if (isMounted.current)
				// Prevents "Can"t perform a React state update on an unmounted component." error.
				setTicketList((prevTicketList) => prevTicketList.concat(ticket));

			// We also need to let the smallWidget know that a new ticket has been added!
			window.dispatchEvent(
				new CustomEvent("ticketUpdated", {
					detail: { id: ticket.ticket_id, events }
				})
			);
		});

		// Student edits ticket or tutor interacts w/ ticket
		sse.addEventListener("ticketUpdate", (event) => {
			const data = JSON.parse(event.data);

			const ticket = data.message.ticket_info;
			const events = data.message.ticket_events;
			const stat = ticket.status;

			console.debug("Ticket update:", ticket);
			// Do not update anything if ticket is private to student
			if (ticket.is_private && course.role === "STUDENT" && ticket.student_id !== user.id) {
				return;
			}

			// use stat for if statement
			// do different action depending on new status
			// ex: CANCELED -> remove ticket from ticket list
			if (stat !== 2 && stat !== 3) {
				const latestEvent = events[0];
				// pending, accepted
				// update status of ticket
				ticket.name = ticket.title;
				const dateTime = new Date(ticket.created_at);

				ticket.name = ticket.title;
				ticket.date = format(dateTime, "eee MMM dd yyyy");
				ticket.time = format(dateTime, "hh:mm aa");

				// tutor accepts ticket and is assigned to it
				if (isMounted.current) {
					setTicketList((prevTicketList) => {
						const newTicketList = [...prevTicketList];
						const index = prevTicketList.findIndex(
							(currentTicket) => currentTicket.ticket_id === ticket.ticket_id
						);
						if (index !== -1) {
							ticket.grader_name = newTicketList[index].grader_name;
							if (latestEvent.event_type === "ACCEPTED") {
								ticket.grader_name = `${latestEvent.first_name} ${latestEvent.last_name}`;
							}
							newTicketList[index] = ticket;
						}

						return newTicketList;
					});

					window.dispatchEvent(
						new CustomEvent("ticketUpdated", {
							detail: { id: ticket.ticket_id, events }
						})
					);
				}
			} else {
				// canceled, resolved
				// remove ticket from list
				// send along the grader_id for logic with updating the queue stats
				window.dispatchEvent(
					new CustomEvent("ticketUpdated", {
						detail: {
							id: ticket.ticket_id,
							events,
							grader: ticket.ec_grader_id
						}
					})
				);

				setResolvedTicket(ticket);
				if (stat === 2 && user.id === ticket.ec_student_id) {
					setOpenFeedback(true);
				}

				if (isMounted.current) {
					setTicketList((ticketList) => {
						const myTicketIndex = ticketList.findIndex((ticket) => ticket.student_id === user.id);
						const newTicketList = ticketList.filter(
							(currentTicket) => currentTicket.ticket_id !== ticket.ticket_id
						);
						const myNewTicketIndex = newTicketList.findIndex((ticket) => ticket.student_id === user.id);

						// My ticket has moved up in the top 2 - notify!
						if (myTicketIndex !== myNewTicketIndex && myNewTicketIndex >= 0 && myNewTicketIndex < 2) {
							const message = "Your ticket has moved closer to the top!";

							if (Notification.permission === "granted") {
								new Notification("Ticket Update", {
									body: message,
									icon: "/favicon.ico"
								});
							} else if (Notification.permission !== "denied") {
								Notification.requestPermission().then((permission) => {
									if (permission === "granted") {
										new Notification("Ticket Update", {
											body: message,
											icon: "/favicon.ico"
										});
									}
								});
							}
						}

						return newTicketList;
					});
				}
			}
		});

		return () => {
			// Cleanup upon unmounting component
			isMounted.current = false;
			sse.close();
		};
	}, [user.id, props.course_id]); // user.id used in update logic

	useEffect(() => {
		server.getUserRoleInCourse(user.id, props.course_id).then((response) => {
			setRole(response.data.result.enrolled_course_info.role);
		});

		// Get current tickets - Pending & Accepted
		server.findAllTickets(props.course_id).then((results) => {
			const currentTickets = results.data.result;
			currentTickets.forEach((ticket) => {
				const dateTime = new Date(ticket.created_at);

				ticket.name = ticket.title;
				ticket.date = format(dateTime, "eee MMM dd yyyy");
				ticket.time = format(dateTime, "hh:mm aa");
			});

			// Put tickets in order of most recent first
			currentTickets.reverse();

			console.debug(currentTickets);
			setTicketList(currentTickets);
			setIsLoading(false);
		});
	}, [props.course_id, user.id]); // does user.id need to be in the depency array?

	return (
		<div>
			<Box sx={styles.center}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center"
					}}
				>
					{role === "STUDENT" && (
						<Box>
							{props.queueData.status === "OPEN" ? (
								<Button
									onClick={() => handleClickOpen()}
									sx={styles.icon}
									variant="outlined"
								>
									<AddCircleIcon />
									<Typography
										variant="h6"
										component="h6"
										style={{ paddingLeft: "0.25rem" }}
									>
										Add Ticket
									</Typography>
								</Button>
							) : (
								<>
									<Button
										disabled
										sx={styles.icon}
										variant="outlined"
									>
										<AddCircleIcon />
										<Typography
											variant="h6"
											component="h6"
											style={{ paddingLeft: "0.25rem" }}
										>
											Add Ticket
										</Typography>
									</Button>
									<Typography
										variant="body2"
										style={{
											color: "#FF7A7A",
											fontWeight: "bold"
										}}
									>
										Please check back when tutors are on duty.
									</Typography>
								</>
							)}
						</Box>
					)}

					{ticketList.length === 0 && (
						<Typography style={{ textAlign: "center", marginTop: "5em" }}>
							<QuestionAnswerIcon fontSize="large" />
							<br />
							Tickets will appear here when created!
							<br />
							{isLoading && "Currently loading tickets..."}
						</Typography>
					)}
				</Box>
			</Box>
			<TicketFeedbackForm
				openFeedback={openFeedback}
				setOpenFeedback={setOpenFeedback}
				resolvedTicket={resolvedTicket}
			/>

			<TicketForm
				ticketInfo={ticketInfo}
				open={open}
				title="Add Ticket"
				toggleTicketItem={toggleTicketItem}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				toggleAnon={toggleAnon}
			/>

			<Box
				id="ticketList"
				sx={styles.ticketList}
			>
				{ticketList.map((ticket) => (
					<Ticket
						key={ticket.ticket_id}
						ticket_id={ticket.ticket_id}
						name={ticket.name}
						tutor_name={ticket.grader_name}
						seat={ticket.workstation}
						room={ticket.room}
						description={ticket.description}
						time={ticket.time}
						date={ticket.date}
						tagArray={ticket.tag_list}
						is_private={ticket.is_private}
						status={ticket.status}
						student_id={ticket.student_id}
						role={role}
						expanded={openedTicket}
						setExpanded={setOpenedTicket}
					/>
				))}
			</Box>
		</div>
	);
}

Queue.propTypes = {
	course_id: PropTypes.number,
	queueData: PropTypes.object
};
