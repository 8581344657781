export default {
	root: {
		flexGrow: 1
	},
	appBar: {
		background: "transparent",
		boxShadow: "none",
		color: "#ffffff"
	},
	appTable: {
		background: "transparent",
		boxShadow: "none",
		color: "#ffffff",
		display: "table",
		paddingLeft: "15px",
		paddingRight: "15px",
		marginBottom: "2em",
		width: "100%"
	},
	pages: {
		fontSize: 18,
		marginRight: 2
	},
	appRow: {
		display: "table-row"
	},
	home: {
		fontSize: 25,
		fontWeight: "bold",
		height: "45px",
		marginRight: "0.5em"
	},
	exitCell: {
		display: "table-cell",
		verticalAlign: "middle",
		textAlign: "right",
		width: "15px"
	},
	homeCell: {
		display: "table-cell",
		width: "150px",
		verticalAlign: {
			xs: "middle",
			md: "bottom"
		},
		alignItems: "center"
	},
	leftlinks: {
		display: "table-cell",
		verticalAlign: "middle"
		// paddingLeft: "15px"
	},
	feedbackLink: {
		display: "table-cell",
		textAlign: "right",
		verticalAlign: "middle"
	},
	settingsLink: {
		display: "table-cell",
		width: "20px",
		verticalAlign: "middle"
	},
	link: {
		cursor: "pointer"
	},
	form: {
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black"
			}
		}
	},
	text: {
		color: "black",
		marginBottom: "15px",
		marginRight: 2
	},
	class: {
		paddingRight: "30px",
		paddingLeft: "30px"
	},
	navButtons: {
		fontSize: {
			xs: "12px",
			md: "initial"
		}
		// margin: "0 1em 0 1em"
	}
};
