import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/Settings/TicketFeedbackStyle";
import OurTheme from "../../style/Theme";

const STATUS_ENUM = ["Pending", "Accepted", "Resolved", "Canceled"];

export default function TicketFeedback() {
	const { inverseTheme } = OurTheme;

	const {
		state: { user, courseId }
	} = useContext(Context);
	const headers = [
		{ field: "submitted_at", label: "Submitted at" },
		{ field: "student", label: "Student" },
		{ field: "tutor", label: "Tutor" },
		{ field: "rating", label: "Rating" },
		{ field: "feedback", label: "Feedback" },
		{ field: "view", label: "View" }
	];

	const [ticketView, setTicketView] = useState(false);
	const [ticketInfo, setTicketInfo] = useState({});

	const handleCloseTicketView = () => {
		setTicketView(false);
	};

	const [rows, setRows] = useState([]);

	// const retrieveRows = () => {
	//     // TODO: Retrieve rows using axios based on the user role, rest stays the same
	// }
	// const formatRows = () => {
	//     // TODO: Once data is retrieved from axios, format dates, etc, correctly
	// }

	useEffect(() => {
		server.getFeedback(courseId).then((result) => {
			const { feedbacks } = result.data;
			const allRows = [];
			for (const feedback of feedbacks) {
				const row_object = {};
				row_object.submitted_at = format(new Date(feedback.submitted_date), "Pp");
				row_object.student = feedback.is_anonymous ? "Anonymous" : feedback.student_name;
				row_object.tutor = user.fname;
				row_object.rating = feedback.rating;
				row_object.feedback = feedback.feedback;
				row_object.id = feedback.ticket_id;

				allRows.push(row_object);
			}
			setRows(allRows);
		});
	}, [courseId, user.fname]);

	const viewTicket = (_id) => {
		// send request using axios to grab information about the ticket and render it
		server.getTicket(_id, user.id).then((result) => {
			const ticket = result.data.ticket_info;
			const info = ticket;
			info.student = ticket.title;
			info.created_at = format(new Date(ticket.created_at), "Pp");
			info.accepted_at = format(new Date(ticket.accepted_at), "Pp");
			info.description = ticket.description;
			info.tag_list = ticket.tag_list.join(", ");
			setTicketInfo(info);
			setTicketView(true);
		});
	};

	const renderCells = (row) =>
		headers.map((header) =>
			header.field !== "view" ? (
				<TableCell key={header.label}>{row[header.field]}</TableCell>
			) : (
				<TableCell
					sx={styles.viewTicket}
					onClick={() => viewTicket(row.id)}
					key={header.label}
				>
					View Ticket
				</TableCell>
			)
		);
	const getStripedStyle = (index) => (index % 2 ? { background: "#d1dae3" } : { background: "white" });

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<h1 style={{ color: "white" }}>Ticket Feedback</h1>
				<TableContainer component={Paper}>
					<Table
						size="small"
						aria-label="simple table"
					>
						<TableHead>
							<TableRow>
								{headers.map((header) => (
									<TableCell key={header.label}>{header.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<TableRow
									style={getStripedStyle(index)}
									key={index}
								>
									{renderCells(row)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Dialog
					open={ticketView}
					onClose={handleCloseTicketView}
					aria-labelledby="Ticket feedback"
				>
					<DialogTitle sx={styles.title}>
						<div>
							<Typography
								variant="h6"
								style={{
									color: "black",
									fontWeight: "bold"
								}}
							>
								Question by {ticketInfo.student}
							</Typography>
						</div>
					</DialogTitle>
					<DialogContent>
						<div>
							<Typography>
								<b>Status: </b>
								{STATUS_ENUM[ticketInfo.status]}
							</Typography>
							<Typography>
								<b>Created at: </b>
								{ticketInfo.created_at}
							</Typography>
							<Typography>
								<b>Accepted at: </b>
								{ticketInfo.accepted_at}
							</Typography>
							<Typography>
								<b>Tags: </b>
								{ticketInfo.tag_list}
							</Typography>
							<Typography>
								<b>Description: </b>
								{ticketInfo.description}
							</Typography>
						</div>
					</DialogContent>
					<DialogActions />
				</Dialog>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
