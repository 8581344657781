import HelpIcon from "@mui/icons-material/Help";
import {
	Button,
	Collapse,
	FormControlLabel,
	Grid,
	Link,
	styled,
	Switch,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import server from "../../server";
import styles from "../../style/CourseSettings/CourseSettingsStyle";
import OurTheme from "../../style/Theme";
import { HELPER_TIPS } from "./consts";

export default function CourseSettingsTab() {
	const { course_id } = useParams();

	const { theme } = OurTheme;

	const [calendars, setCalendars] = useState("");
	const [courseData, setCourseData] = useState({});
	const [highCapMode, setHighCapMode] = useState(false);
	const [highCapThreshold, setHighCapThreshold] = useState();
	const [highCapAnnounce, setHighCapAnnounce] = useState();
	const [highCapWarning, setHighCapWarning] = useState();
	const [cooldown, setCooldown] = useState();
	const calendarIDHelper = (
		<Link
			href="https://docs.simplecalendar.io/find-google-calendar-id/"
			target="_blank"
		>
			How do I find my calendar ID?
		</Link>
	);
	const [open, setOpen] = useState(false);
	const [alertColor, setAlertColor] = React.useState("success");
	const [message, setMessage] = React.useState("");

	const [ticketReqs, setTicketReqs] = useState("");
	const [customTags, setCustomTags] = useState("");

	const [helperTip] = useState(HELPER_TIPS);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		server.findQueueforCourse(course_id).then((result) => {
			const queueData = result.data.result;
			setHighCapMode(queueData.high_capacity_enabled);
			setHighCapThreshold(queueData.high_capacity_threshold);
			setCooldown(queueData.ticket_cooldown);
			setCalendars(queueData.calendar_links.join(","));
			setHighCapWarning(queueData.high_capacity_warning);
			setHighCapAnnounce(queueData.high_capacity_message);
		});
	}, [course_id]);

	useEffect(() => {
		server.findCourseByID(course_id).then((result) => {
			setCourseData(result.data.result);
		});
	}, [course_id]);

	const MySwitch = styled(Switch)({
		switchBase: {
			color: "#5a5a5a",
			"&$checked": {
				color: "#5ECA6F"
			},
			"&$checked + $track": {
				backgroundColor: "#5ECA6F"
			}
		},
		checked: {},
		track: {}
	});

	const handleCourseData = (event) => {
		let newValue = event.target.value;

		if (event.target.type === "checkbox") {
			newValue = event.target.checked;
		}

		setCourseData({
			...courseData,
			[event.target.name]: newValue
		});
	};

	const handleQueueUpdate = () => {
		// TODO: Process `calendar`, `ticketReqs` and send to backend

		const calendarsArray = calendars.split(",").map((calendar) => calendar.trim());
		for (const calendar of calendarsArray) {
			if (calendar && (calendar.length < 26 || !calendar.endsWith("@group.calendar.google.com"))) {
				// calendar id issue flag
				setMessage(
					"There was an issue with your calendar ID. Make sure your link ends with @group.calendar.google.com"
				);
				setAlertColor("error");
				setOpen(true);
				const alertFlag = document.querySelector("#alertFlag");
				alertFlag.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
				return;
			}
		}

		console.debug("Requesting course update:", courseData);

		const promise1 = server.updateCourseData(courseData);
		const promise2 = server.updateQueueSettings(
			Number(course_id),
			highCapMode,
			highCapAnnounce,
			highCapThreshold,
			highCapWarning,
			cooldown,
			calendarsArray
		);

		Promise.all([promise1, promise2])
			.then(() => {
				setMessage("Successful Update!");
				setAlertColor("success");
				setOpen(true);
				setTimeout(() => {
					setOpen(false);
				}, 5000);
			})
			.catch((err) => {
				const errorMessage = err.response.data ? err.response.data.reason : err.message;
				setMessage(`An error occurred: ${errorMessage}`);
				setAlertColor("error");
				setOpen(true);
			})
			.finally(() => {
				const alertFlag = document.querySelector("#alertFlag");
				alertFlag.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			});
	};

	function FormRow2(props) {
		FormRow2.propTypes = {
			checked: PropTypes.bool,
			name: PropTypes.string,
			title: PropTypes.string,
			helperTip: PropTypes.string,
			onChange: PropTypes.func
		};

		return (
			<Grid
				item
				xs={12}
				style={{ marginTop: "15px" }}
			>
				<FormControlLabel
					control={
						<MySwitch
							color="secondary"
							checked={props.checked}
							onChange={props.name in courseData ? handleCourseData : props.onChange}
							name={props.name}
						/>
					}
					label={
						<Typography
							sx={styles.switchLabel}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							{props.title}
							<Tooltip
								title={props.helperTip}
								placement="right"
							>
								<HelpIcon style={{ paddingLeft: "5px" }} />
							</Tooltip>
						</Typography>
					}
				/>
			</Grid>
		);
	}
	// TODO: remove hard coding
	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Collapse in={open}>
						<Alert
							id="alertFlag"
							onClose={() => handleClose()}
							severity={alertColor}
							variant="filled"
						>
							{message}
						</Alert>
					</Collapse>
					<Typography
						sx={{
							color: "#FF8888",
							fontWeight: "bold",
							marginBottom: "1em"
						}}
					>
						Make sure you save your changes when you&apos;re done!
					</Typography>
					<Grid
						container
						spacing={3}
						justifyContent="space-between"
					>
						<Grid
							item
							xs={6}
						>
							<Typography
								variant="h6"
								sx={styles.header}
							>
								COURSE INFO
							</Typography>
							<Grid
								container
								spacing={4}
							>
								{/* Row 1 */}
								<Grid
									item
									xs={6}
									lg={4}
								>
									<TextField
										sx={styles.textField}
										variant="outlined"
										onChange={handleCourseData}
										label="Course Name"
										name="name"
										value={courseData.name || ""}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									lg={4}
								>
									<TextField
										sx={styles.textField}
										variant="outlined"
										onChange={handleCourseData}
										label="Course Code"
										name="short_name"
										value={courseData.short_name || ""}
									/>
								</Grid>
								<Grid
									item
									xs={false}
									lg={4}
									style={{ padding: 0 }}
								/>

								{/* Row 2 */}
								<Grid
									item
									xs={6}
									lg={4}
								>
									<TextField
										sx={styles.textField}
										variant="outlined"
										onChange={handleCourseData}
										label="Quarter"
										name="quarter"
										value={courseData.quarter || ""}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									lg={4}
								>
									<TextField
										sx={styles.textField}
										variant="outlined"
										onChange={handleCourseData}
										label="Year"
										name="year"
										value={courseData.year || ""}
									/>
								</Grid>
								<Grid
									item
									xs={false}
									lg={4}
									style={{ padding: 0 }}
								/>

								{/* Row 3 */}
								<Grid
									item
									xs={7}
								>
									<TextField
										sx={styles.textField}
										variant="outlined"
										onChange={handleCourseData}
										label="Course Website"
										name="url"
										value={courseData.url || ""}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							xs={6}
						>
							<Typography
								variant="h6"
								sx={styles.header}
							>
								SETTINGS
							</Typography>
							<Grid
								container
								spacing={2}
							>
								<FormRow2
									title="Course is active"
									name="active"
									checked={courseData.active ?? true}
									helperTip={helperTip.status}
								/>
							</Grid>
							<Grid
								container
								spacing={2}
							>
								<FormRow2
									title="Queue enabled"
									name="queue_enabled"
									checked={courseData.queue_enabled ?? true}
									helperTip={helperTip.queue}
								/>
							</Grid>
							<Grid
								container
								spacing={2}
							>
								<FormRow2
									title="Lock Queue Button"
									name="lock_button"
									checked={courseData.lock_button ?? false}
									helperTip={helperTip.lock}
								/>
							</Grid>
							{/* <Grid
								container
								spacing={2}
							>
								<FormRow2
									title="Using CSE Labs"
									name="lab"
									checked={settings.lab}
									helperTip={helperTip.lab}
								/>
							</Grid> */}
						</Grid>

						{courseData.queue_enabled ? (
							<>
								<Grid
									container
									item
									xs={4}
									sx={{ paddingRight: "1em" }}
								>
									<Typography
										variant="h6"
										sx={styles.header}
									>
										QUEUE SETTINGS
									</Typography>
									<Tooltip
										title="Please make sure you have selected 'Make available to public' in your Google Calendar settings"
										placement="top"
									>
										<TextField
											sx={styles.textField}
											label="Calendar ID's (separated by commas)"
											helperText={calendarIDHelper}
											fullWidth
											placeholder="c_mrdc9j1c5o67gs@group.calendar.google.com"
											variant="outlined"
											value={calendars || ""}
											onChange={(event) => setCalendars(event.target.value.trim())}
										/>
									</Tooltip>
									<Tooltip
										title={helperTip.cooldown}
										placement="top"
									>
										<TextField
											sx={styles.textField}
											label="New Ticket Cooldown"
											fullWidth
											margin="normal"
											placeholder="10"
											variant="outlined"
											value={cooldown || ""}
											onChange={(event) => setCooldown(event.target.value)}
										/>
									</Tooltip>
									<Tooltip
										title="Separate each requirements with a comma. If left empty, the ticket requirement will be 'Description' by default"
										placement="top"
									>
										<TextField
											sx={styles.textField}
											label="Custom Ticket Description"
											fullWidth
											margin="normal"
											placeholder="What is your issue?, What have you tried to resolve this issue?, etc."
											variant="outlined"
											value={ticketReqs || ""}
											onChange={(event) => setTicketReqs(event.target.value)}
										/>
									</Tooltip>
									<FormRow2
										title="Default Tags"
										name="defaultTags"
										checked={courseData.defaultTags ?? false}
										helperTip={helperTip.defaultTags}
									/>

									<Tooltip
										title="Separate each tag with a comma"
										placement="top"
									>
										<TextField
											sx={styles.textField}
											label="Custom Tags"
											fullWidth
											margin="normal"
											placeholder="Assignment 1, Assignment 2, Review, etc."
											variant="outlined"
											value={customTags}
											onChange={(event) => setCustomTags(event.target.value)}
										/>
									</Tooltip>
									<Grid>
										<Button
											variant="contained"
											onClick={handleQueueUpdate}
											style={{ marginTop: "0.5rem" }}
										>
											Save
										</Button>
									</Grid>
								</Grid>

								<Grid
									container
									item
									xs={6}
									sx={{
										border: "1px solid white",
										borderRadius: "0.5em",
										paddingRight: "1em"
									}}
								>
									{/* <h2 variant="h5" sx={styles.header}>QUEUE CAPACITY</h2> */}

									<FormRow2
										title="High Capacity Mode"
										name="highCapMode"
										checked={highCapMode}
										onChange={(event) => setHighCapMode(event.target.checked)}
										helperTip={helperTip.highCapMode}
									/>

									{highCapMode ? (
										<>
											<TextField
												sx={styles.textField}
												style={{ marginTop: "30px" }}
												label="High Capacity Threshold"
												helperText={helperTip.highCapThreshold}
												placeholder="25"
												variant="outlined"
												value={highCapThreshold || ""}
												onChange={(event) => setHighCapThreshold(event.target.value)}
											/>

											<TextField
												sx={styles.textField}
												label="High Capacity Announcement Message"
												helperText={helperTip.highCapAnnounce}
												fullWidth
												margin="normal"
												multiline
												placeholder="The queue is currently at high capacity. The tutors will be limiting their time to 5 minutes per student."
												variant="outlined"
												value={highCapAnnounce || ""}
												onChange={(event) => setHighCapAnnounce(event.target.value)}
											/>

											<TextField
												sx={styles.textField}
												label="High Capacity Ticket Warning"
												helperText={helperTip.highCapAnnounce}
												fullWidth
												margin="normal"
												style={{
													paddingBottom: "30px"
												}}
												multiline
												placeholder="The queue is currently very busy. You may not be helped before tutor hours end."
												variant="outlined"
												value={highCapWarning || ""}
												onChange={(event) => setHighCapWarning(event.target.value)}
											/>
										</>
									) : null}
								</Grid>
							</>
						) : null}
					</Grid>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
