import api, { serverURL } from "./conf";

const server = {
	baseURL: serverURL,
	logoutUser() {
		return api.get("/api/users/logout", { withCredentials: true });
	},
	getCurrentUser() {
		return api.get("api/users/get_current_user", { withCredentials: true });
	},
	getUser(id) {
		const params = { user_id: id };
		return api.get("/api/users/get_user", { params });
	},
	updateUser(id, fname, lname, email) {
		const payload = {
			id,
			fname,
			lname,
			email
		};
		return api.put("/api/users/update_user", payload, {
			withCredentials: true
		});
	},
	resetPassword(id, newPassword, currPassword) {
		const payload = {
			id,
			password: newPassword,
			old_password: currPassword
		};
		return api.put("/api/users/reset_password", payload, {
			withCredentials: true
		});
	},
	forgotPassword(email) {
		const payload = {
			email
		};
		return api.put("/api/users/forgot_password", payload);
	},
	/* COURSE */
	createCourse(formData) {
		return api.post("/api/course/create_course", formData, {
			withCredentials: true,
			headers: { "Content-Type": "multipart/form-data" }
		});
	},
	getAllCourseRequests() {
		return api.get("/api/course_requests/", { withCredentials: true });
	},
	approveCourseRequest(course_req_id) {
		const url = `api/course_requests/${course_req_id}`;
		const payload = {
			approve: true
		};
		return api.post(url, payload, { withCredentials: true });
	},
	declineCourseRequest(course_req_id) {
		const url = `api/course_requests/${course_req_id}`;
		const payload = {
			approve: false
		};
		return api.post(url, payload, { withCredentials: true });
	},
	getCourseRequestCsv(course_req_id) {
		const url = `api/course_requests/${course_req_id}`;
		return api.get(url, { withCredentials: true });
	},
	getCourseRequestStaffCsv(course_req_id) {
		const url = `api/course_requests/get_staff/${course_req_id}`;
		return api.get(url, { withCredentials: true });
	},
	loginGrader(id, action_type) {
		const payload = {
			queue_id: id,
			action_type
		};
		return api.post("/api/queue/login_grader", payload, {
			withCredentials: true
		});
	},
	logoutGrader(id, action_type) {
		const payload = {
			queue_id: id,
			action_type
		};
		return api.post("/api/queue/logout_grader", payload, {
			withCredentials: true
		});
	},

	findCourseByID(course_id) {
		const params = { id: course_id };
		return api.get("/api/course/find_course_by_id", {
			params,
			withCredentials: true
		});
	},
	/* ANNOUNCEMENT */
	getAnnouncements(queue_id) {
		const params = { queue_id };
		return api.get("/api/newsfeeds/get_posts", {
			params,
			withCredentials: true
		});
	},
	addAnnouncement(user_id, queue_id, subject, content) {
		const payload = {
			user_id,
			queue_id,
			subject,
			content
		};
		return api.post("/api/newsfeeds/add_post", payload, {
			withCredentials: true
		});
	},
	archiveAnnouncement(queue_id, post_id) {
		const payload = {
			queue_id,
			post_id
		};
		return api.post("/api/newsfeeds/archive_post", payload, {
			withCredentials: true
		});
	},
	/* QUEUE */
	findQueueforCourse(course_id) {
		const params = { course_id };
		return api.get("/api/queue/find_queue_for_course", {
			params,
			withCredentials: true
		});
	},
	getUserRoleInCourse(user_id, course_id) {
		const params = {
			course_id,
			user_id
		};
		return api.get("/api/enrolled_course/get_user_in_course", {
			params,
			withCredentials: true
		});
	},
	getCurrentUserData() {
		return api.get("/api/enrolled_course/get_courses_current_user", {
			withCredentials: true
		});
	},
	findActiveTutors(queue_id) {
		const params = {
			queue_id
		};
		return api.get("/api/enrolled_course/find_active_tutor_for", {
			params,
			withCredentials: true
		});
	},
	/* TICKET */
	addTicket({ queue_id, student_id, title, description, room, workstation, is_private, help_type, tag_list }) {
		const payload = {
			queue_id,
			student_id,
			title,
			description,
			room,
			workstation,
			is_private,
			help_type,
			tag_list
		};
		return api.post("api/ticket/add_ticket", payload, {
			withCredentials: true
		});
	},
	getUserPermissions(user_id, ticket_id) {
		const payload = {
			user_id,
			ticket_id
		};
		return api.post("api/ticket/get_user_permissions", payload, {
			withCredentials: true
		});
	},
	studentUpdateTicket(ticket_id, cancel, title, description, room, workstation, help_type, is_private, tag_list) {
		const payload = {
			ticket_id,
			cancel,
			title,
			description,
			room,
			workstation,
			help_type,
			is_private,
			tag_list
		};
		return api.put("api/ticket/student_update", payload, {
			withCredentials: true
		});
	},
	graderUpdateTicket(ticket_id, status) {
		const payload = {
			ticket_id,
			status
		};
		return api.put("api/ticket/grader_update", payload, {
			withCredentials: true
		});
	},
	/**
	 *
	 * @param {*} queue_id Queue to find tickets from
	 * @param {string} start Start date of range to find tickets
	 * @param {string} end End date of range to find tickets
	 * @param {string} status Default: Pending & accepted tickets
	 * @returns Tickets from a queue
	 */
	findAllTickets(queue_id, start = null, end = null, status = "0;1") {
		const params = {
			queue_id,
			start,
			end,
			status
		};
		return api.get("api/ticket/find_tickets", {
			params,
			withCredentials: true
		});
	},
	exportTicketsCsv(queue_id) {
		// Function is same as findAllTickets but with asCsv parameter
		const params = {
			queue_id,
			status: "0;1;2",
			asCsv: true
		};
		return api.get("api/ticket/find_tickets", {
			params,
			withCredentials: true
		});
	},
	submitTicketFeedback(queue_id, ticket_id, rating, ec_grader_id, feedback, is_anonymous) {
		const payload = {
			queue_id,
			ticket_id,
			rating,
			ec_grader_id,
			feedback,
			is_anonymous
		};
		return api.post("api/ticketfeedback/add_feedback", payload, {
			withCredentials: true
		});
	},
	getFeedback(queue_id) {
		const params = {
			queue_id
		};
		return api.get("api/ticketfeedback/get_feedback_by_grader", {
			params,
			withCredentials: true
		});
	},
	getTicket(ticket_id, user_id) {
		const params = {
			ticket_id,
			user_id
		};
		return api.get("api/ticket/get_info", {
			params,
			withCredentials: true
		});
	},
	getTicketEvents(ticket_id, course_id) {
		const params = {
			ticket_id,
			course_id
		};
		return api.get("api/ticket_event/show_ticket_evts", {
			params,
			withCredentials: true
		});
	},
	addComment({ queue_id, message, ticket_id, first_name, last_name }) {
		const params = {
			queue_id
		};
		const payload = {
			ticket_id,
			message,
			first_name,
			last_name
		};
		return api.post("api/ticket_event/add_comment", payload, {
			params,
			withCredentials: true
		});
	},
	/* COURSE SETTINGS */
	updateQueueSettings(
		queue_id,
		highCapMode,
		highCapAnnounce,
		highCapThreshold,
		highCapWarning,
		cooldown,
		calendarsArray
	) {
		const payload = {
			queue_id,
			high_capacity_enabled: highCapMode,
			high_capacity_message: highCapAnnounce,
			high_capacity_threshold: highCapThreshold,
			high_capacity_warning: highCapWarning,
			ticket_cooldown: cooldown,
			calendar_links: calendarsArray
		};
		return api.post("api/queue/update_queue_setting", payload, {
			withCredentials: true
		});
	},
	updateCourseData(courseData) {
		return api.post("api/course/update_course", courseData, {
			withCredentials: true
		});
	},
	changeRoleWithEmail(email, course_id, section_id, role) {
		const payload = {
			email,
			course_id,
			section_id,
			role
		};
		return api.post("api/enrolled_course/change_role_with_email", payload, {
			withCredentials: true
		});
	},
	deleteUserFromCourse(user_id, course_id) {
		const payload = {
			user_id,
			course_id
		};
		return api.post("api/enrolled_course/delete_user_from_course", payload, { withCredentials: true });
	},
	createUser(email, fname, lname, pid) {
		const payload = {
			email,
			fname,
			lname,
			pid
		};
		return api.post("api/users/create_user", payload, {
			withCredentials: true
		});
	}
};

export default server;
