import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/Settings/TicketHistoryStyle";
import OurTheme from "../../style/Theme";

const STATUS_ENUM = ["Pending", "Accepted", "Resolved", "Canceled"];

export default function TicketHistory() {
	const { inverseTheme } = OurTheme;

	const [rows, setRows] = useState([]);
	const {
		state: { courseId, course }
	} = useContext(Context);
	const headers = [
		{ field: "created_at", label: "Created at" },
		{ field: "student", label: "Student" },
		{ field: "tutor", label: "Tutor" },
		{ field: "location", label: "Location" },
		{ field: "tags", label: "Tags" },
		{ field: "description", label: "Description" },
		{ field: "view", label: "View" }
	];

	const [ticketView, setTicketView] = useState(false);
	const [ticketInfo, setTicketInfo] = useState({});

	const handleCloseTicketView = () => {
		setTicketView(false);
	};

	// const retrieveRows = () => {
	//     // TODO: Retrieve rows using axios based on the user role, rest stays the same
	// }
	// const formatRows = () => {
	//     // TODO: Once data is retrieved from axios, format dates, etc, correctly
	// }

	useEffect(() => {
		server.findAllTickets(courseId, null, null, "0;1;2").then((result) => {
			const tickets = result.data.result;
			const allRows = [];
			console.log("Tickets:", tickets);
			for (const ticket of tickets) {
				const row_object = ticket;
				row_object.id = ticket.ticket_id;
				row_object.created_at = format(new Date(ticket.created_at), "Pp");
				row_object.student = ticket.student_name;
				row_object.tutor = ticket.grader_name;
				row_object.location = ticket.room;
				row_object.tags = ticket.tag_list.join(", ");
				row_object.description = ticket.description;
				row_object.accepted_at = ticket.accepted_at ? format(new Date(ticket.accepted_at), "Pp") : "N/A";
				row_object.closed_at = ticket.closed_at ? format(new Date(ticket.closed_at), "Pp") : "N/A";
				allRows.push(row_object);
			}
			setRows(allRows);
		});
	}, [courseId]);

	const viewTicket = (_id) => {
		setTicketInfo(rows.find((ticket) => ticket.ticket_id === _id));
		setTicketView(true);
	};
	// rendering kinda like this: https://stackoverflow.com/questions/61131896/error-type-void-is-not-assignable-to-type-reactnode
	const renderCells = (row) =>
		headers.map((header) =>
			header.field !== "view" ? (
				<TableCell key={header.label}>{row[header.field]}</TableCell>
			) : (
				<TableCell
					sx={styles.viewTicket}
					onClick={() => viewTicket(row.id)}
					key={header.label}
				>
					View Ticket
				</TableCell>
			)
		);
	const getStripedStyle = (index) => (index % 2 ? { background: "#d1dae3" } : { background: "white" });

	const downloadCSV = () => {
		server
			.exportTicketsCsv(courseId)
			.then((file) => {
				if (!file || !file.data) throw new Error("File is inaccessible");

				// Start download by creating download button & clicking it
				const blob = new Blob([file.data.result], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = `${course.name} Ticket History.csv`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch((e) => console.error(e));
	};

	/*	Grabs ticket history table, then opens new tab and
		print dialog with just the table (simplified format) */
	const printPDF = () => {
		// Clone original table to edit only the printable version
		const ticketTable = document.querySelector("#ticketTable").firstChild;
		const newTicketTable = document.importNode(ticketTable, true);
		newTicketTable.setAttribute("border", "1");
		newTicketTable.setAttribute("cellpadding", "3");

		// - Remove View Ticket column (last column)
		for (const row of newTicketTable.querySelectorAll("tr")) {
			row.lastChild.remove();
		}

		// Create print window - seems like styling doesn't work in new window
		const win = window.open("");
		win.document.write(`<h1>${course.name} Ticket History</h1>`);
		win.document.write(newTicketTable.outerHTML);

		// Print the new window contents & close the window
		win.print();
		win.close();
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<h1 style={{ color: "white" }}>Ticket History</h1>
				<Button
					onClick={downloadCSV}
					sx={styles.button}
					variant="outlined"
					color="secondary"
				>
					Download CSV
				</Button>
				<Button
					onClick={printPDF}
					sx={styles.button}
					variant="outlined"
					color="secondary"
				>
					Print / Download PDF
				</Button>
				<TableContainer
					component={Paper}
					id="ticketTable"
				>
					<Table
						size="small"
						aria-label="simple table"
					>
						<TableHead>
							<TableRow>
								{headers.map((header) => (
									<TableCell key={header.label}>{header.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<TableRow
									style={getStripedStyle(index)}
									key={index}
								>
									{renderCells(row)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<Dialog
					open={ticketView}
					onClose={handleCloseTicketView}
					aria-labelledby="Ticket feedback"
				>
					<DialogTitle sx={styles.title}>
						<div>
							<Typography
								variant="h6"
								style={{
									color: "black",
									fontWeight: "bold"
								}}
							>
								Question by {ticketInfo.student_name}
							</Typography>
						</div>
					</DialogTitle>
					<DialogContent>
						<div>
							<Typography>
								<b>Status: </b>
								{STATUS_ENUM[ticketInfo.status]}
							</Typography>
							<Typography>
								<b>Tutor: </b>
								{ticketInfo.tutor}
							</Typography>
							<Typography>
								<b>Created at: </b>
								{ticketInfo.created_at}
							</Typography>
							<Typography>
								<b>Accepted at: </b>
								{ticketInfo.accepted_at}
							</Typography>
							<Typography>
								<b>Closed at: </b>
								{ticketInfo.closed_at}
							</Typography>
							<Typography>
								<b>Tags: </b>
								{ticketInfo.tags}
							</Typography>
							<Typography>
								<b>Description: </b>
								{ticketInfo.description}
							</Typography>
						</div>
					</DialogContent>
					<DialogActions />
				</Dialog>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

/* Need to make it resizable for smaller windows */
