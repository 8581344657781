import { Box, Button, Collapse, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";

import VisitorNavbar from "../components/VisitorNavbar";
import server from "../server";
import styles from "../style/ForgotPasswordStyle";
import OurTheme from "../style/Theme";

export default function ForgotPassword() {
	const { theme } = OurTheme;

	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState("");
	const [message, setMessage] = React.useState("");

	const [severity, setSeverity] = React.useState("success");

	const handleSubmit = () => {
		handleOpen("Sending email, this may take a bit...", "info");
		server
			.forgotPassword(handleWhiteSpace(email))
			.then(() => {
				handleOpen("Email successfully sent.", "success");
			})
			.catch((err) => {
				handleOpen(err.response.data.reason || err, "error");
			});
	};

	const handleWhiteSpace = (email) => email.replace(/^\s+|\s+$/g, "");

	const handleOpen = (message, severity) => {
		setMessage(message);
		setSeverity(severity);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Box sx={styles.alert}>
						<Collapse in={open}>
							<Alert
								onClose={() => handleClose()}
								severity={severity}
							>
								{message}
							</Alert>
						</Collapse>
					</Box>
					<VisitorNavbar />
					<Box sx={styles.page}>
						<Box sx={styles.wrapper1}>
							<Box sx={styles.wrapper}>
								<Typography sx={styles.title}>
									Forgot Password? <br />
									First time logging into Queues?
								</Typography>
							</Box>
							<Box sx={styles.body}>
								<Typography>
									Enter your official UCSD email address and follow the instructions in the email.
								</Typography>
								<Typography>
									(If you don&apos;t receive an email within an hour, please contact queues@ucsd.edu)
								</Typography>

								<Box
									as="form"
									sx={styles.form}
								>
									<TextField
										sx={styles.formControl}
										fullWidth
										id="standard-basic"
										label="Email Address"
										variant="outlined"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Box>
							</Box>
							<Box sx={styles.wrapper}>
								<Button
									sx={styles.submit}
									onClick={handleSubmit}
									variant="outlined"
									color="primary"
								>
									Submit
								</Button>
							</Box>
						</Box>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
