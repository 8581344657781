import { Button, Grid, StyledEngineProvider, ThemeProvider, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Context } from "../context/Context";
import server from "../server";
import OurTheme from "../style/Theme";

export default function PageNotFound() {
	// course ID code referenced from Navbar.js
	// Setup
	const { inverseTheme } = OurTheme;
	const navigate = useNavigate();
	const { course_id } = useParams();
	const {
		state: { user },
		signout
	} = useContext(Context);

	// States
	const [classList, setClassList] = useState([]);
	useEffect(() => {
		if (!user) {
			return;
		}

		// Get course list and current role for course (url param)
		server.getCurrentUserData().then((response) => {
			const courses = response.data.result.courses.map((c) => c.enrolled_user_info);

			// Change context based on check from API call
			const course = courses.find((c) => c.course_id === parseInt(course_id));

			if (course) {
				setClassList(
					courses.map((courseInfo) => ({
						id: courseInfo.course_id,
						name: courseInfo.course_short_name,
						role: courseInfo.role,
						status: courseInfo.status
					}))
				);
			}
		});
	}, [course_id, user]);

	const goToDefaultQueue = () => {
		const courseId = classList.length > 0 ? classList[0].id : 0;

		if (classList.length > 0) {
			navigate(`/queue/${courseId}`);
		} else {
			navigate("/login");
		}
	};

	const handleLogOut = () => {
		signout();
		server.logoutUser();
		navigate("/login");
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: "100vh" }}
				>
					<Grid
						item
						xs={4}
					>
						<Typography
							variant="h3"
							style={{ color: "white", fontWeight: "bold" }}
						>
							404 Not Found
						</Typography>
						<Typography
							variant="body1"
							style={{ color: "white", fontWeight: "500" }}
						>
							If you think this page should exist, let us know at queues@ucsd.edu
						</Typography>
						{user && (
							<Button
								variant="contained"
								onClick={handleLogOut}
								style={{
									marginBottom: "48px",
									marginTop: "12px"
								}}
							>
								Log Out
							</Button>
						)}
						<br />

						<Button
							variant="contained"
							onClick={goToDefaultQueue}
							style={{
								color: "#2a667b",
								backgroundColor: "#baeaff"
							}}
						>
							Back to Queues
						</Button>
					</Grid>
				</Grid>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
