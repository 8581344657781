export default {
	statusContainer: {
		margin: 4,
		marginBottom: 2
	},
	queueStatusBox: {
		cursor: "default",
		backgroundColor: "#baeaff",
		borderRadius: "15px 15px 15px"
	},
	cardContent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		textAlign: "center",
		alignContent: "center",
		padding: "1em!important"
	},
	openMessageTextContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: 3
	},
	openMessageText: {
		color: "#2a667b",
		fontWeight: "bold",
		fontSize: "1em",
		wordWrap: "break-word",
		display: "block",
		lineHeight: "1em",
		maxHeight: "2em",
		marginBottom: "0.5em"
	},
	closedMessageText: {
		color: "#FF7A7A",
		fontWeight: "bold",
		fontSize: "1em",
		display: "block"
	},
	openMessageLabel: {
		color: "#22DB22",
		fontWeight: "bold",
		fontSize: "1.5em",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	closedMessageLabel: {
		color: "#FF7A7A",
		fontWeight: "bold",
		fontSize: "1.5em",
		display: "flex",
		padding: "0 0.5em 0 0.5em",
		alignItems: "center",
		justifyContent: "center"
	}
};
