export default {
	// this is just for testing
	center: {
		display: "flex",
		justifyContent: "center"
	},
	calendarContainer: {
		display: "flex",
		flex: 1,
		justifyContent: "center"
	},
	calendar: {
		backgroundColor: "white",
		border: "5px solid lightblue",
		borderRadius: "12px",
		padding: "1em"
	},
	staticicon: {
		color: "white",
		width: 100,
		position: "relative",
		left: "50%",
		"margin-left": "-50px"
	},
	clickableicon: {
		color: "white",
		cursor: "pointer"
	},
	overflow: {
		overflowY: "auto",
		flex: 1,
		height: "80vh"
	},
	container: {
		display: "flex",
		flexDirection: "row"
	},
	body: {
		// position: "fixed",
		width: "100%"
	},
	form: {
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black"
			}
		}
	},
	text: {
		color: "black"
	},
	columnTitle: {
		color: "white",
		fontWeight: "bold",
		marginBottom: "1rem"
	},
	smallContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		color: "white",
		marginBottom: "1em"
	},
	activeTutors: {
		marginRight: 33,
		marginLeft: 18,
		marginBottom: 10,
		color: "white",
		border: "solid",
		borderWidth: 1,
		borderColor: "white",
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 5
	}
};
