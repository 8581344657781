import { Box, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";

import CourseCreationRequest from "../components/CourseCreationRequest";
import Navbar from "../components/Navbar";
import { Context } from "../context/Context";
import server from "../server";
import styles from "../style/CreateCourseStyle";
import OurTheme from "../style/Theme";
import MustLogin from "./MustLogin";
import PageNotFound from "./PageNotFound";

export default function CourseCreationApproval() {
	const { theme } = OurTheme;

	const {
		state: { user }
	} = useContext(Context);

	const [allRequests, setAllRequests] = useState(null);

	useEffect(() => {
		function retrieveAllCourseRequestsOnlyOnce() {
			server
				.getAllCourseRequests()
				.then((response) => {
					setAllRequests(response.data.courses);
				})
				.catch((err) => console.error(err));
		}
		retrieveAllCourseRequestsOnlyOnce();
	}, []);

	if (!user) {
		return <MustLogin />;
	}
	if (user.urole !== "ADMIN") {
		return <PageNotFound />;
	}

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{user ? <Navbar /> : null}

					<Box sx={styles.wrappertitle}>
						<Typography sx={styles.title}>Course Creation Approval</Typography>
					</Box>
					<div>
						{allRequests && allRequests[0] ? (
							allRequests.map((courseCreationRequest) => (
								<CourseCreationRequest
									key={courseCreationRequest.course_req_id}
									courseTitle={courseCreationRequest.name}
									shortName={courseCreationRequest.short_name}
									courseUrl={courseCreationRequest.url}
									quarter={courseCreationRequest.quarter}
									year={courseCreationRequest.year}
									fname={courseCreationRequest.instructor_fname}
									lname={courseCreationRequest.instructor_lname}
									email={courseCreationRequest.instructor_email}
									course_req_id={courseCreationRequest.course_req_id}
								/>
							))
						) : (
							<Typography
								variant="h5"
								style={{
									color: "#cce2e3",
									textAlign: "center"
								}}
							>
								There are currently no active course creation requests
							</Typography>
						)}
					</div>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
