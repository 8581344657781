import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../conf";
import server from "../../server";
import styles from "../../style/CourseSettings/StudentPageStyle";
import OurTheme from "../../style/Theme";
import { STUDENT_HEADERS } from "./consts";

function createData(fname, lname, email, id) {
	return { fname, lname, email, id };
}

export default function StudentPageTab(props) {
	StudentPageTab.propTypes = {
		handleOpen: PropTypes.func
	};

	const { theme } = OurTheme;
	const { inverseTheme } = OurTheme;

	// const [open, setOpen] = useState(false);
	const [openAddStudent, setOpenAddStudent] = useState(false);

	const [openCreateUser, setOpenCreateUser] = useState(false);

	const { course_id } = useParams();

	// Edit user form
	const [edit, setEdit] = useState(false);
	const [editIndex, setEditIndex] = useState(0);
	const [editId, setEditId] = useState("");
	const [editFirstName, setEditFirstName] = useState("");
	const [editLastName, setEditLastName] = useState("");
	const [editEmail, setEditEmail] = useState("");

	const [students, setStudents] = useState([]);

	// Create user form
	const [userEmail, setUserEmail] = useState("");
	const [fname, setFName] = useState("");
	const [lname, setLName] = useState("");
	const [pid, setPid] = useState("");

	const handleAddStudRow = (f, l, e, i) => {
		const tmpStudents = [...students, createData(f, l, e, i)];
		tmpStudents.sort((a, b) => a.fname.localeCompare(b.fname));
		setStudents(tmpStudents);
	};

	const handleClose = () => {
		setOpenAddStudent(false);
		setOpenCreateUser(false);
		setEdit(false);
	};

	const handleRemoveStudentRow = (idx) => {
		const row = [...students];
		row.splice(idx, 1);
		setStudents(row);
	};

	const handleRemoveButton = (fname, lname, user_id, idx) => {
		if (window.confirm(`Are you sure you would like to remove ${fname} ${lname}?`)) {
			server
				.deleteUserFromCourse(user_id, Number(course_id))
				.then((res) => {
					if (res.status === 200) {
						handleRemoveStudentRow(idx);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
		handleClose();
	};

	const handleEditStudent = (index, id, fname, lname, email) => {
		setEditIndex(index);
		setEditId(id);
		setEditFirstName(fname);
		setEditLastName(lname);
		setEditEmail(email);
		setEdit(true);
	};

	const handleEditSubmit = () => {
		server
			.updateUser(editId, editFirstName, editLastName, editEmail)
			.then((res) => {
				const updatedUser = res.data.result;

				// Reactivity - Create copy of array and edit
				const updatedStudents = [...students];
				updatedStudents[editIndex] = updatedUser;

				setStudents(updatedStudents);
			})
			.catch((err) => alert(err));

		handleClose();
	};

	useEffect(() => {
		const apiBaseUrl = "/api/enrolled_course/get_all_user_in_course";
		let arr = [];
		api.get(apiBaseUrl, {
			params: { course_id, roles: "STUDENT" },
			withCredentials: true
		}).then((response) => {
			arr = response.data.result.filter((user) => user.user_info);
			const stu = [];
			for (let i = 0; i < arr.length; i++) {
				const st = arr[i];

				stu.push(createData(st.user_info.fname, st.user_info.lname, st.user_info.email, st.user_info.id));
			}
			setStudents(stu);
		});
	}, []);

	const renderCells = (row, index) =>
		STUDENT_HEADERS.map((header) =>
			header.field === "edit" ? (
				<TableCell
					align="center"
					key={header.label}
				>
					<IconButton
						aria-label="edit"
						onClick={() => handleEditStudent(index, row.id, row.fname, row.lname, row.email)}
						size="large"
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</TableCell>
			) : header.field === "remove" ? (
				<TableCell
					align="center"
					key={header.label}
				>
					<IconButton
						onClick={() => handleRemoveButton(row.fname, row.lname, row.id, index)}
						aria-label="remove"
						size="large"
					>
						<TrashIcon fontSize="small" />
					</IconButton>
				</TableCell>
			) : (
				<TableCell
					sx={styles.cell}
					align="left"
					key={header.label}
				>
					{row[header.field]}
				</TableCell>
			)
		);

	const getStripedStyle = (index) => (index % 2 ? { background: "#d1dae3" } : { background: "white" });

	const handleAdd = () => {
		handleClose();

		const stud = students.find((e) => userEmail === e.email);
		if (stud) {
			props.handleOpen("User is already a student!", 400);
			return;
		}

		props.handleOpen("Attempting to add user...", 200);
		server
			.changeRoleWithEmail(userEmail, Number(course_id), 1, "STUDENT")
			.then((res) => {
				if (res.status === 200) {
					props.handleOpen("Successfully added & notified via email!", 200);
					handleAddStudRow(res.data.reason.fname, res.data.reason.lname, userEmail, res.data.reason.id);
				}
			})
			.catch((err) => {
				props.handleOpen(err.response.data.reason, 400);
				console.error(err);
			});
	};

	const handleAddUser = () => {
		// if userEmail !=
		handleClose();

		const isEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail);
		if (!isEmail) {
			alert("invalid email");
			return;
		}

		props.handleOpen("Attempting to add user...", 200);
		server
			.createUser(userEmail, fname, lname, pid)
			.then((res) => {
				if (res.status === 200) {
					server
						.changeRoleWithEmail(res.data.user.email, Number(course_id), 1, "STUDENT")
						.then((res) => {
							if (res.status === 200) {
								props.handleOpen("Successfully added & notified via email!", 200);
								handleAddStudRow(
									res.data.reason.fname,
									res.data.reason.lname,
									userEmail,
									res.data.reason.id
								);
							}
						})
						.catch((err) => {
							props.handleOpen(err.response.data.reason, 400);
							console.error(err);
						});
				}
			})
			.catch((err) => {
				alert(err.response.data.reason);
				console.error(err);
			});
	};

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							xs={3}
						>
							<Typography
								variant="h6"
								sx={styles.h2}
							>
								INFO
							</Typography>
							<div>
								<Typography sx={styles.information}>Total Students: {students.length}</Typography>
							</div>

							<Typography
								variant="h6"
								sx={styles.h2}
							>
								OPTIONS
							</Typography>
							<Box sx={styles.buttons}>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => setOpenAddStudent(true)}
								>
									Add Student
								</Button>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => setOpenCreateUser(true)}
								>
									Create Student
								</Button>
							</Box>
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={inverseTheme}>
									<Dialog
										open={openAddStudent}
										onClose={handleClose}
										aria-labelledby="form-dialog-title"
									>
										<DialogTitle
											id="form-dialog-title"
											sx={styles.dialogue}
										>
											Add Student
										</DialogTitle>
										<DialogContent>
											<DialogContentText
												sx={{
													...styles.dialogue,
													marginBottom: "1em"
												}}
											>
												Enter student information. If this user does not exist yet, use
												&lsquo;Create Student&rsquo;
											</DialogContentText>
											<TextField
												label="User Email"
												sx={styles.input}
												error={
													userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
												}
												helperText={
													userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
														? "Must be a valid email"
														: ""
												}
												onChange={(event) => setUserEmail(event.target.value)}
											/>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={handleClose}
												sx={styles.dialogue}
											>
												Cancel
											</Button>
											<Button
												onClick={handleAdd}
												variant="contained"
												color="secondary"
											>
												Add Student
											</Button>
										</DialogActions>
									</Dialog>
								</ThemeProvider>
							</StyledEngineProvider>
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={inverseTheme}>
									<Dialog
										open={openCreateUser}
										onClose={handleClose}
										aria-labelledby="form-dialog-title"
									>
										<DialogTitle
											id="form-dialog-title"
											sx={styles.dialogue}
										>
											Create Student
										</DialogTitle>
										<DialogContent>
											<DialogContentText
												sx={{
													...styles.dialogue,
													marginBottom: "1em"
												}}
											>
												Enter user information. This will create a user if not already in the db
												and add them to this course&apos;s students.
											</DialogContentText>
											<TextField
												id="outlined-basic"
												variant="outlined"
												label="User Email"
												sx={styles.input}
												type="email"
												onChange={(event) => setUserEmail(event.target.value)}
												error={
													userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
												}
												helperText={
													userEmail !== "" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(userEmail)
														? "Must be a valid email"
														: ""
												}
											/>
											<TextField
												id="outlined-basic"
												variant="outlined"
												label="User First Name"
												sx={styles.input}
												onChange={(event) => setFName(event.target.value)}
											/>
											<TextField
												id="outlined-basic"
												variant="outlined"
												label="User Last Name"
												sx={styles.input}
												onChange={(event) => setLName(event.target.value)}
											/>
											<TextField
												id="outlined-basic"
												variant="outlined"
												label="User PID"
												sx={styles.input}
												onChange={(event) => setPid(event.target.value)}
												error={pid !== "" && !/.[0-9]{8}$/.test(pid)}
												helperText={
													pid !== "" && !/.[0-9]{8}$/.test(pid)
														? "Must be a valid PID (A12345678)"
														: ""
												}
											/>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={handleClose}
												sx={styles.dialogue}
											>
												Cancel
											</Button>
											<Button
												onClick={handleAddUser}
												variant="contained"
												color="inherit"
											>
												Create Student
											</Button>
										</DialogActions>
									</Dialog>
								</ThemeProvider>
							</StyledEngineProvider>
						</Grid>

						<Grid
							item
							xs={9}
						>
							<Typography
								variant="h6"
								sx={styles.h2}
							>
								STUDENTS
							</Typography>
							<Box sx={styles.container}>
								<TableContainer
									sx={styles.table}
									component={Paper}
								>
									<Table
										stickyHeader
										size="small"
										sx={styles.table}
									>
										<TableHead>
											<TableRow
												style={{
													background: "#d1dae3"
												}}
											>
												{STUDENT_HEADERS.map((header) =>
													header.field === "edit" || header.field === "remove" ? (
														<TableCell
															align="center"
															sx={styles.col}
															key={header.label}
														>
															{header.label}
														</TableCell>
													) : (
														<TableCell
															align="left"
															sx={styles.col}
															key={header.label}
														>
															{header.label}
														</TableCell>
													)
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{students.map((row, index) => (
												<TableRow
													style={getStripedStyle(index)}
													key={index}
												>
													{renderCells(row, index)}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>
					</Grid>

					{/* <ThemeProvider theme={inverseTheme}>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title" sx={styles.dialogue}>Add Staff</DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={styles.dialogue}>
                                    Enter new staff information.
                                </DialogContentText>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="user id"
                                    sx={styles.input}
                                    onChange={(event) => setUserID(parseInt(event.target.value))}
                                    InputProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}
                                    InputLabelProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}

                                />

                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="section id"
                                    sx={styles.input}
                                    onChange={(event) => setSectID(parseInt(event.target.value))}
                                    InputProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}
                                    InputLabelProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}
                                />

                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="course id"
                                    sx={styles.input}
                                    onChange={(event) => setCourseID(parseInt(event.target.value))}
                                    InputProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}
                                    InputLabelProps={{
                                        sx: styles.floatingLabelFocusStyle
                                    }}
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} sx={styles.dialogue}>Cancel</Button>
                                <Button onClick={() => EventHandleNewStudent(userID, sectID, courseID)} variant="contained" color="secondary">Add Student</Button>
                            </DialogActions>
                        </Dialog>
                    </ThemeProvider> */}

					<ThemeProvider theme={inverseTheme}>
						<Dialog
							open={edit}
							onClose={handleClose}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle
								id="form-dialog-title"
								sx={styles.dialogue}
							>
								Edit Student
							</DialogTitle>
							<DialogContent>
								<DialogContentText
									sx={{
										...styles.dialogue,
										marginBottom: "1em"
									}}
								>
									Enter student information.
								</DialogContentText>
								<TextField
									id="outlined-basic"
									variant="outlined"
									label="First Name"
									sx={styles.input}
									InputProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									InputLabelProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									defaultValue={editFirstName}
									onChange={(event) => setEditFirstName(event.target.value)}
								/>

								<TextField
									id="outlined-basic"
									variant="outlined"
									label="Last Name"
									sx={styles.input}
									InputProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									InputLabelProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									defaultValue={editLastName}
									onChange={(event) => setEditLastName(event.target.value)}
								/>

								<TextField
									id="outlined-basic"
									variant="outlined"
									label="Email"
									sx={styles.input}
									InputProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									InputLabelProps={{
										sx: styles.floatingLabelFocusStyle
									}}
									defaultValue={editEmail}
									onChange={(event) => setEditEmail(event.target.value)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									sx={styles.dialogue}
								>
									Cancel
								</Button>
								<Button
									onClick={handleEditSubmit}
									variant="contained"
									color="inherit"
								>
									Save
								</Button>
							</DialogActions>
						</Dialog>
					</ThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
