import React from "react";

import { Provider } from "./context/Context";
import AppRoutes from "./Routes";

export default function App() {
	return (
		<Provider>
			<AppRoutes />
		</Provider>
	);
}
