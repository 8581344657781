export default {
	form: {
		"& > *": {
			position: "relative",
			textAlign: "center",
			verticalAlign: "middle"
		},
		// Border of Input Boxes when not clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white"
			}
		},
		maxWidth: "25%",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "15px"
	},
	formControl: {
		minWidth: "100%"
	},
	wrapper: {
		textAlign: "center"
	},
	title: {
		color: "white",
		fontWeight: "bold",
		fontSize: "3em",
		marginTop: "40px"
	},
	body: {
		color: "white",
		marginTop: "25px",
		marginLeft: "auto",
		marginRight: "auto",
		textAlign: "center"
	},
	submit: {
		display: "inline-block",
		marginTop: "1em"
	},
	alert: {
		position: "absolute",
		zIndex: 1,
		width: "99%"
	},
	page: {
		position: "absolute",
		zIndex: 0,
		width: "100%"
	},
	wrapper1: {
		marginLeft: "10%",
		marginRight: "10%"
	}
};
