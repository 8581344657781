import { Box, Link, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";

import VisitorNavbar from "../components/VisitorNavbar";
import styles from "../style/HelpStyle";
import OurTheme from "../style/Theme";

export default function Help() {
	const { theme } = OurTheme;

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<VisitorNavbar />
					<Box sx={styles.page}>
						<Box sx={styles.wrapper1}>
							<Box sx={styles.wrapper}>
								<Typography
									sx={styles.title}
									variant="h3"
								>
									Help
								</Typography>
							</Box>
						</Box>
						{/* What is Queues? */}
						<Box sx={styles.wrapper1}>
							<Box sx={styles.wrapper}>
								<Typography
									sx={styles.title}
									variant="h4"
								>
									What is Queues?
								</Typography>
							</Box>
							<Box sx={styles.body}>
								<Typography>
									Queues is a platform that facilitates synchronous tutoring between students, tutors,
									and professors.
									<br />
									It is the revamp of UC San Diego&apos;s old{" "}
									<Link
										href="https://autograder.ucsd.edu"
										target="_blank"
										rel="noreferrer"
									>
										Autograder platform
									</Link>
									, focusing on improving performance and visuals.
									<br />
									Queues is built mainly with desktop resolutions in mind - we hope to add mobile
									responsiveness soon.
									<br />
									<br />
									Queues is currently in the beta phase of development, focusing on introducing
									quality of life features and support beyond its core features.
									<br />
									Queues is closed-source to a small team of current & former UCSD tutors (recruiting
									soon!). It has been in development since 2020, with contributions from many
									different members, which you may see <Link href="/secret/hall-of-fame">
										here.
									</Link>{" "}
									The Queues team is also responsible for maintaining Autograder.
									<br />
									<br />
									To contact the Queues team, email{" "}
									<Link href="mailto:queues@ucsd.edu">queues@ucsd.edu</Link>.
								</Typography>
							</Box>
						</Box>

						{/* Instructions */}
						<Box sx={styles.wrapper1}>
							<Box sx={styles.wrapper}>
								<Typography
									sx={styles.title}
									variant="h4"
								>
									Instructions
								</Typography>
							</Box>
							<Box sx={styles.body}>
								<Typography>
									<b>If you are a professor:</b>
									<br />
									1. Request your course to be added to Queues <Link href="/create-course">here</Link>
									.
									<br />
									2. Once approved, you will receive an email notification with credentials to sign
									into your course. Your students will also receive their credentials upon course
									approval.
									<br />
									3. Go into your settings to manage your course settings (adding tutor calendars,
									your course website link, etc.)
									<br />
									<br />
									<b>If you are a student:</b>
									<br />
									1. You will receive login credentials via email once your professor&apos;s course
									request has been approved.
									<br />
									2. After logging in, check your course&apos;s tutor calendar to see when tutors will
									be on duty.
									<br />
									3. When tutors are on duty, the queue will be unlocked and you can make a ticket to
									talk with a tutor. Add important details & use comments in your ticket to
									communicate effectively with tutors. Typically, a tutor (or yourself) will send a
									Zoom link to screen share and discuss in detail.
									<br />
									4. Leave some constructive feedback & feel free to check your settings for more
									options on the app.
								</Typography>
							</Box>
						</Box>

						{/* FAQ */}
						<Box sx={styles.wrapper1}>
							<Box sx={styles.wrapper}>
								<Typography
									sx={styles.title}
									variant="h4"
								>
									FAQ
								</Typography>
							</Box>
							<Box sx={styles.body}>
								<Typography>Coming soon!</Typography>
							</Box>
						</Box>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
