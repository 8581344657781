import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function unMemoizedTicketDetails(props) {
	const { openTicketHistoryInfo, open, setOpen } = props;

	const {
		description,
		ec_grader_id: tutorName,
		title: studentName,
		room,
		workstation,
		created_at: createdAt,
		closed_at: closedAt,
		accepted_at: acceptedAt,
		status: ticketStatus,
		tag_list: tags,
		is_private: isPrivate
	} = openTicketHistoryInfo;

	const handleClose = () => {
		setOpen(false);
	};

	console.debug("Loading TicketHistoryDetails...");

	return openTicketHistoryInfo ? (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{ style: { minWidth: "350px" } }}
		>
			<DialogTitle id="form-dialog-title">Ticket Details</DialogTitle>
			<DialogContent>
				<Typography>
					<b>Student:</b> {studentName}
					<br />
					<b>Tutor:</b> {tutorName || "None"} <br />
					<b>Description:</b> {description}
					<br />
					<b>Created:</b> {createdAt && new Date(createdAt).toLocaleString()} <br />
					<b>Accepted:</b> {acceptedAt ? new Date(acceptedAt).toLocaleString() : "Not Accepted"}
					<br />
					<b>Closed:</b> {closedAt ? new Date(closedAt).toLocaleString() : "Not Resolved"}
					<br />
					<b>Location:</b> {room} - {workstation}
					<br />
					<b>Status:</b>
					{ticketStatus === 3 ? " Cancelled" : " Resolved"}
					<br />
					<b>Private:</b> {isPrivate ? isPrivate.toString() : ""}
					<br />
					<b>Tags:</b> {tags ? tags.join(", ") : ""}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="primary"
					variant="outlined"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	) : null;
}

unMemoizedTicketDetails.propTypes = {
	open: PropTypes.bool,
	openTicketHistoryInfo: PropTypes.object,
	setOpen: PropTypes.func
};

const TicketHistoryDetails = React.memo(unMemoizedTicketDetails);
export default TicketHistoryDetails;
