export default {
	"@global": {
		option: {
			color: "black"
		}
	},
	form: {
		"& > *": {
			margin: 1,
			position: "relative",
			textAlign: "center",
			verticalAlign: "middle"
		},
		// Border of Input Boxes when clicked
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white"
			}
		},
		display: "grid",
		paddingTop: "5%"
	},

	formRow: {
		color: "yellow",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%"
	},

	formControl: {
		minWidth: 200,
		textAlign: "center"
	},
	wrapper: {
		textAlign: "center"
	},
	wrappertitle: {
		textAlign: "center",
		color: "white",
		display: "table",
		width: "100%",
		margin: "1em auto"
	},
	title: {
		color: "white",
		fontWeight: "bold",
		fontSize: "3em"
	},
	body: (theme) => ({
		[theme.breakpoints.down(700)]: {
			display: "table-row"
		},
		[theme.breakpoints.up(701)]: {
			display: "table-cell"
		},
		verticalAlign: "top"
	}),
	submit: {
		display: "inline-block",
		marginTop: "2em",
		marginBottom: "2em"
	},
	alert: {
		zIndex: 10000,
		position: "fixed",
		width: "95%"
	},
	white: {
		color: "white",
		marginTop: "30px"
	},
	input: {
		// display: 'none',
		// color: 'red'
	},
	fileInput: {
		color: "white",
		textAlignLast: "center",
		marginTop: 0
	},
	upButton: {
		width: "1000px"
	},
	courseForm: (theme) => ({
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		},
		marginBottom: "2em"
	}),

	courseRow: (theme) => ({
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		},
		color: "white"
	}),

	courseCol: (theme) => ({
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		},
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "flex-start",
		color: "white"
	}),
	col: {
		textAlign: "center",
		display: "flex",
		displayDirection: "column",
		direction: "column",
		justifyContent: "space-evenly",
		alignItems: "flex-start",
		color: "white",
		marginLeft: "12%",
		marginRight: "12%"
	},
	textField: {
		"& label + div": {
			backgroundColor: "#4e6f7b",
			borderRadius: "5px"
		},
		"& label": {
			color: "white",
			fontWeight: "900"
		},
		"& label ~ p": {
			color: "#cce2e3"
		},
		"& fieldset": {
			borderColor: "grey"
		}
	}
};
