export default {
	"@global": {
		"#announcementList::-webkit-scrollbar": {
			display: "none"
		}
	},
	announcementList: {
		height: "50vh",
		overflowY: "scroll"
	},
	newAnnouncement: {
		color: "#baeaff",
		fontWeight: "bold"
	}
};
