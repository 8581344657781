import FullCalendar from "@fullcalendar/react"; // eslint-disable-line
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import timeGridPlugin from "@fullcalendar/timegrid";
import PropTypes from "prop-types";
import React from "react";

import { CALENDAR_EVENT_COLORS } from "./consts";

// TODO: Hide API key before deployment
function TutorCalendar(props) {
	TutorCalendar.propTypes = {
		queueData: PropTypes.object
	};

	const handleEventClick = (info) => {
		// Don't let the browser navigate
		info.jsEvent.preventDefault();

		// Open event in new tab
		if (info.event.url) {
			window.open(info.event.url);
		}
	};

	let ind = 0;
	const TUTOR_HOURS = props.queueData.calendar_links.map((link) => {
		const color = CALENDAR_EVENT_COLORS[ind];
		ind = ind < 5 ? ind + 1 : ind; // supports up to 5 different calendars
		return { googleCalendarId: link, backgroundColor: color };
	});

	return (
		<FullCalendar
			plugins={[timeGridPlugin, googleCalendarPlugin]}
			initialView="timeGridWeek"
			googleCalendarApiKey="AIzaSyCryE1wuvTfqTIBQXCyyOHAhycIscB8FaM"
			slotMinTime="07:00:00"
			eventSources={TUTOR_HOURS}
			eventClick={handleEventClick}
			height="89vh"
			headerToolbar={{
				left: "timeGridWeek,timeGridDay",
				center: "title",
				end: "today prev,next" // will normally be on the right. if RTL, will be on the left
			}}
		/>
	);
}

export default TutorCalendar;
