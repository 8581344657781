import { Box, Button, Grid, StyledEngineProvider, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import OurTheme from "../style/Theme";

export default function MustLogin() {
	// course ID code referenced from Navbar.js
	// Setup
	const { inverseTheme } = OurTheme;
	const navigate = useNavigate();

	const goToDefaultQueue = () => {
		navigate("/login");
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: "100vh" }}
				>
					<Grid
						item
						xs={5}
					>
						<Typography
							variant="h4"
							style={{
								color: "white",
								fontWeight: "500",
								marginBottom: "20px"
							}}
						>
							You must be logged in!
						</Typography>
						<Box textAlign="center">
							<Button
								variant="contained"
								onClick={goToDefaultQueue}
								style={{
									color: "#2a667b",
									backgroundColor: "#baeaff"
								}}
							>
								Login
							</Button>
						</Box>
					</Grid>
				</Grid>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
