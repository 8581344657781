export default {
	profileIcon: {
		fontSize: "3rem"
	},
	topRow: {
		display: "flex",
		color: "white",
		alignItems: "center"
	},
	h2: {
		color: "white",
		fontWeight: "bold",
		margin: "0.5em 0 0.5em 0"
	},
	input: {
		color: "white"
	},
	formControl: {
		marginRight: "1rem"
	},
	form: {
		"& .MuiOutlinedInput-root": {
			"& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
				{
					WebkitBoxShadow: "0 0 0 100px #266798 inset",
					WebkitTextFillColor: "white"
				},
			"& fieldset": {
				borderColor: "white"
			}
		},
		"& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
			// borderColor: 'grey',
		},
		"& .MuiInputBase-root.Mui-disabled": {
			// color: "grey"
		}
	},
	submit: {
		marginTop: "1rem"
	}
};
