import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Collapse, Tab, Tabs, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import ProfileSection from "../components/Settings/ProfileSection";
import TicketFeedback from "../components/Settings/TicketFeedback";
//  history and feedback depend on url param for class??
import TicketHistory from "../components/Settings/TicketHistory";
import { Context } from "../context/Context";
import styles from "../style/SettingsStyle";
import OurTheme from "../style/Theme";
import MustLogin from "./MustLogin";

export default function Settings() {
	const navigate = useNavigate();
	const { theme } = OurTheme;

	const [value, setValue] = React.useState(0);
	const [message, setMessage] = React.useState("");
	const [open, setOpen] = useState(false);
	const {
		state: { user, role, courseList },
		changecourse
	} = useContext(Context);

	const [alertColor, setAlertColor] = React.useState("success");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = (message, responseStatus) => {
		setMessage(message);
		setAlertColor(responseStatus === 200 ? "success" : "error");
		setOpen(true);

		// TODO: This timed alert closing causes inputs to re-render in ProfileSection
		// setTimeout(() => {
		// 	setOpen(false);
		// }, 5000);
	};

	const manageCourse = (courseId) => {
		changecourse(courseId);
		navigate(`/course-settings/${courseId}`);
	};

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.any.isRequired,
		value: PropTypes.any.isRequired
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box py={3}>
						<div>{children}</div>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tabpanel-${index}`,
			"aria-controls": `simple-tab-${index}`
		};
	}

	if (!user) {
		return <MustLogin />;
	}

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Box sx={styles.alert}>
						<Collapse in={open}>
							<Alert
								onClose={() => handleClose()}
								severity={alertColor}
							>
								{message}
							</Alert>
						</Collapse>
					</Box>
					<Navbar />
					<Box sx={styles.root}>
						<Typography
							variant="h1"
							sx={styles.title}
						>
							<SettingsIcon fontSize="large" /> Settings
						</Typography>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="Vertical tabs example"
						>
							<Tab
								sx={styles.tab}
								label="Profile"
								{...a11yProps(0)}
							/>
							<Tab
								sx={styles.tab}
								label="Ticket History"
								{...a11yProps(1)}
							/>
							{role !== "STUDENT" ? (
								<Tab
									sx={styles.tab}
									label="Feedback"
									{...a11yProps(2)}
								/>
							) : null}
							{role !== "STUDENT" ? (
								<Tab
									sx={styles.tab}
									label="Courses"
									{...a11yProps(3)}
								/>
							) : null}
						</Tabs>
						<TabPanel
							value={value}
							index={0}
						>
							<ProfileSection
								setOpen={setOpen}
								user={user}
								handleOpen={handleOpen}
							/>
						</TabPanel>
						<TabPanel
							value={value}
							index={1}
						>
							<TicketHistory />
						</TabPanel>
						<TabPanel
							value={value}
							index={2}
						>
							<TicketFeedback />
						</TabPanel>
						<TabPanel
							value={value}
							index={3}
						>
							<Typography
								variant="h4"
								sx={styles.subtitle}
							>
								Manage Courses
							</Typography>
							{courseList.map((course) =>
								course.role === "ADMIN" || course.role === "INSTRUCTOR" ? (
									<div
										style={{ marginBottom: "1em" }}
										key={course.id}
									>
										<Button
											variant="outlined"
											color="primary"
											key={course.id}
											onClick={() => manageCourse(course.id)}
										>
											Manage {course.name}
										</Button>
									</div>
								) : null
							)}
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate("/create-course")}
							>
								Create New Course
							</Button>
							<br />
							<br />
							{user.urole === "ADMIN" && (
								<Button
									variant="contained"
									color="primary"
									onClick={() => navigate("/course-creation-approval")}
								>
									Course Creation Requests
								</Button>
							)}
						</TabPanel>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
