import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Context } from "../context/Context";
import server from "../server";
import styles from "../style/NavbarStyle";
import OurTheme from "../style/Theme";
import Feedback from "./Feedback";

export default function Navbar() {
	// Setup
	const { theme, inverseTheme } = OurTheme;

	const location = useLocation();
	const navigate = useNavigate();
	const {
		state: { course, courseList },
		changecourse,
		signout
	} = useContext(Context);

	// States
	const [classMenu, setClassMenu] = useState(null);
	const [feedback, setFeedback] = useState(false);
	const [classList, setClassList] = useState([]);

	// TODO: Replace all classList logic with context's courseList
	useEffect(() => {
		setClassList(courseList);
	}, [courseList]);

	const openMenu = (event) => {
		setClassMenu(event.currentTarget);
	};

	const changeClass = (courseId) => {
		setClassMenu(null);

		// Change course & role in the context
		changecourse(courseId, classList.find((c) => c.id === courseId).role);

		if (location.pathname.includes("course-settings")) {
			navigate(`/course-settings/${courseId}`);
		} else if (location.pathname.includes("settings")) {
			navigate(0);
		} else {
			navigate(`/queue/${courseId}`);
		}
	};

	const handleLogOut = () => {
		signout();
		server.logoutUser();
		navigate("/login");
	};

	const goToCurrentQueue = () => {
		navigate(`/queue/${course.id}`);
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Feedback
					open={feedback}
					handleClose={() => setFeedback(false)}
				/>
				<AppBar
					position="static"
					sx={styles.appTable}
				>
					<Toolbar sx={styles.appRow}>
						<Box sx={styles.homeCell}>
							<Typography
								sx={{ ...styles.home, ...styles.link }}
								underline="none"
								onClick={goToCurrentQueue}
							>
								queues.
							</Typography>
						</Box>
						<Box sx={styles.leftlinks}>
							{classList.length > 0 ? (
								<>
									<Button
										sx={styles.navButtons}
										onClick={openMenu}
									>
										{" "}
										{course.name} <ArrowDropDownIcon />
									</Button>
									<StyledEngineProvider injectFirst>
										<ThemeProvider theme={inverseTheme}>
											<Menu
												anchorEl={classMenu}
												open={Boolean(classMenu)}
												onClose={() => setClassMenu(null)}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "center"
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "center"
												}}
												elevation={0}
											>
												{classList.map((obj) => (
													<MenuItem
														key={parseInt(obj.id)}
														onClick={() => changeClass(obj.id)}
													>
														{obj.name}
													</MenuItem>
												))}
											</Menu>
										</ThemeProvider>
									</StyledEngineProvider>
								</>
							) : (
								<Tooltip
									title="You must enroll in a course to be able to see its queue"
									placement="bottom"
								>
									<Button sx={styles.navButtons}>Classes</Button>
								</Tooltip>
							)}
						</Box>
						<Box sx={styles.feedbackLink}>
							<Button
								sx={styles.navButtons}
								onClick={() => setFeedback(true)}
							>
								Submit Feedback
							</Button>
						</Box>
						<Box sx={styles.settingsLink}>
							<Button
								sx={styles.navButtons}
								onClick={() => navigate("/settings")}
							>
								<SettingsIcon />
							</Button>
						</Box>
						<Box sx={styles.exitCell}>
							<Button
								sx={styles.navButtons}
								onClick={handleLogOut}
							>
								<ExitToAppIcon fontSize="large" />
							</Button>
						</Box>
					</Toolbar>
				</AppBar>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
