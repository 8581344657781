import PersonIcon from "@mui/icons-material/Person";
import { Box, Button, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import { Context } from "../../context/Context";
import server from "../../server";
import styles from "../../style/Settings/ProfileSectionStyle";

export default function ProfileSection(props) {
	ProfileSection.propTypes = {
		user: PropTypes.object,
		handleOpen: PropTypes.func
	};

	const [fname, setFName] = useState(props.user.fname);
	const [lname, setLName] = useState(props.user.lname);
	const [currPassword, setCurrPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [retypeNewPassword, setRetypeNewPassword] = useState(null);

	const { updateuser } = useContext(Context);

	const handleUserInfo = () => {
		server
			.updateUser(props.user.id, fname, lname, "")
			.then((response) => {
				props.handleOpen("Profile successfully updated.", response.status);
				updateuser(response.data.result);
			})
			.catch((err) => console.error(err));
	};

	const handleResetPassword = () => {
		server
			.resetPassword(props.user.id, newPassword, currPassword)
			.then((response) => {
				props.handleOpen("Password successfully reset.", response.status);
			})
			.catch(() => props.handleOpen("Incorrect password information."));
	};

	return (
		<>
			<Box sx={styles.topRow}>
				<PersonIcon
					color="primary"
					sx={styles.profileIcon}
				/>
				<h2>
					{props.user.fname} {props.user.lname}
				</h2>
			</Box>
			<Typography
				variant="h5"
				sx={styles.h2}
			>
				User Info
			</Typography>
			<Box
				as="form"
				sx={styles.form}
			>
				<TextField
					sx={styles.formControl}
					label="First Name (Preferred)"
					variant="outlined"
					defaultValue={fname}
					onChange={(e) => setFName(e.target.value)}
				/>
				<TextField
					sx={styles.formControl}
					label="Last Name"
					variant="outlined"
					defaultValue={lname}
					onChange={(e) => setLName(e.target.value)}
				/>
				<TextField
					sx={styles.formControl}
					disabled
					label="Email"
					variant="outlined"
					defaultValue={props.user.email}
				/>
			</Box>
			<Button
				onClick={handleUserInfo}
				sx={styles.submit}
				variant="outlined"
				color="primary"
			>
				Submit
			</Button>

			<Typography
				variant="h5"
				sx={styles.h2}
			>
				Reset Password
			</Typography>
			<Box
				as="form"
				sx={styles.form}
			>
				{/* Hidden username field for accessibility (https://goo.gl/9p2vKq) */}
				<input
					type="text"
					name="email"
					autoComplete="username email"
					value={props.user.email}
					readOnly
					hidden
				/>

				<TextField
					sx={styles.formControl}
					type="password"
					label="Current Password"
					variant="outlined"
					defaultValue={currPassword}
					onChange={(e) => setCurrPassword(e.target.value)}
					autoComplete="current-password"
				/>
				<TextField
					sx={styles.formControl}
					type="password"
					label="New Password"
					variant="outlined"
					defaultValue={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					autoComplete="new-password"
				/>
				<TextField
					sx={styles.formControl}
					type="password"
					label="Retype New Password"
					variant="outlined"
					defaultValue={retypeNewPassword}
					onChange={(e) => setRetypeNewPassword(e.target.value)}
					autoComplete="new-password"
				/>
			</Box>
			<Button
				onClick={handleResetPassword}
				sx={styles.submit}
				variant="outlined"
				color="primary"
			>
				Reset Password
			</Button>
		</>
	);
}
