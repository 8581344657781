import { Box, Card, CardContent, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import server from "../../../server";
import styles from "../../../style/SmallWidget/QueueStatusStyle";
import OurTheme from "../../../style/Theme";

export default function QueueStatus(props) {
	QueueStatus.propTypes = {
		queueStatus: PropTypes.oneOf(["OPEN", "LOCKED", "CLOSED"]),
		totalTutors: PropTypes.number,
		useEventListener: PropTypes.func
	};

	const { course_id } = useParams();

	const status = props.queueStatus;
	const { totalTutors, useEventListener } = props;

	const { theme } = OurTheme;
	const closedMessage = "Students are unable to submit new tickets because the queue is locked.";
	const closedMessageStatus = "LOCKED";
	const openMessageStatus = "OPEN";

	const [pendingTickets, setPendingTickets] = useState(0);
	const [acceptedTickets, setAcceptedTickets] = useState(0);
	const [estWaitTime, _setEstWaitTime] = useState("");
	const TIME_PER_TICKET = 8;

	const setEstWaitTime = (pendingCount, acceptedCount) => {
		// Assumption for algo: On-duty tutors are not AFK & are accepting pending tickets

		if (totalTutors === -1) {
			_setEstWaitTime("Loading");
		} else if (totalTutors === 0) {
			_setEstWaitTime("No Tutors");
		} else if (totalTutors > acceptedCount) {
			// There are extra tutors on duty free to accept new/current tickets -> 0 min wait
			_setEstWaitTime("0 min");
		} else if (totalTutors === acceptedCount) {
			// All tutors occupied -> wait time calc'd from time it takes tutor to finish a ticket
			// Also factor in how many tutors there are & how many tickets
			// - Ex: 8 pending tickets, 4 busy tutors -> wait time is between 8-16min

			// Ex: 8 tickets, 4 tutors -> 2 layers of pending tickets to go through
			// Ex: 5 tickets, 4 tutors -> still 2 layers, as 5th ticket is on 2nd layer -> Math.ceil
			const layers = Math.ceil(pendingCount / totalTutors);

			if (layers <= 1) {
				_setEstWaitTime(`${TIME_PER_TICKET} min`);
			} else {
				_setEstWaitTime(`${TIME_PER_TICKET}-${layers * TIME_PER_TICKET} min`);
			}
		}
	};

	const handleTicketUpdate = (e) => {
		// update the number of active tickets on the queue
		const event = e.detail.events[0];
		const { grader } = e.detail;
		let pendingCount = pendingTickets;
		let acceptedCount = acceptedTickets;

		console.debug("handling ticket update...", event);

		if (event.event_type === "CREATED") {
			pendingCount++;
		} else if (event.event_type === "DEFERRED") {
			pendingCount++;
			acceptedCount--;
		} else if (event.event_type === "ACCEPTED") {
			pendingCount--;
			acceptedCount++;
		} else if (["CANCELED", "RESOLVED"].includes(event.event_type)) {
			if (grader) {
				// Cancelled accepted ticket
				acceptedCount--;
			} else {
				// Cancelled un-accepted (aka pending) ticket
				pendingCount--;
			}
		}

		setPendingTickets(pendingCount);
		setAcceptedTickets(acceptedCount);
		setEstWaitTime(pendingCount, acceptedCount);
	};

	useEffect(() => {
		// gets all of the current tickets
		server.findAllTickets(course_id, null, null, "0;1").then((result) => {
			const tickets = result.data.result || [];
			let pendingCount = 0;
			tickets.forEach((ticket) => {
				if (ticket.status === 0) {
					pendingCount++;
				}
			});
			const acceptedCount = tickets.length - pendingCount;

			setPendingTickets(pendingCount);
			setAcceptedTickets(acceptedCount);

			setEstWaitTime(pendingCount, acceptedCount);
		});
	}, [course_id, totalTutors]);

	useEventListener("ticketUpdated", handleTicketUpdate);

	return (
		<Box sx={styles.statusContainer}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Card
						variant="outlined"
						sx={styles.queueStatusBox}
					>
						{status === "OPEN" ? (
							<CardContent sx={styles.cardContent}>
								<Box>
									<Typography sx={styles.openMessageText}>Estimated Wait: {estWaitTime}</Typography>
									<Typography sx={styles.openMessageText}>
										Pending Tickets: {pendingTickets}
									</Typography>
									<Typography sx={styles.openMessageText}>Tutors on Duty: {totalTutors}</Typography>
								</Box>
								<Typography
									variant="h5"
									sx={styles.openMessageLabel}
								>
									{openMessageStatus}
								</Typography>
							</CardContent>
						) : (
							<CardContent sx={styles.cardContent}>
								<Box>
									<Typography
										variant="body1"
										sx={styles.closedMessageText}
									>
										{closedMessage}
									</Typography>
								</Box>
								<Typography
									variant="h5"
									sx={styles.closedMessageLabel}
								>
									{closedMessageStatus}
								</Typography>
							</CardContent>
						)}
					</Card>
				</ThemeProvider>
			</StyledEngineProvider>
		</Box>
	);
}
