import {
	Alert,
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import { styled, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// import RightWidget from '../components/RightWidget';
import MiddleWidget from "../components/MainWidget/MainWidget";
import Navbar from "../components/Navbar";
// got this from Settings.js
import SmallWidget from "../components/SmallWidget/SmallWidget";
import QueueStatus from "../components/SmallWidget/StatusTabs/QueueStatus";
import { Context } from "../context/Context";
import server from "../server";
import styles from "../style/MainWidget/QueuePageStyle";
import OurTheme from "../style/Theme";
import MustLogin from "./MustLogin";

export default function QueuePage() {
	const { inverseTheme } = OurTheme;

	const [open, setOpen] = useState(false);
	const [publicAlertOpen, setPublicAlertOpen] = useState(true);
	const { course_id } = useParams();
	const {
		state: { user, role, courseList },
		changecourse,
		setCourseList,
		updateuser
	} = useContext(Context);
	const [onDuty, setOnDuty] = useState(user.status);

	// TODO: This should be dependent on totalTutorOnDuty > 0?, should sync w/ Queue.js
	const [queueData, setQueueData] = useState({});

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = () => {
		// right now realizing that i don't know how to make messages connect to tickets?
		// and render the right tickets for a student
		// and add messages when i need to...where i need to...um...
		handleClose();
	};

	const handleOnDutyToggle = () => {
		setOnDuty(!onDuty);

		let promise;
		if (!onDuty) {
			promise = server.loginGrader(parseInt(course_id), "MANUAL");
		} else {
			promise = server.logoutGrader(parseInt(course_id), "MANUAL");
		}

		// Update course status in context if the queue will open/lock after this action
		promise.then(() => {
			server.getCurrentUserData().then((result) => {
				const courses = result.data.result.courses.map((c) => c.enrolled_user_info);

				setCourseList(courses);
			});

			server.findQueueforCourse(course_id).then((result) => {
				setQueueData(result.data.result);
			});
		});
	};

	// custom hook to allow us to communicate between unrelated components
	const useEventListener = (eventType, handler) => {
		const handlerRef = useRef(handler);

		useEffect(() => {
			handlerRef.current = handler;
		});

		useEffect(() => {
			const innerHandler = (e) => handlerRef.current(e);

			window.addEventListener(eventType, innerHandler);

			return () => {
				window.removeEventListener(eventType, innerHandler);
			};
		}, [eventType]);
	};

	const TutorSwitch = styled(Switch)({
		".MuiSwitch-switchBase": {
			color: "white !important"
		},
		checked: {},
		track: {
			backgroundColor: "white"
		}
	});

	// Event Source connection – add all event listeners here!
	const isMounted = React.useRef(true);
	const [activeTutors, setActiveTutors] = useState([]);
	useEffect(() => {
		const sse = new EventSource(`${server.baseURL}api/queue/stream?channel=queue_id.${course_id}`);

		// When a tutor goes on or off duty, update the queue
		sse.addEventListener("dutyUpdate", (event) => {
			const data = JSON.parse(event.data).message;

			setQueueData(data);
			setActiveTutors(data.tutors);
		});

		return () => {
			// Cleanup upon unmounting component
			isMounted.current = false;
			sse.close();
		};
	}, [course_id]);

	useEffect(() => {
		if (!courseList.find((c) => c.id === parseInt(course_id))) {
			window.location.href = "/404";
		}

		server.findActiveTutors(parseInt(course_id)).then((result) => {
			const tutors = result.data.result || [];
			setActiveTutors(tutors);
		});

		server.findQueueforCourse(course_id).then((result) => {
			setQueueData(result.data.result);
		});

		server.getCurrentUserData().then((response) => {
			const courses = response.data.result.courses.map((c) => c.enrolled_user_info);

			// Change context based on check from API call
			const course = courses.find((c) => c.course_id === parseInt(course_id));

			if (course) {
				updateuser(response.data.result.user_info);
				changecourse(course.course_id, course.role);
				setCourseList(courses);

				setOnDuty(course.status !== "INACTIVE");
			}
		});
	}, [course_id]);

	if (!user) {
		return <MustLogin />;
	}

	return (
		<Box sx={styles.body}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={inverseTheme}>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle
							sx={styles.form}
							id="form-dialog-title"
						>
							Add a Comment
						</DialogTitle>
						<DialogContent>
							<TextField
								sx={styles.text}
								variant="outlined"
								id="name"
								label="Comment"
								multiline
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button
								sx={styles.form}
								onClick={handleSubmit}
								color="primary"
							>
								Send
							</Button>
						</DialogActions>
					</Dialog>
					<Navbar />
					<Grid container>
						<Grid container>
							{/* wide middle section */}
							<Grid
								item
								xs={12}
								md={9}
							>
								<MiddleWidget queueData={queueData} />
							</Grid>
							<Grid
								item
								xs={12}
								md={3}
							>
								<QueueStatus
									queueStatus={queueData.status}
									totalTutors={activeTutors.length}
									useEventListener={useEventListener}
								/>
								{role !== "STUDENT" ? (
									<Box
										sx={styles.smallContainer}
										onClick={handleOnDutyToggle}
									>
										{onDuty ? <Typography>On Duty</Typography> : <Typography>Off Duty</Typography>}
										<TutorSwitch
											checked={onDuty}
											name="onDuty"
										/>
									</Box>
								) : null}
								<Collapse in={publicAlertOpen}>
									<Alert
										style={{
											fontSize: "0.85em",
											marginBottom: "0.5em"
										}}
										icon={false}
										severity="info"
										onClose={() => {
											setPublicAlertOpen(false);
										}}
									>
										<b>📣 Note:</b> There may be private tickets that tutors are attending to. The
										Queues Team is working on displaying more information to inform everyone of
										their statuses. Thank you for your patience. Keep up the hard work and good luck
										on finals!
									</Alert>
								</Collapse>
								<SmallWidget
									activeTutors={activeTutors}
									useEventListener={useEventListener}
								/>
							</Grid>
						</Grid>
					</Grid>
				</ThemeProvider>
			</StyledEngineProvider>
		</Box>
	);
}
