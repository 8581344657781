export default {
	// CAN WE MAKE THE STYLES CUSTOMIZABLE???

	ticketEvent: {
		cursor: "default"
	},
	messageText: {
		color: "#000000",
		fontWeight: "regular",
		fontSize: "80%"
	},
	messageTitle: {
		color: "#2a667b",
		fontWeight: "bold",
		fontSize: "85%"
	},
	messageSubheader: {
		color: "#000000",
		fontWeight: "light",
		fontSize: "70%"
	},
	commentme: {
		margin: 2,
		borderRadius: "15px 10px 10px",
		width: "60%",
		alignSelf: "end"
	},
	commentyou: {
		margin: 2,
		borderRadius: "15px 10px 10px",
		width: "60%",
		alignSelf: "start"
	},
	layout: {
		display: "flex",
		flexDirection: "column"
	},
	created: {
		backgroundColor: "#B6F6C8"
	},
	accepted: {
		backgroundColor: "#FFE9C9"
	},
	resolved: {
		backgroundColor: "#FFC9C9"
	},
	updated: {
		backgroundColor: "#FDFFE5"
	},
	deferred: {
		backgroundColor: "#E7D0FE"
	},
	cancelled: {
		backgroundColor: "#B8B8B8"
	},
	commented: {
		backgroundColor: "#ffffff"
	}
};
