import HelpIcon from "@mui/icons-material/Help";
import { Alert, Box, Button, Collapse, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../conf";
import { Context } from "../context/Context";
import styles from "../style/LoginStyle";
import OurTheme from "../style/Theme";

export default function Login() {
	const navigate = useNavigate();

	const { theme } = OurTheme;
	const [password, setPass] = useState("");
	const [email, setEmail] = useState("");
	const [open, setOpen] = useState(false);
	const { signin, changecourse, setCourseList } = useContext(Context);
	const {
		state: { user, courseList }
	} = useContext(Context);

	const pressedKey = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			loginAttempt();
		}
		setOpen(false);
	};

	const loginAttempt = async () => {
		const login = "/api/users/login";
		const loginInfo = {
			email,
			password
		};
		await api
			.post(login, loginInfo, { withCredentials: true })
			.then(async (response) => {
				signin(response.data.result);
				const url = "/api/enrolled_course/get_courses_user_in";
				await api
					.get(url, {
						params: {
							user_id: response.data.result.id
						},
						withCredentials: true
					})
					.then((response) => {
						const { courses } = response.data.result;
						setCourseList(courses.map((c) => c.enrolled_user_info));

						const courseId = courses.length > 0 ? courses[0].enrolled_user_info.course_id : 0;
						if (courseId !== 0) {
							changecourse(courseId, courses[0].enrolled_user_info.role);
						}
						navigate(`/queue/${courseId}`);
					});
			})
			.catch((error) => {
				console.error("Error logging in:", error);
				if (error.response.status === 400) {
					// Display an alert and clear password
					setOpen(true);
					setPass("");
				} else {
					navigate("/404");
				}
			});
	};

	useEffect(() => {
		const alreadyLoggedIn = () => {
			navigate(`/queue/${courseList[0].id}`);
		};

		if (user && courseList.length > 0) {
			alreadyLoggedIn();
		}
	}, [courseList, navigate, user]);

	return (
		<div>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Box sx={styles.popup}>
						<Collapse in={open}>
							<Alert
								variant="filled"
								severity="error"
							>
								Incorrect username and/or password!
							</Alert>
						</Collapse>
					</Box>
					<Box
						sx={styles.main}
						onClick={(event) => pressedKey(event)}
					>
						<Typography sx={styles.title}>
							queues
							<Link
								href="secret/hall-of-fame"
								sx={styles.underline}
							>
								.
							</Link>
						</Typography>
						<form>
							<Box sx={styles.email}>
								<TextField
									color="primary"
									label="Email"
									name="email"
									type="email"
									value={email}
									variant="outlined"
									onChange={(event) => setEmail(event.target.value)}
									onKeyPress={(event) => pressedKey(event)}
									onKeyUp={(event) => pressedKey(event)}
									autoComplete="username email"
								/>
							</Box>
							<Box sx={styles.password}>
								<TextField
									color="primary"
									label="Password"
									name="password"
									type="password"
									value={password}
									variant="outlined"
									onChange={(event) => setPass(event.target.value)}
									onKeyPress={(event) => pressedKey(event)}
									autoComplete="current-password"
								/>
							</Box>
						</form>
						<Button
							sx={styles.button}
							color="primary"
							variant="outlined"
							onClick={() => loginAttempt()}
						>
							Login
						</Button>
						<div />

						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<Grid
								item
								xs={3}
								container
								direction="column"
								justifyContent="flex-start"
								alignItems="center"
							>
								<Box
									style={{ width: "100%" }}
									marginY={2}
								>
									<Divider
										style={{ backgroundColor: "white" }}
										variant="fullWidth"
									/>
								</Box>
								<Link
									href="forgot-password"
									style={{ marginBottom: "0.25em" }}
								>
									Reset Password
								</Link>
								<Link href="create-course">Request Course Creation</Link>
							</Grid>
						</Grid>
					</Box>
					<Link
						href="help"
						sx={styles.bottomRightIcon}
						title="Help"
					>
						<HelpIcon fontSize="large" />
					</Link>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}
