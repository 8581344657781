export default {
	title: {
		color: "white",
		fontSize: "3em",
		fontWeight: "bold",
		marginTop: "0.5em",
		marginBottom: "0.5em"
	},
	subtitle: {
		color: "white",
		fontWeight: "bold",
		marginBottom: "0.5em"
	},
	root: {
		paddingLeft: "10%",
		paddingRight: "10%",
		position: "absolute",
		zIndex: 0
	},
	tabs: {
		borderRight: (theme) => `1px solid ${theme.palette.divider}`
	},
	tab: {
		// width: "10px",
		color: "white"
	},
	alert: {
		position: "absolute",
		zIndex: 1,
		width: "99%"
	}
};
