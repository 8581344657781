export default {
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "1%"
	},
	root: {
		width: "90%",
		margin: 1,
		color: "black"
	},
	acceptedTicket: {
		backgroundColor: "#f9bf6d"
	},
	unacceptedTicket: {
		backgroundColor: "white"
	},
	ticketIcon: {
		display: "inline",
		fill: "#2a667b"
	},
	title: {
		fontSize: 16,
		color: "black",
		alignItems: "center",
		display: "inline",
		verticalAlign: "top",
		paddingLeft: "4px"
	},
	location: {
		fontSize: 16,
		color: "black",
		align: "right",
		display: "inline",
		verticalAlign: "top"
	},
	locationfield: {
		marginBottom: "20px"
	},
	table: {
		display: "table",
		width: "100%"
	},
	tableRow: {
		display: "table-row"
	},
	tableLeft: {
		display: "table-cell"
	},
	tableRight: {
		display: "table-cell",
		textAlign: "right"
	},
	body: {
		display: "block",
		overflowWrap: "break-word"
	},
	buttonDiv: {
		marginTop: "1em"
	},
	editButton: {
		backgroundColor: "#2A667B",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#357f9a"
		},
		marginRight: "10px"
	},
	acceptButton: {
		backgroundColor: "#003D32",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#006653"
		},
		marginRight: "10px"
	},
	cancelButton: {
		backgroundColor: "#381316",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#612025"
		},
		marginRight: "10px"
	},
	resolveButton: {
		backgroundColor: "#28381d",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#435e31"
		},
		marginRight: "10px"
	},
	returnButton: {
		backgroundColor: "#142f38",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#296073"
		},
		marginRight: "10px"
	},
	commentButton: {
		backgroundColor: "#1E385C",
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#2D548B"
		}
	},
	dialog: {
		color: "black"
	},
	text: {
		color: "black"
	},
	check: {
		color: "black"
	},

	copyLink: {
		width: "fit-content",

		"&:hover": {
			cursor: "pointer",
			backgroundColor: "#cce2e3",
			width: "fit-content",
			borderRadius: "8px",
			// outline: "#cce2e3 solid 3px",
			boxShadow: "-5px 0px 0px 0px #cce2e3, 5px 0px 0px 0px #cce2e3"
		}
	}
};
