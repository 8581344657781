import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import styles from "../style/NavbarStyle";
import OurTheme from "../style/Theme";

export default function Feedback(props) {
	Feedback.propTypes = {
		open: PropTypes.bool,
		handleClose: PropTypes.func
	};

	const { inverseTheme } = OurTheme;
	const { open, handleClose } = props;

	const handleSubmit = () => {
		// TODO: Send an email with their feedback to AG email
		// TODO: Display a banner saying feedback submitted
		handleClose();
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={inverseTheme}>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth
				>
					<DialogTitle style={{ color: "black" }}>Submit Feedback</DialogTitle>
					<DialogContent>
						<Typography sx={styles.text}>
							Please let us know if you have suggestions to improve our site.
						</Typography>
						<TextField
							sx={styles.text}
							variant="outlined"
							label="Subject"
							placeholder="Subject of your feedback"
							fullWidth
						/>
						<TextField
							sx={styles.text}
							variant="outlined"
							label="Feedback"
							placeholder="Feedback description & details"
							margin="normal"
							multiline
							fullWidth
							minRows="6"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							sx={styles.form}
							onClick={handleSubmit}
							color="primary"
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
