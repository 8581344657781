import { Box, Card, CardContent, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import styles from "../../../style/SmallWidget/TicketStatusStyle";
import OurTheme from "../../../style/Theme";

export default function TicketStatus(props) {
	TicketStatus.propTypes = {
		person: PropTypes.string,
		tutor: PropTypes.string,
		type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		events: PropTypes.array
	};

	const { theme } = OurTheme;

	function chooseStatusType() {
		switch (props.type) {
			case "Created":
				return styles.created;
			case 2:
				return styles.resolved;
			case "Returned":
				return styles.returned;
			case "accepted":
				return styles.accepted;
			case 3:
				return styles.cancelled;
			case "Edited":
				return styles.edited;
			default:
				return styles.created;
		}
	}

	const statusType = chooseStatusType();

	return (
		<Box>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Card
						variant="outlined"
						sx={statusType}
					>
						<CardContent>
							<Box sx={styles.table}>
								<Box sx={styles.tableRow}>
									<Box sx={styles.tableCellStatus}>
										<Typography
											variant="body2"
											sx={styles.bold}
										>
											{props.type === 3 ? "Cancelled" : "Resolved"}
										</Typography>
										<Typography fontSize="0.75em">
											{new Date(props.events?.at(-1)?.timestamp).toLocaleString()}
										</Typography>
									</Box>
									<Box sx={styles.tableCellTutor}>
										<Typography variant="body2">{props.person}</Typography>
										<Typography variant="body2">{props.tutor || "No Tutor"}</Typography>
									</Box>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</ThemeProvider>
			</StyledEngineProvider>
		</Box>
	);
}
