export default {
	root: {
		position: "absolute",
		width: "100%",
		left: 0,
		right: 0
	},
	main: {
		position: "absolute",
		width: "100%",
		left: 0,
		right: 0,
		zIndex: 1
	},
	popup: {
		position: "absolute",
		width: "98.5%",
		left: "50%",
		right: 0,
		marginLeft: "-49.25%",
		zIndex: 2
	},
	title: {
		color: "white",
		fontSize: "calc(30px + 5vw)",
		fontWeight: "bold",
		textAlign: "center",
		margin: "0.75em 0 0.25em 0"
	},
	underline: {
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline",
			underline: "1px solid"
		}
	},
	button: {
		color: "white",
		width: 100,
		left: "50%",
		marginLeft: "-50px",
		marginTop: "0.5em"
	},
	email: {
		textAlign: "center",
		marginBottom: "15px",
		"& > *": {
			width: "calc(210px + 6vw)"
		},
		"& .MuiOutlinedInput-root": {
			"& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
				{
					WebkitBoxShadow: "0 0 0 100px #266798 inset",
					WebkitTextFillColor: "white"
				},
			"& fieldset": {
				borderColor: "white"
			}
		}
	},
	password: {
		textAlign: "center",
		marginBottom: "10px",
		"& > *": {
			position: "relative",
			width: "calc(210px + 6vw)"
		},
		"& .MuiOutlinedInput-root": {
			"& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
				{
					WebkitBoxShadow: "0 0 0 100px #266798 inset",
					WebkitTextFillColor: "white"
				},
			"& fieldset": {
				borderColor: "white"
			}
		}
	},
	links: {
		textAlign: "center"
	},
	helper: {
		textAlign: "center",
		color: "white",
		cursor: "default"
	},
	bottomRightIcon: {
		position: "absolute",
		bottom: "1em",
		right: "1em",
		height: "40px",
		zIndex: 1
	}
};
