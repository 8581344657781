import { Alert, Box, Collapse, Tab, Tabs, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CourseSettingsTab from "../components/CourseSettings/CourseSettingsTab";
import StaffPageTab from "../components/CourseSettings/StaffPageTab";
import StudentPageTab from "../components/CourseSettings/StudentPageTab";
import Navbar from "../components/Navbar";
import { Context } from "../context/Context";
import server from "../server";
import styles from "../style/CourseSettings/CourseSettingsStyle";
import OurTheme from "../style/Theme";
import MustLogin from "./MustLogin";

export default function CourseSettings() {
	const { course_id } = useParams();
	const { theme } = OurTheme;

	const navigate = useNavigate();
	const [value, setValue] = useState(0);
	const [visible, setVisible] = useState(false);

	const [message, setMessage] = React.useState("");
	const [open, setOpen] = useState(false);
	const [alertColor, setAlertColor] = React.useState("success");

	const {
		state: { course, user }
	} = useContext(Context);

	server.getUserRoleInCourse(user.id, course_id).then((result) => {
		const studentData = result.data.result.enrolled_course_info;
		const ALLOWED_ROLES = ["ADMIN", "INSTRUCTOR"];
		if (!ALLOWED_ROLES.includes(studentData.role)) {
			navigate("/404");
		}

		setVisible(true);
	});

	if (!user) {
		return <MustLogin />;
	}
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = (message, responseStatus) => {
		setMessage(message);
		setAlertColor(responseStatus === 200 ? "success" : "error");
		setOpen(true);
		setTimeout(() => {
			setOpen(false);
		}, 5000);
	};

	function TabPanel(props) {
		TabPanel.propTypes = {
			children: PropTypes.element,
			value: PropTypes.number,
			index: PropTypes.number
		};

		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<div>{children}</div>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tabpanel-${index}`,
			"aria-controls": `simple-tab-${index}`
		};
	}

	return (
		<div>
			{visible && (
				<>
					<Navbar />
					<div>
						<Collapse in={open}>
							<Alert
								onClose={() => handleClose()}
								severity={alertColor}
								variant="filled"
								sx={{ fontFamily: "Quicksand" }}
							>
								{message}
							</Alert>
						</Collapse>
					</div>
					<Box sx={styles.root}>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<Typography sx={styles.title}>Course Settings: {course.name}</Typography>
								{/* 
                        <Typography variant="h5">{course_id != null ? courseList.find(c => c.id === parseInt(course_id)).name : courseList.find(c => c.id === parseInt(courseId)).name}</Typography>
                        */}
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="Vertical tabs example"
								>
									<Tab
										sx={styles.tab}
										label="Settings"
										{...a11yProps(0)}
									/>
									<Tab
										sx={styles.tab}
										label="Staff"
										{...a11yProps(1)}
									/>
									<Tab
										sx={styles.tab}
										label="Students"
										{...a11yProps(2)}
									/>
								</Tabs>
								<TabPanel
									value={value}
									index={0}
								>
									<CourseSettingsTab course_id={parseInt(course_id)} />
								</TabPanel>
								<TabPanel
									value={value}
									index={1}
								>
									<StaffPageTab handleOpen={handleOpen} />
								</TabPanel>
								<TabPanel
									value={value}
									index={2}
								>
									<StudentPageTab handleOpen={handleOpen} />
								</TabPanel>
							</ThemeProvider>
						</StyledEngineProvider>
					</Box>
				</>
			)}
		</div>
	);
}
