import createDataContext from "./createDataContext";

const reducer = (state, action) => {
	switch (action.type) {
		case "signin":
			return { ...state, user: action.payload };
		case "signout":
			return { ...state, user: "" };
		case "updateuser":
			return { ...state, user: action.payload };
		case "changecourseId":
			return { ...state, courseId: action.payload };
		case "changecourse":
			return { ...state, course: action.payload };
		case "changerole":
			return { ...state, role: action.payload };
		case "setcourselist":
			return { ...state, courseList: action.payload };
		default:
			return state;
	}
};

const signup = (dispatch) => (user) => {
	// on successful signup OR make api call here to sign up
	// set the user
	dispatch({ type: "signin", payload: user });
	localStorage.setItem("user", JSON.stringify(user));
};

const signin = (dispatch) => (user) => {
	// on successful signin OR make api call here to sign in
	// set the user
	dispatch({ type: "signin", payload: user });
	localStorage.setItem("user", JSON.stringify(user));
};

const signout = (dispatch) => () => {
	// remove the user from the state
	dispatch({ type: "signout" });
	localStorage.clear();
};

const updateuser = (dispatch) => (user) => {
	// user updates their profile
	dispatch({ type: "updateuser", payload: user });
	localStorage.setItem("user", JSON.stringify(user));
};

const changecourse = (dispatch) => (courseId, role) => {
	dispatch({ type: "changecourseId", payload: courseId });
	dispatch({ type: "changerole", payload: role });
	localStorage.setItem("courseId", courseId);
	localStorage.setItem("role", role);

	const courseList = JSON.parse(localStorage.getItem("courseList"));
	const courseData = courseList.find((course) => course.id === courseId);

	dispatch({ type: "changecourse", payload: courseData });
	localStorage.setItem("course", JSON.stringify(courseData) || "{}");
};

const setCourseList = (dispatch) => (coursesData) => {
	const courseList = coursesData.map((course) => ({
		id: course.course_id,
		name: course.course_short_name,
		role: course.role,
		status: course.status
	}));

	dispatch({ type: "setcourselist", payload: courseList });
	localStorage.setItem("courseList", JSON.stringify(courseList));
};

/* courseList: [
    { 
        id: Number,
        name: String,
        role: String,
        status: String
    }
] */

export const { Provider, Context } = createDataContext(
	reducer,
	{ signup, signin, signout, updateuser, changecourse, setCourseList },
	{
		user: JSON.parse(localStorage.getItem("user")) || "",
		course: JSON.parse(localStorage.getItem("course")) || "",
		courseId: localStorage.getItem("courseId") || "",
		role: localStorage.getItem("role") || "",
		courseList: JSON.parse(localStorage.getItem("courseList")) || []
	}
);
